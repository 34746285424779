import * as Yup from "yup";
import { useState } from "react";
import { useFormik, Form, FormikProvider } from "formik";
import {
  Stack,
  TextField,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import SaveIcon from "@mui/icons-material/Save";
import { useTranslation } from "react-i18next";
import { UsersService } from "../../../../api/services";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

export default function ChangeOnlineUserPassword({
  formData,
  setModalStatus,
  setSnackbarStatus,
  setMessage,
  successCallback,
}) {
  const { t } = useTranslation();
  const [showPassword, setShowPassword] = useState(false);

  const PasswordChangeSchema = Yup.object().shape({
    old_password: Yup.string().required(t("old-password-is-required")),
    new_password: Yup.string()
      .required(t("new-password-is-required"))
      .min(3, t("password-too-short"))
      .max(32, t("password-too-long"))
      .matches(/^[\S]*$/, t("password-no-spaces")),
  });

  const formik = useFormik({
    initialValues: {
      old_password: "",
      new_password: "",
    },
    validationSchema: PasswordChangeSchema,
    onSubmit: (values, actions) => {
      const payload = {
        old_password: values.old_password,
        new_password: values.new_password,
      };

      UsersService.updateOnlineUserPassword(formData.id, payload)
        .then((response) => {
          if (response.status === 201 || response.status === 200) {
            setMessage(t("password-changed-successfully"));
            setSnackbarStatus(true);
            setModalStatus(false);
            successCallback();
          } else {
            throw new Error(t("failed-to-change-password"));
          }
        })
        .catch((err) => {
          console.error("Password change failed:", err);
          setMessage(t("failed-to-change-password"));
          setSnackbarStatus(true);
        })
        .finally(() => {
          actions.setSubmitting(false);
        });
    },
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <TextField
            fullWidth
            label={t("old-password")}
            type={showPassword ? "text" : "password"}
            {...getFieldProps("old_password")}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            error={Boolean(touched.old_password && errors.old_password)}
            helperText={touched.old_password && errors.old_password}
          />

          <TextField
            fullWidth
            label={t("new-password")}
            type={showPassword ? "text" : "password"}
            {...getFieldProps("new_password")}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            error={Boolean(touched.new_password && errors.new_password)}
            helperText={touched.new_password && errors.new_password}
          />

          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            loading={isSubmitting}
            startIcon={<SaveIcon />}
            color="accent"
          >
            {t("change-password")}
          </LoadingButton>
        </Stack>
      </Form>
    </FormikProvider>
  );
}
