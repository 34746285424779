import React, { useState, useEffect } from 'react';
import { 
  Box, 
  Typography, 
  IconButton, 
  Drawer,
  TextField,
  Button,
  Select,
  MenuItem,
  InputAdornment,
  Snackbar,
  Alert
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { OddsService, OnlineOddsService } from '../../../api/services';
import { useStore } from '../../../store/Store';
import { useTranslation } from 'react-i18next';
import { styled, useTheme } from '@mui/material/styles';

const StyledDrawer = styled(Drawer)(({ theme }) => ({
  '& .MuiDrawer-paper': {
    height: '70%',
    borderTopLeftRadius: 16,
    borderTopRightRadius: 16,
    zIndex: 1000,
    backgroundColor: theme.palette.common.white,
    padding: 0,
    display: 'flex',
    flexDirection: 'column',
  },
}));

const StyledAlert = styled(Alert)(({ theme }) => ({
  backgroundColor: theme.palette.warning.light,
  color: theme.palette.common.black,
  fontSize: '1.5rem',
  textAlign: 'center',
  height: "20%",
  width: "100%",
  fontWeight: "bold",
  padding: "18px",
}));

export default function TicketModal({ open, onClose, handleChangeSelectedBets }) {
  const [betType, setBetType] = useState('Combine');
  const [stakes, setStakes] = useState({});
  const [sharedStake, setSharedStake] = useState(20);
  const [showSuccess, setShowSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [store,] = useStore();
  const theme = useTheme();
  const { t } = useTranslation();

  useEffect(() => {
    if (betType === 'Single') {
      const initialStakes = store.selected_bets.reduce((acc, _, index) => {
        acc[index] = stakes[index] !== undefined ? stakes[index] : 20;
        return acc;
      }, {});
      setStakes(initialStakes);
    }
  }, [store.selected_bets, betType]);

  const handleStakeChange = (index, value) => {
    const numericValue = Number(value);
    if (value === "" || (!isNaN(numericValue) && numericValue >= 0)) {
      if (betType === 'Single') {
        setStakes(prev => ({
          ...prev,
          [index]: value === "" ? "" : numericValue,
        }));
      } else {
        setSharedStake(value === "" ? "" : numericValue);
      }
    }
  };

  const calculateSingleReturn = (ticket, stake) => {
    return (parseFloat(stake) || 0) * parseFloat(ticket.odd);
  };

  const calculateTotalOdd = () => {
    if (betType === 'Single') {
      return store.selected_bets.length > 0 ? store.selected_bets[0].odd : '0.00';
    } else {
      return store.selected_bets.reduce((acc, item) => acc * parseFloat(item.odd), 1).toFixed(2);
    }
  };

  const calculateTotalStake = () => {
    if (betType === 'Single') {
      return Object.values(stakes).reduce((acc, stake) => acc + (parseFloat(stake) || 0), 0).toFixed(2);
    } else {
      return parseFloat(sharedStake).toFixed(2);
    }
  };

  const calculatePossibleWin = () => {
    if (betType === 'Single') {
      return store.selected_bets.reduce((acc, ticket, index) => {
        return acc + calculateSingleReturn(ticket, stakes[index]);
      }, 0).toFixed(2);
    } else {
      return (parseFloat(sharedStake) * parseFloat(calculateTotalOdd())).toFixed(2);
    }
  };

  const handleCreateCoupon = async () => {
    if (betType === 'Single') {
      const placedBets = store.selected_bets.map((ticket, index) => ({
        fixture_id: ticket.fixture_id,
        odd_id: ticket.odd_id,
        bet_amount: parseFloat(stakes[index] || 0)
      }));

      const payload = {
        placed_bets: placedBets,
        coupon_type: 1,
      };

      try {
        let apiService = store.userType === "kiosk"
          ? OddsService.createCoupon(payload)
          : OnlineOddsService.createOnlineCoupon(payload);

        const response = await apiService;

        if (response.status === 200) {
          setShowSuccess(true);
          localStorage.removeItem("selected_bets");
          setTimeout(() => {
            window.location.reload();
          }, 3000);
        }
      } catch (error) {
        setErrorMessage(error.message || "Error creating coupon");
      }
    } else {
      const placedBets = store.selected_bets.map(ticket => ({
        fixture_id: ticket.fixture_id,
        odd_id: ticket.odd_id,
        bet_amount: parseFloat(sharedStake)
      }));

      const payload = {
        placed_bets: placedBets,
        coupon_type: 2,
      };

      try {
        let apiService = store.userType === "kiosk"
          ? OddsService.createCoupon(payload)
          : OnlineOddsService.createOnlineCoupon(payload);

        const response = await apiService;

        if (response.status === 200) {
          setShowSuccess(true);
          localStorage.removeItem("selected_bets");
          setTimeout(() => {
            window.location.reload();
          }, 3000);
        }
      } catch (error) {
        setErrorMessage(error.message || "Error creating coupon");
      }
    }
  };

  const handleRemoveTicket = (index) => {
    const removedTicket = store.selected_bets[index];

    handleChangeSelectedBets(removedTicket)

  };

  return (
    <StyledDrawer anchor="bottom" open={open} onClose={onClose}>
      <Box sx={{ 
        bgcolor: theme.palette.error.darkest, 
        color: theme.palette.common.white,
        p: 2,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
      }}>
        <Typography>{t("ticket-basket")}</Typography>
        <IconButton onClick={onClose} sx={{ color: theme.palette.common.white }}>
          <KeyboardArrowDownIcon />
        </IconButton>
      </Box>

      <Box sx={{ p: 2, borderBottom: '1px solid #eee' }}>
        <Select
          value={betType}
          size="small"
          sx={{ bgcolor: theme.palette.neutral.lighter, width:{xs:"50%", md:"15%"} }}
          onChange={(e) => setBetType(e.target.value)}
        >
          <MenuItem value="Single">{t("single")}</MenuItem>
          <MenuItem value="Combine">{t("combine")}</MenuItem>
        </Select>
      </Box>

      <Box sx={{ 
        flex: 1,
        overflow: 'auto',
        display: 'flex',
        flexDirection: 'column'
      }}>
        <Box sx={{ p: 2, flex: 1 }}>
          {store.selected_bets.map((ticket, index) => (
            <Box 
              key={index}
              sx={{ 
                py: 2,
                borderBottom: '1px solid #eee',
                position: 'relative'
              }}
            >
              <Box sx={{ 
                display: 'flex', 
                justifyContent: 'space-between', 
                alignItems: 'center',
                mb: 1 
              }}>
                <Typography variant="body2" color="text.secondary">
                  {ticket.date} {ticket.time}
                </Typography>
                <Box sx={{ 
                  display: 'flex', 
                  alignItems: 'center'
                }}>
                  <Typography 
                    variant="body1" 
                    sx={{ 
                      fontWeight: 'bold',
                      mr: 5
                    }}
                  >
                    {ticket.odd}
                  </Typography>
                  <IconButton 
                    size="small"
                    onClick={() => handleRemoveTicket(index)}
                    sx={{ 
                      padding: 0,
                      position: 'absolute',
                      right: 0,               
                      '& .MuiSvgIcon-root': {
                        fontSize: '16px',
                        color: '#666'
                      }
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </Box>
              </Box>
              
              <Typography variant="body2" sx={{ mb: 0.5 }}>
                {ticket.homeTeam} vs {ticket.awayTeam}
              </Typography>
              
              <Typography variant="body2" color="text.secondary">
                {ticket.betType}: {ticket.value}
              </Typography>

              {betType === 'Single' && (
                <Box sx={{ mt: 1 }}>
                  <Box sx={{ 
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1
                  }}>
                    <Typography variant="body2" color="text.secondary">
                     ₺
                    </Typography>
                    <TextField
                      size="small"
                      type="number"
                      value={stakes[index] || ''}
                      onChange={(e) => handleStakeChange(index, e.target.value)}
                      sx={{ 
                        width: '80px',
                        '& .MuiOutlinedInput-input': {
                          padding: '4px 8px',
                          textAlign: 'right'
                        }
                      }}
                    />
                  </Box>
                  <Typography 
                    variant="body2" 
                    sx={{ 
                      color: theme.palette.success.darkest,
                      mt: 0.5,
                      fontWeight: 500 
                    }}
                  >
                    {t("possible-payout")} : {calculateSingleReturn(ticket, stakes[index]).toFixed(2)} ₺
                  </Typography>
                </Box>
              )}
            </Box>
          ))}
        </Box>

        <Box sx={{ 
          p: 2, 
          bgcolor: theme.palette.neutral.lighter,
          borderTop: '1px solid #ddd'
        }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
            <Typography>{t("total-stake")}</Typography>
            <Typography sx={{ fontWeight: 'bold' }}>
              {calculateTotalStake()} ₺
            </Typography>
          </Box>
          
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
            <Typography>{t("total-odd")}</Typography>
            <Typography sx={{ fontWeight: 'bold' }}>{calculateTotalOdd()}</Typography>
          </Box>
          
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
            <Typography>{t("possible-payout")}</Typography>
            <Typography sx={{ fontWeight: 'bold', color: theme.palette.error.darkest }}>
              {calculatePossibleWin()} ₺
            </Typography>
          </Box>

          {betType === 'Combine' && (
            <TextField
              fullWidth
              type="number"
              value={sharedStake}
              onChange={(e) => handleStakeChange(0, e.target.value)}
              InputProps={{
                startAdornment: <InputAdornment position="start">₺</InputAdornment>,
                endAdornment: <InputAdornment position="end">{t("total-bet")}</InputAdornment>,
              }}
              sx={{ 
                mb: 2,
                bgcolor: 'white',
                '& .MuiOutlinedInput-root': {
                  borderRadius: 1,
                }
              }}
            />
          )}

          <Button 
            fullWidth 
            variant="contained" 
            sx={{ 
              bgcolor: theme.palette.success.darker,
              '&:hover': {
                bgcolor: theme.palette.success.main
              },
              py: 1.5
            }}
            onClick={(e) => {
              e.preventDefault();
              handleCreateCoupon();
            }}
            disabled={store.selected_bets.length === 0}
          >
            {calculateTotalStake()} ₺ {t("confirm-ticket")}
          </Button>
        </Box>
      </Box>

      <Snackbar 
        open={showSuccess} 
        autoHideDuration={6000} 
        onClose={() => setShowSuccess(false)} 
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }} 
      >
        <StyledAlert onClose={() => setShowSuccess(false)} severity="success">
          {t("coupon-created-successfully")}
        </StyledAlert>
      </Snackbar>

      {errorMessage && (
        <Snackbar open={Boolean(errorMessage)} autoHideDuration={6000} onClose={() => setErrorMessage('')}>
          <Alert onClose={() => setErrorMessage('')} severity="error">
            {errorMessage}
          </Alert>
        </Snackbar>
      )}
    </StyledDrawer>
  );
} 