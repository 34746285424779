import React, { useState, useEffect } from "react";
import { Tooltip, IconButton, Typography, Box, Button, Select, MenuItem, Divider } from "@mui/material";
import { useTranslation } from "react-i18next";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import { DefaultPaginationData } from "../../constants";
import { rowArrayToObject } from "../../utils/Util";
import BaseModal from "../../components/BaseModal";
import BaseSnackbar from "../../components/BaseSnackbar";
import { BaseTable } from "../../components/table";
import {  OnlineOddsService } from "../../api/services";
import { couponStatusColors, couponStatusMap } from "../../constants";
import { useTheme } from "@mui/material/styles";

export default function Coupons() {
  const { t } = useTranslation();
  const theme = useTheme();
  const [openSnackbar, setSnackbarStatus] = useState(false);
  const [message, setMessage] = useState("");
  const [data, setData] = useState([]);
  const [paginationData, setPaginationData] = useState(DefaultPaginationData);
  const [loadingData, setLoadingData] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});
  const [openViewModal, setViewModalStatus] = useState(false);
  const [openEditModal, setEditModalStatus] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [newStatus, setNewStatus] = useState("");

  const TABLE_HEAD = [
    { key: "id", label: t("coupon-id") },
    { key: "coupon_status", label: t("coupon-status") },
    { key: "bet_amount", label: t("bet-amount") },
    { key: "total_payout", label: t("expected-payout") },
    { key: "created_at", label: t("created-date") },
    { key: "actions", label: t("actions"), align: "center" },
  ];
  
  const TABLE_FIELD_MAPPING = {
    id: { key: "id", index: 0 },
    coupon_status: { key: "coupon_status", index: 1 },
    bet_amount: { key: "bet_amount", index: 2 },
    total_payout: { key: "total_payout", index: 3 },
    created_at: { key: "created_at", index: 4 },
  };

  const fetchCoupons = () => {
    setLoadingData(true);
    const params = {
      page: paginationData.page + 1,
      size: paginationData.rowsPerPage,
    };

    OnlineOddsService.listOnlineCoupons(params)
      .then((response) => {
        const coupons = response.data.items.map((item) => {
          const row = new Array(TABLE_HEAD.length - 1).fill({});
          Object.keys(TABLE_FIELD_MAPPING).forEach((key) => {
            let value;
            switch (key) {
              case "coupon_status":
                value = (
                  <Typography sx={{ color: couponStatusColors[item.coupon_status] || "text.primary" }}>
                    {couponStatusMap[item.coupon_status] || "Unknown Status"}
                  </Typography>
                );
                break;
              case "bet_amount":
                value = item.placed_bets
                  ? item.placed_bets.reduce((sum, bet) => sum + bet.bet_amount, 0).toFixed(2)
                  : "0.00";
                break;
              case "total_payout":
                value = item.total_payout ? item.total_payout.toFixed(2) : "0.00";
                break;
              case "created_at":
                value = new Intl.DateTimeFormat("en-GB", {
                  day: "2-digit",
                  month: "2-digit",
                  year: "numeric",
                  hour: "2-digit",
                  minute: "2-digit",
                  second: "2-digit",
                  hour12: false,
                }).format(new Date(item.created_at));
                break;
              default:
                value = item[key] || "";
            }
            row[TABLE_FIELD_MAPPING[key].index] = { ...TABLE_FIELD_MAPPING[key], value };
          });
          return { key: item.id, row, bets: item.placed_bets || [] };
        });

        setData(coupons);
        setTotalCount(response.data.total);
      })
      .catch((error) => {
        setMessage(t("failed-to-get-coupons"));
        setSnackbarStatus(true);
        console.error(error);
      })
      .finally(() => {
        setLoadingData(false);
      });
  };

  useEffect(() => {
    fetchCoupons();
  }, [paginationData]);

  const modalHandler = (modalType, index = undefined) => {
    switch (modalType) {
      case "view":
        setViewModalStatus(!openViewModal);
        break;
      case "edit":
        setEditModalStatus(!openEditModal);
        break;
      default:
        break;
    }

    if (index !== undefined) {
      const row = rowArrayToObject(data[index].row, TABLE_FIELD_MAPPING);
      setSelectedRow({ ...row, bets: data[index].bets });

      if (modalType === "edit") {
        const currentStatusKey = Object.keys(couponStatusMap).find(
          (key) => couponStatusMap[key] === row.coupon_status.props.children
        );
        setNewStatus(currentStatusKey || "");
      }
    }
  };

  const handleEditStatus = () => {
    OnlineOddsService.updateOnlineCouponStatus(selectedRow.id, {
      new_status: parseInt(newStatus, 10),
    })
      .then(() => {
        setMessage(t("status-updated-successfully"));
        setSnackbarStatus(true);
        setEditModalStatus(false);
        fetchCoupons();
      })
      .catch((error) => {
        console.error(error);
        setMessage(t("failed-to-update-status"));
        setSnackbarStatus(true);
      });
  };

  const getActionItems = (index) => (
    <Box sx={{ display: "flex", justifyContent: "center", minWidth: "120px" }}>
      <Tooltip title={t("view-details")}>
        <IconButton
          sx={{ color: theme.palette.neutral }}
          size="small"
          onClick={() => modalHandler("view", index)}
        >
          <VisibilityIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title={t("edit-status")}>
        <IconButton
          color="secondary"
          size="small"
          onClick={() => modalHandler("edit", index)}
        >
          <EditIcon />
        </IconButton>
      </Tooltip>
    </Box>
  );

  return (
    <>
      <BaseSnackbar
        open={openSnackbar}
        message={message}
        setOpen={setSnackbarStatus}
      />
      <BaseModal
        title={t("coupon-details")}
        open={openViewModal}
        setOpen={setViewModalStatus}
        sx={{
          width: "40%",
          height: "auto",
          overflow: "none",
        }}
        children={
          <Box>
            <Typography
              variant="h6"
              sx={{ marginBottom: 2, textAlign: "center" }}
            >
              {t("Fixtures and Bets")}
            </Typography>
            <Divider sx={{ marginBottom: 2 }} />
            {selectedRow.bets?.length > 0 ? (
              <>
                {selectedRow.bets.map((bet, idx) => (
                  <Box key={idx} sx={{ paddingBottom: 2 }}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginBottom: 1,
                      }}
                    >
                      <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                        {`${bet.fixture.home_team_name} - ${bet.fixture.away_team_name}`}
                      </Typography>
                      <Typography
                        variant="body1"
                        sx={{ color: "text.secondary" }}
                      >
                        {`${bet.odd.value} - ${bet.odd.odd}`}
                      </Typography>
                    </Box>
                    {idx < selectedRow.bets.length - 1 && <Divider />}
                  </Box>
                ))}

                <Box sx={{ marginTop: 2 }}>
                  <Divider />
                  <Typography
                    variant="body2"
                    sx={{ fontWeight: "bold", marginTop: 2 }}
                  >
                    {t("bet-amount")}:{" "}
                    {selectedRow.bet_amount || "0.00"}
                  </Typography>
                  <Typography variant="body2">
                    {t("expected-payout")}:{" "}
                    {selectedRow.total_payout || "0.00"}
                  </Typography>
                </Box>
              </>
            ) : (
              <Typography
                variant="body1"
                sx={{ textAlign: "center", color: "text.secondary" }}
              >
                {t("No bets found")}
              </Typography>
            )}
          </Box>
        }
      />

      <BaseModal
        title={t("change-coupon-status")}
        open={openEditModal}
        setOpen={setEditModalStatus}
        children={
          <>
            <Select
              fullWidth
              value={newStatus}
              onChange={(e) => setNewStatus(e.target.value)}
              sx={{ mt: 2 }}
            >
              {Object.entries(couponStatusMap).map(([key, label]) => (
                <MenuItem key={key} value={key}>
                  {label}
                </MenuItem>
              ))}
            </Select>
            <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
              <Button onClick={() => setEditModalStatus(false)}>
                {t("cancel")}
              </Button>
              <Button
                onClick={handleEditStatus}
                variant="contained"
                color="accent"
              >
                {t("save")}
              </Button>
            </Box>
          </>
        }
      />

      <BaseTable
        head={TABLE_HEAD}
        data={data.map(({ row }) => row)}
        actionItemPrep={getActionItems}
        pagination={{
          paginationData: { ...paginationData, totalCount },
          setPaginationData,
        }}
        loadingData={loadingData}
      />
    </>
  );
}
