import * as Yup from "yup";
import { useEffect, useState } from "react";
import { useFormik, Form, FormikProvider } from "formik";
// material
import {
  Stack,
  TextField,
  FormControl,
  Box,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
// app
import { useStore } from "../../store/Store";
import { ProfileService } from "../../api/services";
import BaseSnackbar from "../BaseSnackbar";
import { useTranslation } from "react-i18next";
// ----------------------------------------------------------------------

export default function ProfileDetailsForm() {
  const { t } = useTranslation();
  const [showNotification, setShowNotification] = useState(false);
  const [message, setMessage] = useState("");
  const [store, dispatch] = useStore();
  const [user, setUser] = useState({});

  useEffect(() => {
    switch(store.userType) {
      case "partition":
        setUser(store.partition.user);
        break;
      case "distributor":
        setUser(store.distributor.user);
        break;
      case "store":
        setUser(store.store.user);
        break;
      default:
        setUser({});
    }
  }, []);

  const ProfileDetailsSchema = Yup.object().shape({
    first_name: Yup.string()
      .required(t("first-name-is-required"))
      .matches(/^[^?"'!@#%<>;]*$/, t("name-invalid-characters"))
      .test(
        "has-more-letters-than-commas-and-periods",
        t("name-must-have-more-letters-than-commas-and-periods"),
        (value) => {
          if (!value) {
            return true;
          }
          const lettersCount = (value.match(/[a-zA-Z]/g) || []).length;
          const specialCharsCount = (value.match(/[,./]/g) || []).length;
          return lettersCount > specialCharsCount;
        }
      ),
    last_name: Yup.string()
      .required(t("last-name-is-required"))
      .matches(/^[^?"'!@#%<>;]*$/, t("name-invalid-characters"))
      .test(
        "has-more-letters-than-commas-and-periods",
        t("name-must-have-more-letters-than-commas-and-periods"),
        (value) => {
          if (!value) {
            return true;
          }
          const lettersCount = (value.match(/[a-zA-Z]/g) || []).length;
          const specialCharsCount = (value.match(/[,./]/g) || []).length;
          return lettersCount > specialCharsCount;
        }
      ),
  });  

  const getUserInitialValues = () => {
    switch(store.userType) {
      case "partition":
        return {
          first_name: store.partition.user.first_name,
          last_name: store.partition.user.last_name,
        };
      case "distributor":
        return {
          first_name: store.distributor.user.first_name,
          last_name: store.distributor.user.last_name,
        };
      case "store":
        return {
          first_name: store.store.user.first_name,
          last_name: store.store.user.last_name,
        };
      default:
        return { first_name: "", last_name: "" };
    }
  };
  const getUpdateProfileService = (userId, payload) => {
    switch(store.userType) {
      case "partition":
        return ProfileService.updatePartitionUser(userId, payload);
      case "distributor":
        return ProfileService.updateDistributorUserUser(userId, payload);
      case "store":
        return ProfileService.updateStoreUser(userId, payload);
      default:
        throw new Error("Invalid user type");
    }
  };
  const getStoreUpdateAction = () => {
    switch(store.userType) {
      case "partition":
        return "UPDATE_PARTITON_USER";
      case "distributor":
        return "UPDATE_DISTRIBUTOR_USER";
      case "store":
        return "UPDATE_STORE_USER";
      default:
        throw new Error("Invalid user type");
    }
  };

  const formik = useFormik({
    initialValues: getUserInitialValues(),
    validationSchema: ProfileDetailsSchema,
    onSubmit: (values, actions) => {
      const payload = {
        first_name: values.first_name,
        last_name: values.last_name,
      };

      getUpdateProfileService(user.id, payload)
        .then((response) => {
          if (response.status === 200) {
            setTimeout(() => {
              setMessage(t("profile-updated-successfully"));
              setShowNotification(true);
              actions.setSubmitting(false);
            }, 500);
            let updatedUser = { ...user };
            updatedUser.first_name = values.first_name;
            updatedUser.last_name = values.last_name;
            dispatch({
              type: getStoreUpdateAction(),
              payload: { user: updatedUser },
            });
          } else {
            throw "profile could not be updated";
          }
        })
        .catch((err) => {
          setMessage(t("profile-could-not-be-updated"));
          setShowNotification(true);
        });
    },
  });

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

  return (
    <>
      <BaseSnackbar
        open={showNotification}
        message={message}
        setOpen={setShowNotification}
      />
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Stack spacing={3}>
            <FormControl>
              <TextField
                required
                color="secondary"
                label={t("first-name")}
                {...getFieldProps("first_name")}
                error={Boolean(touched.first_name && errors.first_name)}
                helperText={touched.first_name && errors.first_name}
              />
            </FormControl>

            <FormControl>
              <TextField
                required
                color="secondary"
                label={t("last-name")}
                {...getFieldProps("last_name")}
                error={Boolean(touched.last_name && errors.last_name)}
                helperText={touched.last_name && errors.last_name}
              />
            </FormControl>

            <FormControl>
              <TextField
                color="secondary"
                type="email"
                label={t("email")}
                defaultValue={
                  store.userType === "partition" 
                    ? store.partition.user.email 
                    : store.userType === "distributor"
                    ? store.distributor.user.email
                    : store.userType === "store"
                    ? store.store.user.email
                    : ""
                }
                disabled
              />
            </FormControl>
          </Stack>
          <br />

          <Box style={{ textAlign: "right" }}>
            <LoadingButton
              color="secondary"
              size="large"
              type="submit"
              variant="contained"
              loading={isSubmitting}
            >
              {t("update-profile")}
            </LoadingButton>
          </Box>
        </Form>
      </FormikProvider>
    </>
  );
}
