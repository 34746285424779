import api from "../Api"
import urls from "../Urls";

export const partitionLogin = (payload) => {
  return api.post(urls.partition_login, payload);
};

export const distributorLogin = (payload) => {
  return api.post(urls.distributor_login, payload);
};


export const storeLogin = (payload) => {
  return api.post(urls.store_login, payload);
};


export const updatePartitionUser = (partition_user_id, payload ) => {
  return api.put(urls.update_partition_user(partition_user_id), payload)
};

export const changePartitionUserPassword = (partition_user_id, payload ) => {
  return api.put(urls.change_partition_password(partition_user_id), payload)
};


export const updateDistributorUserUser = (distributor_user_id, payload ) => {
  return api.put(urls.update_distributor_user(distributor_user_id), payload)
};

export const changeDistributorUserUserPassword = (distributor_user_id, payload ) => {
  return api.put(urls.change_distributor_password(distributor_user_id), payload)
};


export const updateStoreUser = (store_user_id, payload ) => {
  return api.put(urls.update_store_user(store_user_id), payload)
};

export const changeStoreUserPassword = (store_user_id, payload ) => {
  return api.put(urls.change_store_password(store_user_id), payload)
};