import React, { useEffect, useState, useCallback } from 'react';
import { 
  Box, 
  Typography, 
  List, 
  ListItem,
  IconButton,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { OddsService, OnlineOddsService } from '../../../api/services';
import { useStore } from '../../../store/Store';
import { useTranslation } from 'react-i18next';

const CountryRow = styled(Accordion)(({ theme }) => ({
  '&:before': {
    display: 'none',
  },
  boxShadow: 'none',
  borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
}));

const LeagueRow = styled(ListItem)(({ theme }) => ({
  display: 'flex',
  padding: '12px 16px',
  backgroundColor: theme.palette.common.white,
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: theme.palette.grey[200],
  }
}));

const LeagueContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  flex: 1,
  gap: theme.spacing(2)
}));

const LeagueImage = styled('img')({
  width: '24px',
  height: '24px',
  objectFit: 'contain'
});

const CountryFlag = styled('img')({
  width: '24px',
  height: '24px',
  objectFit: 'contain',
  marginRight: '12px'
});

export default function AllMatches() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const theme = useTheme();
  const [leaguesByCountry, setLeaguesByCountry] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [store,] = useStore()

  const fetchAllLeagues = useCallback(async () => {
    if (!isLoading) return;

    try {

      let apiService = store.userType === 'kiosk' ? OddsService.listLeagues() : OnlineOddsService.listOnlineLeagues();

      const response = await apiService;
      const allLeagues = response.data || [];

      const groupedLeagues = allLeagues.reduce((acc, league) => {
        if (league.country) {
          const countryId = league.country.id;
          if (!acc[countryId]) {
            acc[countryId] = {
              countryName: league.country.name,
              countryFlag: league.country.flag,
              leagues: []
            };
          }
          acc[countryId].leagues.push(league);
        }
        return acc;
      }, {});

      setLeaguesByCountry(groupedLeagues);
    } catch (error) {
      console.error('Error fetching leagues:', error);
    } finally {
      setIsLoading(false);
    }
  }, [isLoading]);

  useEffect(() => {
    fetchAllLeagues();
  }, [fetchAllLeagues]);

  const handleLeagueClick = useCallback((leagueId) => {
    store.userType === "kiosk" 
      ? navigate(`/ki/league/${leagueId}`)
      : navigate(`/dt/${store.hashCode}/on/league/${leagueId}`);
  }, [navigate, store.userType, store.hashCode]);

  return (
    <Box mt={2}>
      {Object.entries(leaguesByCountry).map(([countryId, countryData]) => (
        <CountryRow key={countryId}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            sx={{ 
              backgroundColor: theme.palette.grey[100],
              borderBottom: '1px solid rgba(0, 0, 0, 0.12)'
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              {countryData.countryFlag && (
                <CountryFlag
                  src={countryData.countryFlag}
                  alt={countryData.countryName}
                  onError={(e) => {
                    e.target.src = '/fallback-flag.png';
                  }}
                />
              )}
              <Typography>{countryData.countryName}</Typography>
            </Box>
          </AccordionSummary>
          
          <AccordionDetails sx={{ padding: 0 }}>
            <List disablePadding>
              {countryData.leagues.map((league) => (
                <LeagueRow 
                  key={league.id}
                  onClick={() => handleLeagueClick(league.id)}
                >
                  <LeagueContainer>
                    <LeagueImage
                      src={league.logo}
                      alt={league.name}
                      onError={(e) => {
                        e.target.src = '/fallback-league-logo.png';
                      }}
                    />
                    <Typography variant="subtitle1">
                      {league.name}
                    </Typography>
                  </LeagueContainer>
                  
                  <IconButton edge="end">
                    <ChevronRightIcon />
                  </IconButton>
                </LeagueRow>
              ))}
            </List>
          </AccordionDetails>
        </CountryRow>
      ))}
    </Box>
  );
}