import { useRoutes, Navigate } from 'react-router-dom';
import { useStore } from './store/Store';
import Auth from './Auth';
import WentWrong from './pages/WentWrong';
import { isTokenExpired } from './utils/Util';
import Page404 from './pages/Page404';
import LandingPage from './pages/kiosk/LandingPage';
import Layout from './layouts/betLayouts/defaultLayout/Layout';
import CouponSummary from './layouts/betLayouts/defaultLayout/CouponSummary';
import TipoLayout from './layouts/betLayouts/tipoLayout/TipoLayout';
import TipoLandingPage from './pages/kiosk/TipoLandingPage';
import AllMatches from './layouts/betLayouts/tipoLayout/AllMatches';
import OddsDetail from './layouts/betLayouts/tipoLayout/OddsDetail';
import TodayOdds from './layouts/betLayouts/tipoLayout/TodayOdds';
import LastMinuteOdds from './layouts/betLayouts/tipoLayout/LastMinuteOdds';
import LeagueMatchList from './layouts/betLayouts/tipoLayout/LeagueMatchList';

export default function KioskRouter() {
  const [store, dispatch] = useStore();
  const kiosk = JSON.parse(localStorage.getItem("kiosk"))
  const layout = store.services?.style?.kiosk_layout === 1 ? 'default' : 'tipo';

  const handleAppRedirect = () => {
    if (isTokenExpired(kiosk?.expires_at)) {
      (async () => {
        await dispatch({ type: "KIOSK_LOGOUT" });
      })();
    }

    if (kiosk !== null && store?.kiosk?.token !== null) {
        return layout === 'default' ? <Layout /> :  <TipoLayout />;
    } else {
      return <Navigate to="/ki/login" />;
    }
  };

  const defaultRoutes = [
    {
      path: "/ki",
      element:handleAppRedirect(),
      children: [
        { path: "/ki", index: true, element: <Navigate to="/ki/landing" replace /> },
        { path: "/ki/landing", element: <LandingPage/>},
        { path: "/ki/coupon-summary", element: <CouponSummary/>},

      ]
    },
    {
      path: "/ki/login",
      element:(kiosk === null || kiosk?.token === null ) ? <Auth type="kiosk-login" /> : <Navigate to="/ki"/>,
    },
    { path: "*", element: <Page404 /> },
    { path: "/oops", element: <WentWrong /> },
  ]

  const tipoRoutes = [
    {
      path: "/ki",
      element:handleAppRedirect(),
      children: [
        { path: "/ki", index: true, element: <Navigate to="/ki/landing" replace /> },
        { path: "/ki/landing", element: <TipoLandingPage/>},
        { path: "/ki/all-matches", element: <AllMatches/>},
        { path: "/ki/odds-detail/:fixture_id", element: <OddsDetail/>},
        { path: "/ki/today", element: <TodayOdds/>},
        { path: "/ki/last-minute", element: <LastMinuteOdds/>},
        { path: "/ki/league/:leagueId", element: <LeagueMatchList /> },
      ]
    },
    {
      path: "/ki/login",
      element:(kiosk === null || kiosk?.token === null ) ? <Auth type="kiosk-login" /> : <Navigate to="/ki"/>,
    },
    { path: "*", element: <Page404 /> },
    { path: "/oops", element: <WentWrong /> }
  ]

  let routes = layout === 'default' ? defaultRoutes : tipoRoutes;


  return useRoutes(routes);
}
