import React, { useState, useEffect } from "react";
import { TextField, Button, Grid, Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { UsersService } from "../../../../api/services";

export default function EditOnlineUser({
  successCallback,
  formData,
  setModalStatus,
  setSnackbarStatus,
  setMessage,
}) {
  const { t } = useTranslation();
  const [userName, setUserName] = useState("");

  useEffect(() => {
    if (formData) {
      setUserName(formData.user_name || ""); 
    }
  }, [formData]);

  const handleSubmit = (event) => {
    event.preventDefault();

    const updatedData = {
      user_name: userName,
    };

    UsersService.updateOnlineUser(formData.id, updatedData)
      .then((response) => {
        successCallback();
        setModalStatus(false); 
        setSnackbarStatus(true); 
        setMessage(t("online-user-updated-successfully"));
      })
      .catch((err) => {
        console.error("Update failed:", err);
        setSnackbarStatus(true);
        setMessage(t("failed-to-update-online-user"));
      });
  };

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            label={t("username")}
            value={userName}
            onChange={(e) => setUserName(e.target.value)}
            fullWidth
            required
          />
        </Grid>

        <Grid item xs={12}>
          <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 1 }}>
            <Button onClick={() => setModalStatus(false)}>{t("cancel")}</Button>
            <Button type="submit" color="secondary">
              {t("save")}
            </Button>
          </Box>
        </Grid>
      </Grid>
    </form>
  );
}
