import React, { createContext, useReducer, useContext, useEffect } from "react";
import Reducer from "./Reducer";
import palette from "../theme/palette";

const StoreContext = createContext(null);

const Store = ({ children, initialStateData = {}}) => {

  const platform = JSON.parse(localStorage.getItem("platform"))
  const partition = JSON.parse(localStorage.getItem("partition"))
  const distributor = JSON.parse(localStorage.getItem("distributor"))
  const storeUser = JSON.parse(localStorage.getItem("storeUser"))
  const kiosk = JSON.parse(localStorage.getItem("kiosk"))
  const onlineUser = JSON.parse(localStorage.getItem("onlineUser"))
  const language = localStorage.getItem("language");
  const services = JSON.parse(localStorage.getItem("services"));
  const hashCode = localStorage.getItem("hashCode");

  const isDrawerOpen = JSON.parse(localStorage.getItem("isDrawerOpen"))

  const selected_bets = JSON.parse(
    localStorage.getItem("selected_bets")
  );
  const initialState = {
    platform: platform ? platform : null,
    partition: partition ? partition : null,
    distributor: distributor ? distributor : null,
    storeUser: storeUser ? storeUser : null,
    kiosk: kiosk ? kiosk : null,
    onlineUser : onlineUser ? onlineUser : null,
    userType: null,
    language: language ? language : "en-US",
    breadcrumb: [],
    services: services ? services : null,
    palette: {...palette},
    isDrawerOpen: isDrawerOpen ? isDrawerOpen : false,
    hashCode : hashCode ? hashCode : null,
    styleUpdatedOnState: false,
    selected_bets  : selected_bets ? selected_bets : [],
    ...initialStateData,
  };


  const [store, dispatch] = useReducer(Reducer, initialState);

    useEffect(() => {
      if (
        initialState.styleUpdatedOnState &&
        !store.manualUpdateTriggeredForPalette
      ) {
        dispatch({
          type: "UPDATE_INITIAL",
          payload: {
            services: {
              style : {
                palettes: initialStateData.palettes,
                selectedPalette: initialStateData.selectedPalette,
                logo: initialState.logo,
                landingImage: initialState.landingImage,
                kiosk_layout: initialStateData.kiosk_layout,
                online_layout: initialStateData.online_layout,
              }
    
            },
            palette: initialStateData.palettes[initialStateData.selectedPalette],
            manualUpdateTriggeredForPalette: true,
            userType: initialStateData.userType,
          },
        });
      }
    }, [initialStateData.userType]);

  
  return (
    <StoreContext.Provider value={[store, dispatch]}>
      {children}
    </StoreContext.Provider>
  );
};

export const useStore = () => useContext(StoreContext);
export default Store;
