import {
    Box,
    Card,
    Grid,
    InputLabel,
    TextField,
  } from "@mui/material";
  import * as Yup from "yup";
  import { useState } from "react";
  import { useTranslation } from "react-i18next";
  import { Form, FormikProvider, useFormik } from "formik";
  import { StoreService } from "../../../../api/services";
  import BaseSnackbar from "../../../../components/BaseSnackbar";
  import SaveIcon from "@mui/icons-material/Save";
  import { LoadingButton } from "@mui/lab";
  import { useTheme } from "@mui/material/styles";
  
  export default function AddNewStore({ setModalStatus, successCallback }) {
    const { t } = useTranslation();
    const theme = useTheme();
    const [message, setMessage] = useState("");
    const [openSnackbar, setSnackbarStatus] = useState(false);
  
    const StoreSchema = Yup.object().shape({
      name: Yup.string().required(t("store-name-is-required")),
    });
  
    const formik = useFormik({
      validationSchema: StoreSchema,
      initialValues: {
        name: "",
      },
      onSubmit: (values, actions) => {
        const payload = {
          name: values.name,
        };
  
        StoreService.createStore(payload)
          .then((response) => {
            if (response.status === 201 || response.status === 200) {
              setMessage(t("new-store-added-successfully"));
              setSnackbarStatus(true);
              setTimeout(() => {
                setModalStatus(false);
                successCallback();
              }, 600);
            } else {
              throw new Error("Failed to create store");
            }
          })
          .catch((err) => {
            console.error(err);
            setMessage(t("store-add-failed"));
            setSnackbarStatus(true);
            setTimeout(() => {
              actions.setSubmitting(false);
            }, 1000);
          });
      },
    });
  
    const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;
  
    return (
      <>
        <BaseSnackbar
          open={openSnackbar}
          message={message}
          setOpen={setSnackbarStatus}
        />
  
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Card
              sx={{
                display: "flex",
                flexDirection: "column",
                padding: 5,
                gap: 4,
                mt: 5,
              }}
            >
              <Grid container spacing={10}>
                <Grid item xs={12} sx={{ display: "flex", flexDirection: "column" }}>
                  <InputLabel>
                    {t("store-name")}<span style={{ color: "red" }}>*</span>
                  </InputLabel>
                  <TextField
                    fullWidth
                    id="name"
                    label={t("store-name")}
                    placeholder={t("enter-store-name")}
                    {...getFieldProps("name")}
                    error={Boolean(touched.name && errors.name)}
                    helperText={touched.name && errors.name}
                  />
                </Grid>
              </Grid>
  
              <Box className="add-store-button" sx={{ alignSelf: "center" }}>
                <LoadingButton
                  size="large"
                  type="submit"
                  variant="contained"
                  color="accent"
                  loading={isSubmitting}
                  startIcon={<SaveIcon />}
                >
                  {t("add-store")}
                </LoadingButton>
              </Box>
            </Card>
          </Form>
        </FormikProvider>
      </>
    );
  }
  