import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Logo from "../../../components/Logo";
import { Link as RouterLink, useNavigate, useLocation } from "react-router-dom";
import { styled } from "@mui/material/styles";
import LanguageSelector from "../../../components/LanguageSelector";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { useStore } from "../../../store/Store";
import { languageToCountry, languageToTimezone } from "../../../utils/Util";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ConfirmationNumberIcon from "@mui/icons-material/ConfirmationNumber"; 
import { Box, Menu, MenuItem, IconButton, Typography, Tooltip } from "@mui/material";

const MainStyle = styled("div")(({ theme }) => ({
  maxHeight: "70px",
  backgroundColor: theme.palette.primary.darker,
  display: "flex",
  alignItems: "center",
  padding: "0 16px",
  justifyContent: "space-between",
  [theme.breakpoints.down("sm")]: {
    padding: "0 8px", 
  },
}));

export default function Header() {
  const { t } = useTranslation();
  const [currentTime, setCurrentTime] = useState("");
  const [country, setCountry] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [store, dispatch] = useStore();
  const navigate = useNavigate();
  const location = useLocation();

  const match = location.pathname.match(/^\/dt\/(\d{6})\/on/);
  const hashCode = match ? match[1] : null;

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    handleClose();

    if (store.userType === "kiosk") {
      dispatch({ type: "KIOSK_LOGOUT" });
      navigate("/ki/login", { replace: true });
    } else if (store.userType === 'onlineUser') {
      dispatch({ type: "ONLINE_LOGOUT" });
      navigate(`/dt/${hashCode}/on/login`, { replace: true });
    }
    
  };

  useEffect(() => {
    const updateTime = () => {
      const timezone = languageToTimezone(store.language);
      const options = {
        hour: "2-digit",
        minute: "2-digit",
        timeZone: timezone,
      };
      setCurrentTime(new Date().toLocaleTimeString(store.language, options));
      setCountry(languageToCountry(store.language));
    };

    updateTime();
    const timer = setInterval(updateTime, 1000);

    return () => clearInterval(timer);
  }, [store.language]);

  const navigatePath =
    store.userType === "kiosk"
      ? "/ki"
      : hashCode
      ? `/dt/${hashCode}/on/landing`
      : "/on/landing";

  const handleCouponsClick = () => {
    if (hashCode) {
      navigate(`/dt/${hashCode}/on/coupons`);
    }
  };


  return (
    <MainStyle>
      <Box
        component={RouterLink}
        to={navigatePath}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: 2,
          marginLeft: "-15px",
        }}
      >
        <Logo
          sx={{
            width: { xs: 100, md: 150 },
            height: { xs: 40, md: 60 },
          }}
        />
      </Box>

      <Box sx={{ display: "flex", alignItems: "center", color: "white" }}>
        {store.userType !== "kiosk" && (
          <Tooltip title={t("coupons")}>
            <IconButton size="large" edge="end" onClick={handleCouponsClick}>
              <ConfirmationNumberIcon
                sx={{ color: "white", fontSize: { xs: "20px", md: "32px" } }}
              />
            </IconButton>
          </Tooltip>
        )}

        <IconButton size="large" edge="end" onClick={handleMenu}>
          <AccountCircleIcon sx={{ color: "white", fontSize: { xs: "20px", md: "32px" } }} />
        </IconButton>
        <Menu
          id="menu-appbar"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem onClick={handleLogout}>{t("logout")}</MenuItem>
        </Menu>

        <Box sx={{ display: "flex", alignItems: "center", margin: { xs: 0.5, sm: 1.5 } }}>
          <AccessTimeIcon sx={{ marginRight: 0.5, marginLeft: 0.5, fontSize: { xs: "16px", sm: "20px", md: "24px" } }} />
          <Typography variant="body2" sx={{ fontSize: { xs: "12px", md: "16px" } }}>
            {currentTime}
          </Typography>
          <Typography variant="body2" sx={{ fontSize: { xs: "12px", md: "16px" }, marginLeft: 0.5 }}>
            - {country}
          </Typography>
        </Box>

        <LanguageSelector />
      </Box>
    </MainStyle>
  );
}
