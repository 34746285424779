import { useState } from "react";
import { Stack, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useTranslation } from "react-i18next";
import { UsersService } from "../../../../api/services";

export default function DeleteOnlineUser({
  formData,
  setModalStatus,
  setSnackbarStatus,
  setMessage,
  successCallback,
}) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const deleteUser = () => {
    setLoading(true);

    UsersService.deletePaDistributorUsers(formData.id)
      .then((response) => {
        if (response.status === 204 || response.status === 200) {
          setMessage(t("distributor-user-deleted-successfully"));
          setSnackbarStatus(true);
          setModalStatus(false);
          setLoading(false);
          successCallback();
        } else {
          throw new Error(t("distributor-user-delete-failed"));
        }
      })
      .catch((err) => {
        console.error("Delete failed:", err);
        setTimeout(() => {
          setMessage(t("distributor-user-delete-failed"));
          setSnackbarStatus(true);
          setModalStatus(false);
          setLoading(false);
        }, 1000);
      });
  };

  return (
    <Stack spacing={3}>
      <Typography component="h6">
        {t("are-you-sure-delete-this-user")}
      </Typography>

      <Stack sx={{ display: "block" }} direction="row" spacing={1}>
        <Typography component="subtitle1" variant="h6">
          {formData.user_name}
        </Typography>
      </Stack>

      <Stack sx={{ display: "block" }} direction="row" spacing={2}>
        <LoadingButton
          color="primary"
          variant="contained"
          disabled={loading}
          onClick={() => setModalStatus(false)}
        >
          {t("cancel")}
        </LoadingButton>
        <LoadingButton
          color="secondary"
          variant="contained"
          loading={loading}
          onClick={deleteUser}
        >
          {t("delete")}
        </LoadingButton>
      </Stack>
    </Stack>
  );
}
