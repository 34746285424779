import { useRoutes, Navigate, useLocation, useNavigate } from "react-router-dom";
import { useStore } from "./store/Store";
import Auth from "./Auth";
import WentWrong from "./pages/WentWrong";
import { isTokenExpired } from "./utils/Util";
import Page404 from "./pages/Page404";
import Landing from "./pages/onlineUser/Landing";
import Layout from "./layouts/betLayouts/defaultLayout/Layout";
import TipoLayout from "./layouts/betLayouts/tipoLayout/TipoLayout";
import CouponSummary from "./layouts/betLayouts/defaultLayout/CouponSummary";
import Coupons from "./pages/onlineUser/Coupons";
import TipoLandingPage from "./pages/kiosk/TipoLandingPage";
import OddsDetail from "./layouts/betLayouts/tipoLayout/OddsDetail";
import AllMatches from "./layouts/betLayouts/tipoLayout/AllMatches";
import MyBets from "./layouts/betLayouts/tipoLayout/MyBets";
import TodayOdds from "./layouts/betLayouts/tipoLayout/TodayOdds";
import LastMinuteOdds from "./layouts/betLayouts/tipoLayout/LastMinuteOdds";
import { useEffect , useState} from "react";
import LeagueMatchList from "./layouts/betLayouts/tipoLayout/LeagueMatchList";

export default function OnlineStoreRouter({hashCode}) {

  
  const [store, dispatch] = useStore();
  const location = useLocation();
  const navigate = useNavigate();
  const onlineUser = JSON.parse(localStorage.getItem("onlineUser"));
  const layout = store.services?.style?.online_layout === 1 ? 'default' : 'tipo';


  const handleAppRedirect = () => {
    if (isTokenExpired(onlineUser?.expires_at)) {
      (async () => {
        await dispatch({ type: "ONLINE_LOGOUT" });
      })();
    }

    if (onlineUser !== null && store?.onlineUser?.token !== null) {
      return layout === 'default' ? <Layout /> :  <TipoLayout />;
    } else {
      return <Navigate to={`/dt/${hashCode}/on/login`} />;
    }
  };

  const tipoRoutes = [
    {
      path: `/dt/${hashCode}/on`,
      element: handleAppRedirect(),
      children: [
        { path: `/dt/${hashCode}/on` , element: <Navigate to={`/dt/${hashCode}/on/landing`} replace /> },
        { path: `/dt/${hashCode}/on/landing`, element: <TipoLandingPage /> },
        { path: `/dt/${hashCode}/on/odds-detail/:fixture_id`, element: <OddsDetail /> },
        { path: `/dt/${hashCode}/on/all-matches`, element: <AllMatches /> },
        { path: `/dt/${hashCode}/on/my-bets`, element: <MyBets /> },   
        { path: `/dt/${hashCode}/on/today`, element: <TodayOdds /> },
        { path: `/dt/${hashCode}/on/last-minute`, element: <LastMinuteOdds /> },
        { path: `/dt/${hashCode}/on/league/:leagueId`, element: <LeagueMatchList /> },
      ],
    },
    {
      path: `/dt/${hashCode}/on/login`,
      element:
        (onlineUser === null || onlineUser?.token === null) ? (
          <Auth type="online-login" hashCode = {hashCode} />
        ) : (
          <Navigate to={`/dt/${hashCode}/on`} />
        ),
    },
    { path: "*", element: <Page404 /> },
    { path: "oops", element: <WentWrong /> },
  ]

  const defaultRoutes = [
    {
      path: `/dt/${hashCode}/on`,
      element: handleAppRedirect(),
      children: [
        { path: `/dt/${hashCode}/on` , element: <Navigate to={`/dt/${hashCode}/on/landing`} replace /> },
        { path: `/dt/${hashCode}/on/landing`, element: <Landing /> },
        { path: `/dt/${hashCode}/on/coupon-summary`, element: <CouponSummary /> },
        { path: `/dt/${hashCode}/on/coupons`, element: <Coupons /> },
      ],
    },
    {
      path: `/dt/${hashCode}/on/login`,
      element:
        (onlineUser === null || onlineUser?.token === null) ? (
          <Auth type="online-login" hashCode = {hashCode} />
        ) : (
          <Navigate to={`/dt/${hashCode}/on`} />
        ),
    },
    { path: "*", element: <Page404 /> },
    { path: "oops", element: <WentWrong /> },
  ];

  let routes = layout === 'default' ? defaultRoutes : tipoRoutes;

  return useRoutes(routes);
}
