import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { 
  Box, 
  Typography, 
  List, 
  ListItem,
  IconButton,
  Divider
} from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { OddsService, OnlineOddsService } from '../../../api/services';
import { useStore } from '../../../store/Store';
import { useTranslation } from "react-i18next";

const LeagueRow = styled(ListItem)(({ theme }) => ({
  display: 'flex',
  padding: '6px 8px',
  backgroundColor: theme.palette.common.white,
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: theme.palette.grey[200],
  }
}));

const LeagueContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  flex: 1,
  gap: theme.spacing(2)
}));

const LeagueImage = styled('img')({
  width: '24px',
  height: '24px',
  objectFit: 'contain'
});



export default function RecommendedMatches({ showAllLeagues = false }) {
  const { t } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();
  const [leagues, setLeagues] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [store,] = useStore()

  const fetchLeagues = useCallback(async () => {
    if (!isLoading) return; 

    try {
      let apiService = store.userType === 'kiosk' ? OddsService.listLeagues({order_by: 'Match Count'}) : OnlineOddsService.listOnlineLeagues({order_by: 'Match Count'});

      const response = await apiService;
      const allLeagues = response.data || [];
      
      if (showAllLeagues) {
        setLeagues(allLeagues);
      } else {
       
        setLeagues(allLeagues.slice(0, 4));

      }
    } catch (error) {
      console.error('Error fetching leagues:', error);
    } finally {
      setIsLoading(false);
    }
  }, [showAllLeagues, isLoading]);

  useEffect(() => {
    setIsLoading(true);
  }, [showAllLeagues]);

  useEffect(() => {
    if (isLoading) {
      fetchLeagues();
    }
  }, [fetchLeagues, isLoading]);

  const handleLeagueClick = useCallback((leagueId) => {
    store.userType === "kiosk" 
      ? navigate(`/ki/league/${leagueId}`)
      : navigate(`/dt/${store.hashCode}/on/league/${leagueId}`);
  }, [navigate, store.userType, store.hashCode]);

  const handleAllMatchesClick = useCallback(() => {

    store.userType === "kiosk" ? navigate('/ki/all-matches') : navigate(`/dt/${store.hashCode}/on/all-matches`);

  }, [navigate]);

  return (
    <Box sx={{ width: '100%', bgcolor: theme.palette.common.white, mt:3}}>
      <Typography variant="subtitle1" fontWeight="bold" p={1}>
        {showAllLeagues ? t("all-matches") : t("recommended-matches")}
      </Typography>
      
      <List sx={{ bgcolor: theme.palette.common.white }}>
        {leagues.map((league, index) => (
          <React.Fragment key={league.id}>
            <LeagueRow onClick={() => handleLeagueClick(league.id)}>
              <LeagueContainer>
                <LeagueImage
                  src={league.logo}
                  alt={league.name}
                  onError={(e) => {
                    e.target.src = '/fallback-league-logo.png';
                  }}
                />
                <Box>
                  <Typography variant="subtitle1">
                    {league.name}
                  </Typography>
                  <Typography variant="caption" color="text.secondary">
                    {league.country?.name}
                  </Typography>
                </Box>
              </LeagueContainer>
              
              <IconButton edge="end">
                <ChevronRightIcon />
              </IconButton>
            </LeagueRow>
            <Divider />
          </React.Fragment>
        ))}
      </List>

      {!showAllLeagues && (
        <Box 
          sx={{ 
            p: 1, 
            display: 'flex', 
            justifyContent: 'flex-end',
          }}
        >
          <Typography 
            variant="button" 
            sx={{ 
              cursor: 'pointer',
              display: 'flex',
              alignItems: 'center',
              color: 'black'
            }}
            onClick={handleAllMatchesClick}
          >
            {t("all-matches")}
            <ChevronRightIcon sx={{ ml: 1 }} />
          </Typography>
        </Box>
      )}
    </Box>
  );
}