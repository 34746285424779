import React from "react";
import { Outlet } from "react-router-dom";
import { styled } from "@mui/material/styles";

import Header from "./Header";

const TipoRootStyle = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  minHeight: "100vh",
  overflow: "hidden",
  backgroundColor: theme.palette.primary.lighter,
}));

export default function TipoLayout() {


  return (
    <TipoRootStyle>
      {/* Header Component */}
      <Header />
      <Outlet />

    </TipoRootStyle>
  );
}
