import React, { useState, useEffect } from "react";
import { TextField, Button, Grid, Box, InputLabel, Select, MenuItem,FormControl } from "@mui/material";
import { useTranslation } from "react-i18next";
import { UsersService, CommonService } from "../../../../api/services";

export default function EditDistributorUser({
  successCallback,
  formData,
  setModalStatus,
  setSnackbarStatus,
  setMessage,
}) {
  const { t } = useTranslation();
  
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [telephone, setTelephone] = useState("");
  const [countryId, setCountryId] = useState("");
  const [countries, setCountries] = useState([]);

  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const response = await CommonService.listCountries();
        setCountries(response.data || []);
      } catch (error) {
        console.error("Error fetching countries:", error);
        setSnackbarStatus(true);
        setMessage(t("failed-to-get-countries"));
      }
    };

    fetchCountries();
  }, [setSnackbarStatus, setMessage]);
  useEffect(() => {
    if (formData) {
      setFirstName(formData.first_name || "");
      setLastName(formData.last_name || "");
      setEmail(formData.email || "");
      setAddress1(formData.address1 || "");
      setAddress2(formData.address2 || "");
      setTelephone(formData.telephone || "");
      setCountryId(formData.country_id || "");
    }
  }, [formData]);

  const handleSubmit = (event) => {
    event.preventDefault();

    const updatedData = {
      ...formData, 
      first_name: firstName,
      last_name: lastName,
      email: email,
      properties: {
        address1: address1,
        address2: address2,
        telephone: telephone,
        country_id: countryId,
      },
    };

    console.log("Submitting updated user data:", updatedData);

    UsersService.updateDistributorUsers(formData.id, updatedData)
      .then((response) => {
        successCallback(); 
        setModalStatus(false); 
        setSnackbarStatus(true); 
        setMessage(t("distributor-user-updated-successfully"));
      })
      .catch((err) => {
        console.error("Update failed:", err);
        setSnackbarStatus(true);
        setMessage(t("failed-to-update-distributor-user"));
      });
  };

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            label={t("first-name")}
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            fullWidth
            required
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label={t("last-name")}
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            fullWidth
            required
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label={t("email")}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            fullWidth
            required
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label={t("telephone")}
            value={telephone}
            onChange={(e) => setTelephone(e.target.value)}
            fullWidth
            required
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label={t("address1")}
            value={address1}
            onChange={(e) => setAddress1(e.target.value)}
            fullWidth
            required
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label={t("address2")}
            value={address2}
            onChange={(e) => setAddress2(e.target.value)}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth required>
            <InputLabel id="country-id-label">{t("country")}</InputLabel>
            <Select
              labelId="country-id-label"
              label={t("country")}
              value={countryId}
              onChange={(e) => setCountryId(e.target.value)}
            >
              <MenuItem value="">
                <em>{t("select-country")}</em>
              </MenuItem>
              {countries.map((country) => (
                <MenuItem key={country.id} value={country.id}>
                  {country.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 1 }}>
            <Button onClick={() => setModalStatus(false)}>{t("cancel")}</Button>
            <Button type="submit" color="secondary">
              {t("save")}
            </Button>
          </Box>
        </Grid>
      </Grid>
    </form>
  );
}
