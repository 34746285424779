import dayjs from "dayjs";
/*   import { formatInTimeZone } from 'date-fns-tz'; */

export const rowArrayToObject = (row) => {
  let obj = {};
  row.forEach((d, idx) => {
    obj[d.key] = d.value;
  });
  return obj;
};

export const camelize = (str) => {
  return str
    .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
      return index === 0 ? word.toLowerCase() : word.toUpperCase();
    })
    .replace(/\s+/g, "");
};

/* export function isTokenExpired() {
  const expiresAt = localStorage.getItem("expires_at");
  const currentTime = Date.now() / 1000;
  return currentTime > parseInt(expiresAt);
} */

export function isTokenExpired(expiresAt) {
  const currentTime = Date.now() / 1000;
  return currentTime > parseInt(expiresAt);
}

export const formatDate = (dateString) => {
  if (!dateString) return "";

  const date = new Date(dateString);
  const options = { year: "numeric", month: "2-digit", day: "2-digit" };
  const dateFormatted = date.toLocaleDateString(undefined, options);
  const timeFormatted = date.toLocaleTimeString(undefined, {
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  });

  return `${dateFormatted} ${timeFormatted}`;
};

export const formatDateInput = (date) => {
  return date ? dayjs(date).format("YYYY-MM-DDTHH:mm:ss.SSS") : "";
};

export const isMobile = () => {
  return window.innerWidth <= 768;
};



export const fakeApiCall = ({ items, page, size }) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      const start = (page - 1) * size;
      const end = start + size;
      const paginatedItems = items.slice(start, end);

      const fakeResponse = {
        data: {
          items: paginatedItems,
          total: items.length,
          page: page,
          size: size,
          pages: Math.ceil(items.length / size)
        }
      };

      resolve(fakeResponse);
    }, 600);
  });
};


export const languageToCountry = (lng) => {
  const countryMap = {
    "en-US": "USA",
    "en-GB": "United Kingdom",
    "de-DE": "Germany",
    "fr-FR": "France",
    "it-IT": "Italy",
    "tr-TR": "Turkey",
    "ar-AR": "Saudi Arabia",
    "es-ES": "Spain",
    "hi-HI": "India",
    "id-ID": "Indonesia",
    "ja-JA": "Japan",
    "pt-PT": "Portugal",
    "zh-ZH": "China",
    "da-DA": "Denmark",
    "fi-FI": "Finland",
    "sv-SV": "Sweden"
  };
  
  return countryMap[lng] || "Unknown";
}

export const languageToTimezone = (lng) => {
  const timezoneMap = {
    "en-US": "America/New_York",
    "en-GB": "Europe/London",
    "de-DE": "Europe/Berlin",
    "fr-FR": "Europe/Paris",
    "it-IT": "Europe/Rome",
    "tr-TR": "Europe/Istanbul",
    "ar-AR": "Asia/Riyadh",
    "es-ES": "Europe/Madrid",
    "hi-HI": "Asia/Kolkata",
    "id-ID": "Asia/Jakarta",
    "ja-JA": "Asia/Tokyo",
    "pt-PT": "Europe/Lisbon",
    "zh-ZH": "Asia/Shanghai",
    "da-DA": "Europe/Copenhagen",
    "fi-FI": "Europe/Helsinki",
    "sv-SV": "Europe/Stockholm"
  };
  
  return timezoneMap[lng] || "UTC";
}

export const convertUTCToLocalTime = (utcDate, utcTime, lng) => {
  const utcDateTimeString = `${utcDate}T${utcTime}Z`;
  const utcDateTime = new Date(utcDateTimeString);

  const timezone = languageToTimezone(lng);

  const localDateTime = new Date(utcDateTime.toLocaleString('en-US', {
    timeZone: timezone,
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: false // 24 saat formatı için
  }));

  return localDateTime;
};
export const formatDateTime = (utcDateTimeString, lng) => {
  const utcDateTime = new Date(utcDateTimeString);
  const timezone = languageToTimezone(lng);

  const localDateTime = utcDateTime.toLocaleString(lng, {
    timeZone: timezone,
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true
  });

  return localDateTime;
}

export const setFavIcon = (favicon) => {
  if (favicon) {
    const faviconComponent = document.getElementById("favicon");
    faviconComponent.href = "data:;base64," + favicon;
  }
};