import React, { useState, useEffect } from 'react';
import { 
  Box, 
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useParams, useNavigate } from 'react-router-dom';
import { OddsService, OnlineOddsService } from '../../../api/services';
import { useStore } from '../../../store/Store';
import BottomNavigationBar from './BottomNavigationBar';
import TicketModal from './TicketModal';
import { useTranslation } from 'react-i18next';
import { styled, useTheme } from '@mui/material/styles';

const OddBox = styled(Box)(({ theme, selected }) => ({
  padding: '2px',
  textAlign: 'center',
  cursor: 'pointer',
  transition: 'all 0.2s',
  borderRadius: '4px',
  backgroundColor: selected ? theme.palette.accent.darker : theme.palette.grey[200],
  color: selected ? theme.palette.common.white : theme.palette.common.black,
  '&:hover': {
    backgroundColor: selected ? theme.palette.primary.dark : theme.palette.grey[400],
    color: theme.palette.common.white
  }
}));

const SubCategoryName = styled(Typography)(({ theme }) => ({
  fontSize: '14px',
  color: theme.palette.grey[600],
  marginBottom: '16px'
}));

const OddValue = styled(Typography)({
  fontSize: '13px',
});

const OddNumber = styled(Typography)({
  fontSize: '16px',
  fontWeight: 'bold'
});

export default function OddsDetail() {
  const { fixture_id } = useParams();
  const [matchData, setMatchData] = useState(null);
  const [expanded, setExpanded] = useState('Others'); 
  const [store, dispatch] = useStore();
  const [isTicketModalOpen, setIsTicketModalOpen] = useState(false);
  const theme = useTheme();
  const { t } = useTranslation();

  const handleChangeSelectedBets = (bet) => {
    const isAlreadySelected = store.selected_bets.some((item) => item.odd_id === bet.odd_id);

    let notSelectable = store.selected_bets.some((item) => item.betType === bet.betType && item.fixture_id === bet.fixture_id && item.odd_id !== bet.odd_id);

    if (!notSelectable){
  
      dispatch({
        type: "UPDATE_SELECTED_BETS",
        payload: {
          selected_bets: isAlreadySelected
            ? store.selected_bets.filter((item) => item.odd_id !== bet.odd_id)
            : [...store.selected_bets, bet],
        },
      });
    }

  };
  console.log(store.selected_bets)
  useEffect(() => {
    const fetchOdds = async () => {
      try {
        let apiService = store.userType === 'kiosk' 
          ? OddsService.listFootballOddsDetail(fixture_id) 
          : OnlineOddsService.listOnlineFootballOddsDetail(fixture_id);
        
        const response = await apiService;
        setMatchData(response.data);
      } catch (error) {
        console.error('Error fetching odds:', error);
      }
    };
    fetchOdds();
  }, [fixture_id]);


  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleOddClick = (odd_id, odd_value, odd, category, subCategoryName) => {

    const ticketItem = {
      fixture_id: matchData.fixture.fixture_id,
      odd_id: odd_id,
      homeTeam: matchData.fixture.home_team,
      awayTeam: matchData.fixture.away_team,
      date: matchData.fixture.date,
      time: matchData.fixture.time,
      odd: odd,
      value: odd_value,
      betType: category + ' - ' + subCategoryName,

    };
    
    handleChangeSelectedBets(ticketItem);
  };

 

  if (!matchData) return null;

  return (
    <>
      <Box sx={{ bgcolor: theme.palette.common.white, minHeight: '100vh', p: 2, mb: 15 }}>
        {Object.entries(matchData.bets).map(([category, subCategories]) => (
          <Accordion 
            key={category}
            expanded={expanded === category}
            onChange={handleAccordionChange(category)}
            sx={{ 
              mb: 1,
              '&.MuiAccordion-root': {
                borderRadius: '4px',
                '&:before': {
                  display: 'none',
                }
              }
            }}
          >
            <AccordionSummary 
              expandIcon={<ExpandMoreIcon />}
              sx={{
                backgroundColor: theme.palette.grey[200],
                '& .MuiAccordionSummary-content': {
                  margin: '12px 0'
                }
              }}
            >
              <Typography sx={{ fontWeight: 'bold' }}>{category}</Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ p: 2 }}>
              {Object.entries(subCategories).map(([subCategoryName, odds]) => (
                <Box key={subCategoryName} sx={{ mb: 2 }}>
                  <SubCategoryName>{subCategoryName}</SubCategoryName>
                  <Grid container spacing={2}>
                    {odds.map((odd) => (
                      <Grid item xs={4} key={odd.odd_id}>
                        <OddBox 
                          onClick={() => handleOddClick(
                            odd.odd_id, 
                            odd.value, 
                            odd.odd,
                            category,
                            subCategoryName
                          )}
                          selected={store.selected_bets.some((item) => item.odd_id === odd.odd_id)}
                        >
                          <OddValue>{odd.value}</OddValue>
                          <OddNumber>{odd.odd}</OddNumber>
                        </OddBox>
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              ))}
            </AccordionDetails>
          </Accordion>
        ))}
      </Box>
      <TicketModal 
        open={isTicketModalOpen}
        onClose={() => setIsTicketModalOpen(false)}
        handleChangeSelectedBets= {handleChangeSelectedBets}
      />
      <BottomNavigationBar 
        onTicketClick={() => setIsTicketModalOpen(true)}
        ticketCount={store.selected_bets.length}
      />
    </>
  );
}
