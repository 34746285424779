import api from "../Api";
import urls from "../Urls";

export const listOnlineFootballFixtures = (params) => {
    return api.get(urls.list_online_football_fixtures, { params: params });
};

export const listOnlineFootballOdds = (params) => {
    return api.get(urls.list_online_football_odds, { params: params });
};

export const listOnlineFootballOddsDetail = (fixture_id, params) => {
    return api.get(urls.list_online_football_odds_detail(fixture_id), { params: params });
};

export const createOnlineCoupon =(payload) => {
    return api.post(urls.create_online_coupon, payload)
};

export const listOnlinePlacedBets = (params) => {
    return api.get(urls.list_online_placed_bets, { params: params });
};

export const listOnlineLeagues = (params) => {
    return api.get(urls.list_online_football_leagues, { params: params });
};

export const listOnlineCoupons = (params) => {
    return api.get(urls.list_online_coupons, {params: params});
};

export const updateOnlineCouponStatus = (coupon_id, body) => {
    return api.patch(urls.update_online_coupon_status(coupon_id), body);
};