import { useEffect, useState } from "react";
import { NavLink as RouterLink } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { Box, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { useStore } from "../store/Store";
import { useTranslation } from "react-i18next";

const ListItemStyle = styled((props) => (
  <ListItemButton disableGutters {...props} />
))(({ theme }) => ({
  height: 30,
  width: "100%",
  position: "relative",
  margin: "auto",
  color: theme.palette.secondary.contrastText,
  display: "flex",
  flexDirection: "column",

}));

const ListItemIconStyle = styled(ListItemIcon, {
  shouldForwardProp: (prop) => prop !== "isActive"
})(({ theme, isActive }) => ({
  color: isActive ? theme.palette.accent.main : theme.palette.neutral.light,
  height: "20px",
  width: "20px",
  margin: "auto",
  "& svg": {
    fontSize: "22px",
  },
}));

const ListItemTextStyle = styled(ListItemText)(({ theme }) => ({
  color: theme.palette.neutral.lighter,
  width: "50px",
  "& .MuiListItemText-primary": {
    fontSize: theme.typography.pxToRem(11),
    fontWeight: 800,

    [theme.breakpoints.up("sm")]: {
      fontSize: theme.typography.pxToRem(11),
    },
    [theme.breakpoints.up("md")]: {
      fontSize: theme.typography.pxToRem(13),
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: theme.typography.pxToRem(13),
    },
  },
}));

const StyledBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  height: "100%",
  gap: "5px",
  width: "100%",
  textAlign: "center",
  mb: "20px",
}));

export default function FixedNavItem({ item, isOpen }) {
  const { title, path, icon } = item;
  const [store] = useStore()
  const [isActive, setIsActive] = useState(false);
  const {t} = useTranslation();

  useEffect(() => {
    setIsActive(store.breadcrumb.some((page) => page.header === title));
  }, [store, title])

  return (
    <ListItemStyle component={RouterLink} to={path}>
      <StyledBox>
        <ListItemIconStyle isActive={isActive}>{icon}</ListItemIconStyle>
        <ListItemTextStyle primary={t(title)} />
      </StyledBox>
    </ListItemStyle>
  );
}
