import axios from "axios";

const instance = axios.create({
    headers: {
        "Accept": "application/json",
        "Content-Type": "application/json"
    }
});

instance.interceptors.response.use((response) => {
    return Promise.resolve(response);
}, error => {
    if (error.response.status === 401 || error.response.status === 403) {
        /* if (localStorage.getItem("userType") === "admin") {
          localStorage.removeItem("admin");
        } else if (localStorage.getItem("userType") === "portal") {
          localStorage.removeItem("portal");
        } */

        let userType = localStorage.getItem("userType");
        
        switch(userType){
            case "platform":
                localStorage.removeItem("platform");
                window.location = "/";
            break;
            case "partition":
                localStorage.removeItem("partition");
                window.location = "/";
            break;
            case "distributor":
                localStorage.removeItem("distributor");
                window.location = "/dt";
            break;
            case "store":
                localStorage.removeItem("store");
                window.location = "/st";
            break;
            case "kiosk":
                localStorage.removeItem("kiosk");
                window.location = "/ki";
            break;
            // TODO: 
            /* case "onlineUser":
                localStorage.removeItem("partition");
                window.location = "/dt/";
            break; */
            default:
                window.location = "/";
            break;
        }
  


      } 
    return Promise.reject(error);
});

instance.interceptors.request.use((request) => {

    let token;

    let userType = localStorage.getItem("userType");
    // eslint-disable-next-line 
    switch(userType){
        case "platform":
            token = JSON.parse(localStorage.getItem("platform"))?.token
        break;
        case "partition":
            token = JSON.parse(localStorage.getItem("partition"))?.token
        break;
        case "distributor":
            token = JSON.parse(localStorage.getItem("distributor"))?.token

        break;
        case "store":
            token = JSON.parse(localStorage.getItem("store"))?.token
        break;
        case "kiosk":
            token = JSON.parse(localStorage.getItem("kiosk"))?.token
        break;

        case "onlineUser":
            token = JSON.parse(localStorage.getItem("onlineUser"))?.token
        break;
       /*  default:
            throw new Error(`Unexpected userType: ${userType}`); */
    }

    if (!("Authorization" in request.headers.common) && token) {
        request.headers.common["Authorization"] = `Bearer ${token}`;
    }
    return request;
}, error => {
    return Promise.reject(error);
});

export default instance;
