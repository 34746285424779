import React from 'react';
import { 
  Paper, 
  BottomNavigation as MuiBottomNavigation, 
  BottomNavigationAction,
  Badge
} from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import HomeIcon from '@mui/icons-material/Home';
import ReceiptIcon from '@mui/icons-material/Receipt';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import { useStore } from '../../../store/Store';
import { useTranslation } from 'react-i18next';
import { styled, useTheme } from '@mui/material/styles';

const StyledBottomNavigation = styled(MuiBottomNavigation)(({ theme }) =>({
  backgroundColor: '#fff',
  borderTop: '1px solid #e0e0e0',
  height: '64px',
  width: '100%',
  zIndex: 1100,
  '& .MuiBottomNavigationAction-root': {
    color: theme.palette.neutral.darker,
    minWidth: 'auto',
    padding: '6px 0',
    flex: 1,
    maxWidth: 'none',
    '&.Mui-selected': {
      color: theme.palette.error.darkest
    }
  },
  '& .MuiBottomNavigationAction-label': {
    fontSize: '0.75rem',
    opacity: 1,
    '&.Mui-selected': {
      fontSize: '0.75rem'
    }
  },
  '& .MuiBottomNavigationAction-root .MuiBadge-badge': {
    backgroundColor: theme.palette.error.darkest,
    color: theme.palette.common.white,
    minWidth: '18px',
    height: '18px',
    fontSize: '0.75rem'
  }
}));

export default function BottomNavigation({ onTicketClick, ticketCount = 0 }) {
  const navigate = useNavigate();
  const location = useLocation();
  const [store] = useStore();
  const theme = useTheme();
  const { t } = useTranslation();

  const navigationItems = [
    {
      label: t("homepage"),
      icon: <HomeIcon />,
      path: store.userType === 'kiosk'
        ? '/ki/landing'
        : `/dt/${store.hashCode}/on/landing`
    },
    ...(store.userType !== 'kiosk'
      ? [
          {
            label: t("my-coupons"),
            icon: <ReceiptIcon />,
            path: `/dt/${store.hashCode}/on/my-bets`
          },
        ]
      : []
    ),
    {
      label: t("ticket"),
      icon: (
        <Badge badgeContent={ticketCount}>
          <ConfirmationNumberIcon />
        </Badge>
      ),
      path: store.userType === 'kiosk'
        ? '/ki/ticket'
        : `/dt/${store.hashCode}/on/ticket`
    },
  ];

  const getCurrentValue = () => {
    const currentPath = location.pathname;
    const index = navigationItems.findIndex(item => item.path === currentPath);
    return index !== -1 ? index : 0;
  };

  const handleNavigation = (path, index) => {
    if (path.includes('/ticket')) {
      onTicketClick();
      return;
    }
    navigate(path);
  };

  return (
    <Paper 
      sx={{ 
        position: 'fixed', 
        bottom: 0, 
        left: 0, 
        right: 0,
        zIndex: 1000,
        width: '100%'
      }} 
      elevation={3}
    >
      <StyledBottomNavigation
        value={getCurrentValue()}
        onChange={(event, newValue) => {
          const path = navigationItems[newValue].path;
          handleNavigation(path, newValue);
        }}
        showLabels
        sx={{
          width: '100%',
          '& .MuiBottomNavigationAction-root': {
            flex: 1,
            minWidth: 0,
            px: 0
          }
        }}
      >
        {navigationItems.map((item, index) => (
          <BottomNavigationAction
            key={index}
            label={item.label}
            icon={item.icon}
            sx={{ flex: 1 }}
          />
        ))}
      </StyledBottomNavigation>
    </Paper>
  );
}