import { useState } from "react";
import { Stack, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useTranslation } from "react-i18next";
import { StoreService } from "../../../../api/services";

export default function DeleteStoreForm({
  formData,
  setModalStatus,
  setSnackbarStatus,
  setMessage,
  successCallback,
}) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const deleteStore = () => {
    setLoading(true);

    StoreService.deleteStore(formData.id)
      .then((response) => {
        if (response.status === 204 || response.status === 200) {
          setMessage(t("store-deleted-successfully"));
          setSnackbarStatus(true);
          setModalStatus(false);
          setLoading(false);
          successCallback();
        } else {
          throw t("store-delete-failed");
        }
      })
      .catch((err) => {
        setLoading(true);
        setTimeout(() => {
          setMessage(t("store-delete-failed"));
          setSnackbarStatus(true);
          setModalStatus(false);
          setLoading(false);
        }, 1000);
      });
  };

  return (
    <Stack spacing={3}>
      <Typography component="h6">
        {t("are-you-sure-delete-this-store")}
      </Typography>

      <Stack sx={{ display: "block" }} direction="row" spacing={1}>
        <Typography component="subtitle1" variant="h6">
          {formData.name}
        </Typography>
      </Stack>
      <Typography component="subtitle1" variant="h6">
        {formData.location}
      </Typography>
      <Stack sx={{ display: "block" }} direction="row" spacing={2}>
        <LoadingButton
          type="submit"
          color="primary"
          variant="contained"
          disabled={loading}
          onClick={() => setModalStatus(false)}
        >
          {t("cancel")}
        </LoadingButton>
        <LoadingButton
          type="submit"
          color="secondary"
          variant="contained"
          loading={loading}
          onClick={deleteStore}
        >
          {t("delete")}
        </LoadingButton>
      </Stack>
    </Stack>
  );
}
