import api from "../Api"
import urls from "../Urls";

export const setStyle = (payload) => {
  return api.put(urls.set_style, payload);
};

export const getStyle = () => {
  return api.get(urls.get_style);
};

export const updateSettings = (id, payload) => {
  return api.put(urls.update_settings(id), payload);
};

export const setLogo = (logo) => {
  return api.put(urls.set_logo, logo);
};

export const setFavicon = (icon) => {
  return api.put(urls.set_favicon, icon);
};

export const setLandingImage = (icon) => {
  return api.put(urls.set_landing_image, icon);
};


export const getOnlineStyle = (hash_code) => {
  return api.get(urls.get_online_style(hash_code));
};


export const getDistributorStyle = () => {
  return api.get(urls.get_distributor_style);
};

export const getStoreStyle = () => {
  return api.get(urls.get_store_style);
};

export const getKioskStyle = () => {
  return api.get(urls.get_kiosk_style);
};


//distributor config


export const setDistributorStyle = (payload) => {
  return api.put(urls.set_distributor_style, payload);
};


export const setDistributorLogo = (logo) => {
  return api.put(urls.set_distributor_logo, logo);
};

export const setDistributorFavicon = (icon) => {
  return api.put(urls.set_distributor_favicon, icon);
};

export const setDistributorLandingImage = (icon) => {
  return api.put(urls.set_distributor_landing, icon);
};


export const setLayout = (payload) => {
  return api.put(urls.set_layout, payload);
};

//partition config


export const setPartitionStyle = (payload) => {
  return api.put(urls.set_partition_style, payload);
};


export const setPartitionLogo = (logo) => {
  return api.put(urls.set_partition_logo, logo);
};

export const setPartitionFavicon = (icon) => {
  return api.put(urls.set_partition_favicon, icon);
};

export const setPartitionLandingImage = (icon) => {
  return api.put(urls.set_partition_landing, icon);
};
