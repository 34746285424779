import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Tooltip, IconButton, Typography, Box, Button } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useNavigate, useLocation } from "react-router-dom";
import { DefaultPaginationData } from "../../../constants";
import { rowArrayToObject } from "../../../utils/Util";
import BaseModal from "../../../components/BaseModal";
import BaseSnackbar from "../../../components/BaseSnackbar";
import { BaseTable, TableFilterContainer } from "../../../components/table";
import { UsersService } from "../../../api/services";
import SearchBar from "../../../components/SearchBar";
import AddNewButton from "../../../components/buttons/AddNewButton";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import EditStoreUser from "./editStoreUser/editStoreUser";

export default function StoreUsers() {
  const { t } = useTranslation();
  const theme = useTheme();
  const location = useLocation();
  const { successMessage } = location.state || {};
  const [openSnackbar, setSnackbarStatus] = useState(false);
  const [message, setMessage] = useState("");
  const [data, setData] = useState([]);
  const [paginationData, setPaginationData] = useState(DefaultPaginationData);
  const [loadingData, setLoadingData] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedRow, setSelectedRow] = useState({});
  const [openEditModal, setEditModalStatus] = useState(false);
  const [openDeleteModal, setDeleteModalStatus] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    if (successMessage) {
      setSnackbarStatus(true);
      setMessage(successMessage);
    }
  }, [successMessage]);

  const TABLE_HEAD = [
    { key: "id", label: t("user-id") },
    { key: "email", label: t("user-email") },
    { key: "first_name", label: t("first-name") },
    { key: "last_name", label: t("last-name") },
    { key: "address1", label: t("address-1") },
    { key: "address2", label: t("address-2") },
    { key: "telephone", label: t("telephone") },
    { key: "country_id", label: t("country") },
    { key: "action", label: t("actions"), align: "right" },
  ];

  const TABLE_FIELD_MAPPING = {
  id: { key: "id", index: 0 },  
  email: { key: "email", index: 1 },
  first_name: { key: "first_name", index: 2 },
  last_name: { key: "last_name", index: 3 },
  address1: { key: "address1", index: 4 },
  address2: { key: "address2", index: 5 },
  telephone: { key: "telephone", index: 6 },
  country_id: { key: "country_id", index: 7 },
  };

  const fetchUsers = () => {
    setLoadingData(true);
    const params = {
      page: paginationData.page + 1,
      size: paginationData.rowsPerPage,
    };

   
    UsersService.listDtStoreUsers(params)
    .then((response) => {
      const items = response.data.items.map((itemData) => {
        const item = new Array(TABLE_HEAD.length - 1).fill({});
        Object.keys(TABLE_FIELD_MAPPING).forEach((key) => {
          const value = key === "telephone"
            ? itemData.properties?.telephone 
            : key.includes("address")
            ? itemData.properties?.[key]
            : key === "country_id"
            ? itemData.properties?.country_id
            : itemData[key];
          item[TABLE_FIELD_MAPPING[key].index] = {
            ...TABLE_FIELD_MAPPING[key],
            value: value || "",
          };
        });
        return item;
      });
      setData(items);
      setTotalCount(response.data.total);
    })
    .catch((err) => {
      setMessage(t("failed-to-get-users"));
      setSnackbarStatus(true);
      console.error(err);
    })
    .finally(() => {
      setLoadingData(false);
    });
};

  useEffect(() => {
    fetchUsers();
  }, [paginationData]);

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const filterData = (data, query) => {
    if (!query) return data;
    return data.filter((row) => {
      return ["email", "first_name", "last_name"].some((key) => {
        const cellValue = row[TABLE_FIELD_MAPPING[key]?.index]?.value || "";
        return cellValue.toString().toLowerCase().includes(query.toLowerCase());
      });
    });
  };

  const filteredData = filterData(data, searchQuery);

  const modalHandler = (modalType, index = undefined) => {
  if (index !== undefined) {
    const row = rowArrayToObject(data[index]);  
    setSelectedRow(row);
  }

  switch (modalType) {
    case "edit":
      setEditModalStatus(!openEditModal);
      break;
    case "delete":
      setDeleteModalStatus(!openDeleteModal);
      break;
    default:
      break;
  }
};

const handleDeleteUser = () => {
  if (selectedRow) {
    const userId = selectedRow.id || selectedRow[TABLE_FIELD_MAPPING["id"].index]?.value;  

    if (userId) {
      UsersService.deleteDtStoreUsers(userId)
        .then(() => {
          setMessage(t("user-deleted-successfully"));
          setSnackbarStatus(true);
          fetchUsers();
          setDeleteModalStatus(false);
        })
        .catch((err) => {
          setMessage(t("failed-to-delete-user"));
          setSnackbarStatus(true);
          console.error(err);
        });
    } else {
      console.error("User ID is undefined");
    }
  } else {
    console.error("Selected row is undefined");
  }
};

  const getActionItems = (index) => (
    <Box sx={{ minWidth: "80px" }}>
      <Tooltip title={t("edit-user")}>
        <IconButton
          sx={{
            color: theme.palette.neutral,
          }}
          size="small"
          aria-label="edit-user"
          onClick={() => modalHandler("edit", index)}
        >
          <EditIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title={t("delete-user")}>
        <IconButton
          color="secondary"
          size="small"
          aria-label="delete-user"
          onClick={() => modalHandler("delete", index)}
        >
          <DeleteIcon />
        </IconButton>
      </Tooltip>
    </Box>
  );

  return (
    <>
      <BaseSnackbar
        open={openSnackbar}
        message={message}
        setOpen={setSnackbarStatus}
      />
         <BaseModal
        title={t("edit-store-user") }
        sx={{
          width: {xs:"80%", md:"40%"},
          height: {xs:"80%", md:"auto"},
          overflow: "auto",
        }}
        open={openEditModal}
        setOpen={setEditModalStatus}
        children={
          <EditStoreUser
            successCallback={fetchUsers}
            formData={selectedRow}
            setModalStatus={setEditModalStatus}
            setSnackbarStatus={setSnackbarStatus}
            setMessage={setMessage}
          />
        }
      />
      <BaseModal
        title={t("delete-user")}
        open={openDeleteModal}
        setOpen={setDeleteModalStatus}
        sx={{width: {xs:"80%", md:"20%"}}}
        children={
          <Box>
            <Typography>{t("delete-this-item")}</Typography>
            <Box
              sx={{ marginTop: 2, display: "flex", justifyContent: "flex-end" }}
            >
              <Button
                onClick={() => setDeleteModalStatus(false)}
                sx={{ marginRight: 2 }}
              >
                {t("cancel")}
              </Button>
              <Button color="secondary" onClick={handleDeleteUser}>
                {t("confirm")}
              </Button>
            </Box>
          </Box>
        }
      />
      <TableFilterContainer
        sx={{
          marginTop: "2vh",
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          alignItems: "center",
          justifyContent: "space-between",
          px: 2,
        }}
      >
        <SearchBar
          searchQuery={searchQuery}
          handleSearchChange={handleSearchChange}
        />
        <AddNewButton
          label={t("add-new-user")}
          onClick={() => navigate("/dt/stores/users/create")}
        />
      </TableFilterContainer>
      <BaseTable
        head={TABLE_HEAD}
        data={filteredData}
        actionItemPrep={getActionItems}
        pagination={{
          paginationData: { ...paginationData, totalCount: totalCount },
          setPaginationData: setPaginationData,
        }}
        loadingData={loadingData}
      />
    </>
  );
}
