import { useState } from "react";
import React from "react";
import { styled } from "@mui/system";
import PropTypes from "prop-types";
import Scrollbar from "../Scrollbar";
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TablePagination,
  TableRow,
  TableCell,
  Paper,
  Tooltip,
  Box,
  Typography,
  Checkbox,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import TableSkeleton from "./TableSkeleton";

// ------------------------------------------------------------

const alignType = PropTypes.oneOf([
  "right",
  "left",
  "center",
  "inherit",
  "justify",
]); // default: left

const headCellProp = PropTypes.shape({
  key: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  sortable: PropTypes.bool,
  align: alignType,
});

const dataCellProp = PropTypes.arrayOf(
  PropTypes.shape({
    key: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.node,
      PropTypes.array,
      PropTypes.bool,
    ]),
    cellComponentType: PropTypes.oneOf(["th", "tr"]),
    align: alignType,
    tooltipText: PropTypes.string,
  })
);

const paginationProp = PropTypes.shape({
  paginationData: PropTypes.object,
  setPaginationData: PropTypes.func,
});

BaseTable.propTypes = {
  head: PropTypes.arrayOf(headCellProp).isRequired,
  data: PropTypes.arrayOf(dataCellProp).isRequired,
  pagination: paginationProp,
  actionItemPrep: PropTypes.func,
  collapsible: PropTypes.bool,
  enableCheckbox: PropTypes.bool,
  onCheckboxClick: PropTypes.func,
};

// ------------------------------------------------------------

const PaperStyle = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.background,
  marginTop: 20
}));

const TableHeadCellStyle = styled(TableCell)(({ theme }) => ({
  color: theme.palette.neutral.light,
  backgroundColor: theme.palette.neutral.darkest,
  fontWeight: "bold",
  fontSize: "clamp(1rem, 2vw, 1.3rem)",
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
}));

const TablePaginationStyle = styled(TablePagination)(({ theme }) => ({
  color: theme.palette.neutral.main,
  textDecoration: "bold",
  overflow: "visible",
}));

const getTableDataCellStyle = (theme, smallRow) => ({
  color: theme.palette.neutral.darkest,
  fontWeight: 600,
  fontSize: "clamp(0.8rem, 2vw, 1rem)",
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  ...(smallRow
    ? {
        paddingTop: theme.spacing(0.5),
        paddingBottom: theme.spacing(0.5),
      }
    : {
        paddingTop: theme.spacing(1.5),
        paddingBottom: theme.spacing(1.5),
      }),
});

const TableDataCellStyle = styled(TableCell, {
  shouldForwardProp: (prop) => prop !== "smallRow"
})(({ theme, smallRow }) =>
  getTableDataCellStyle(theme, smallRow)
);

const TableRowStyle = styled(TableRow)(({ theme }) => ({
  "&:hover": {
    backgroundColor: theme.palette.background.lighter,
  },
}));

const TooltipTypographyStyle = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(15),
}));

const BaseTableContainer = styled(Scrollbar)(({ theme }) => ({
  overflow: "auto",
  maxHeight: "calc(100vh - 200px)", // Set the maximum height for the table body
}));

export default function BaseTable({
  head,
  data,
  pagination,
  actionItemPrep,
  loadingData = true,
  select = false,
  onRowSelect,
  onRowSelectIndex = 0,
  onSelectAll,
  onClickName,
  enableSwitch,
  alignSwitch,
  bookmakerSelect,
  smallRow = false,
}) {
  const { paginationData, setPaginationData } = pagination;
  const [numberOfSelectedRows, setNumberOfSelectedRows] = useState(0);

  function* getTableRows(rowData) {
    for (const idx in rowData) {
      let cellData = [];
      rowData[idx].forEach((c) => {
        if (!c.noRender) {
          cellData.push(c);
        }
      });
      yield (
        <TableRowStyle
          key={idx}
          selected={select && cellData[0].key === "select" && cellData[0].value}
        >
          {getTableCells(cellData, idx)}
        </TableRowStyle>
      );
    }
  }

  const getTableCells = (cellData, index) => {
    let cells = [];
    for (const idx in cellData) {
      const d = cellData[idx];
  
      const cellContent = (value) => {
        if (typeof value === "string" || typeof value === "number" || React.isValidElement(value)) {
          return value;
        } else {
          return null;
        }
      };
  
      if (d.key === "name" && onClickName) {
        cells.push(
          <TableDataCellStyle
            key={`${d.key}-${index}`}
            component={d.cellComponentType ? d.cellComponentType : "td"}
            align={d.align ? d.align : "left"}
            onClick={() => {
              onClickName(index);
            }}
            smallRow={smallRow}
            sx={{
              textDecoration: d.onClick !== undefined ? "underline" : "",
              cursor: d.onClick !== undefined ? "pointer" : "zoom-in",
              transition: "transform 400ms",
              "&:hover": {
                transform: "scale(1.15) perspective(2px) translateX(30px)",
              },
            }}
          >
            {cellContent(d.value)}
          </TableDataCellStyle>
        );
      } else if (d.key === "bookmaker_select" && bookmakerSelect) {
        cells.push(
          <TableDataCellStyle key={`bookmaker-${index}`} smallRow={smallRow}>
            {cellContent(bookmakerSelect(index))}
          </TableDataCellStyle>
        );
      } else if (select && d.key === "select") {
        cells.push(
          <TableDataCellStyle
            key={`row-select-checkbox-${index}`}
            component="td"
            align="center"
            smallRow={smallRow}
          >
            <Checkbox
              color="primary"
              checked={data[index][onRowSelectIndex].value}
              onChange={(event) => handleRowSelect(event, index)}
            />
          </TableDataCellStyle>
        );
      } else if (d.key === "enabled" && enableSwitch) {
        cells.push(
          <TableDataCellStyle
            key={`enabled-${index}`}
            component="td"
            align={alignSwitch}
            smallRow={smallRow}
          >
            {cellContent(d.value)}
            {enableSwitch(index)}
          </TableDataCellStyle>
        );
      } else if (d.tooltipText) {
        cells.push(
          <TableDataCellStyle
            key={`${d.key}-${index}`}
            component={d.cellComponentType ? d.cellComponentType : "td"}
            align={d.align ? d.align : "left"}
            sx={{
              textDecoration: d.onClick !== undefined ? "underline" : "",
              cursor: d.onClick !== undefined ? "pointer" : "",
            }}
            smallRow={smallRow}
          >
            {" "}
            <Box
              sx={{
                display: "inline-flex",
                alignItems: "center",
                width: "fit-content",
                "& svg": {
                  mr: -1,
                  ml: 0.5,
                },
              }}
              onClick={d.onClick}
            >
              {cellContent(d.value)}
              <Tooltip
                sx={{ maxWidth: 200 }}
                title={
                  <TooltipTypographyStyle>
                    {d.tooltipText}
                  </TooltipTypographyStyle>
                }
              >
                <InfoIcon />
              </Tooltip>
            </Box>
          </TableDataCellStyle>
        );
      } else {
        cells.push(
          <TableDataCellStyle
            key={`${d.key}-${index}`}
            component={d.cellComponentType ? d.cellComponentType : "td"}
            align={d.align ? d.align : "left"}
            onClick={d.onClick}
            smallRow={smallRow}
            sx={{
              textDecoration: d.onClick !== undefined ? "underline" : "",
              cursor: d.onClick !== undefined ? "pointer" : "",
              paddingRight: d.paddingRight,
              paddingLeft: d.paddingLeft
            }}
          >
            {cellContent(d.value)}
          </TableDataCellStyle>
        );
      }
    }
  
    if (actionItemPrep) {
      cells.push(
        <TableDataCellStyle
          key={`actions-${index}`}
          component="td"
          align="right" // Align the "Actions" column to the right
          smallRow={smallRow}
        >
          {actionItemPrep(index)}
        </TableDataCellStyle>
      );
    }
  
    return cells;
  };
  

  const handleChangePage = (event, newPage) => {
    const data = { ...paginationData, page: newPage };
    setPaginationData(data);
  };

  const handleChangeRowsPerPage = (event) => {
    const data = {
      ...paginationData,
      rowsPerPage: +event.target.value,
      page: 0,
    };
    setPaginationData(data);
  };

  const getTableHead = () => {
    return (
      <TableHead sx={{ width: "100%" }}>
        <TableRow>
          {head.map((h, index) => {
            if (h.key === "select" && select) {
              return (
                <TableHeadCellStyle
                  component="th"
                  key={`select-all-checkbox`}
                  align="center"
                >
                  <Checkbox color="primary" onChange={handleSelectAll} />
                </TableHeadCellStyle>
              );
            }

            return (
              <TableHeadCellStyle
                key={`${h.key}-${index}`}
                align={h.align ? h.align : "left"}
                style={{ paddingRight: h.padding }}
              >
                {h.label}
              </TableHeadCellStyle>
            );
          })}
        </TableRow>
      </TableHead>
    );
  };

  const handleSelectAll = (event) => {
    if (onSelectAll) {
      onSelectAll(event);
    }
    let count = 0;
    /* const selectedValue = event.target.checked;
    const updatedData = data.map((row) => {
      row[onRowSelectIndex].value = selectedValue;
      if (selectedValue) count += 1;
      return row;
    }); */
    setNumberOfSelectedRows(count);
  };

  const handleRowSelect = (event, index) => {
    setNumberOfSelectedRows(
      event.target.checked ? numberOfSelectedRows + 1 : numberOfSelectedRows - 1
    );
    onRowSelect(event.target.checked, index);
  };

  return (
    <>
      {loadingData && <TableSkeleton row={paginationData?.rowsPerPage} />}
      {!loadingData && (
        <PaperStyle>
          <TableContainer>
            <BaseTableContainer>
              <Table>
                {getTableHead()}
                <TableBody>{Array.from(getTableRows(data))}</TableBody>
              </Table>
            </BaseTableContainer>
          </TableContainer>
          {paginationData && (
            <TablePaginationStyle
              rowsPerPageOptions={[5, 10, 25, 50]}
              component="div"
              count={paginationData.totalCount}
              rowsPerPage={paginationData.rowsPerPage}
              page={paginationData.page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )}
        </PaperStyle>
      )}
    </>
  );
}
