import { useEffect, useState } from "react";
import { useTheme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { PlatformSettings } from "../../../api/services";
import BaseSnackbar from "../../../components/BaseSnackbar";
import BaseModal from "../../../components/BaseModal";
import SaveIcon from "@mui/icons-material/Save";
import { BaseTable, TableFilterContainer } from "../../../components/table";
import SearchBar from "../../../components/SearchBar";
import PublicTwoToneIcon from "@mui/icons-material/PublicTwoTone";
import HideImageIcon from "@mui/icons-material/HideImage";
import InfoIcon from "@mui/icons-material/Info";
import {
  Autocomplete,
  Box,
  Checkbox,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import DisableAnyway from "../../../components/DisableAnyway";
import ImageLoader from "../../../components/ImageLoader";
import SearchButton from "../../../components/buttons/SearchButton";

export default function ManageLeagues() {
  const { t } = useTranslation();
  const theme = useTheme();
  const [openSnackbar, setSnackbarStatus] = useState(false);
  const [message, setMessage] = useState("");
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [searchLeague, setSearchLeague] = useState(null);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [searchStatus, setSearchStatus] = useState(null);
  const [updatedLeagues, setUpdatedLeagues] = useState([]);
  const [loadingData, setLoadingData] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [numberOfSelectedRows, setNumberOfSelectedRows] = useState(0);
  const [openDisableAnyway, setOpenDisableAnyway] = useState(false);
  const [paginationData, setPaginationData] = useState({
    page: 0,
    rowsPerPage: 50,
    totalCount: 0,
  });
  const [totalCount, setTotalCount] = useState(0);
  const [countries, setCountries] = useState([]);

  useEffect(() => {
    let result = 0;
    for (const idx in data) if (data[idx][6].value.props.checked) result++;
    setNumberOfSelectedRows(result);
  }, [data]);

  const handleSelectAll = (event) => {
    setSearchStatus("Any");
    const isChecked = event.target.checked;
    setNumberOfSelectedRows(isChecked ? data.length : 0);

    let tempData = [...data];
    let newUpdatedLeagues = [];

    tempData = tempData.map((row) => {
      const leagueID = row[TABLE_FIELD_MAPPING["id"].index].value;
      const currentIsEnabled =
        row[TABLE_FIELD_MAPPING["is_enabled"].index].value.props.children ===
        "True";

      if (currentIsEnabled !== isChecked) {
        newUpdatedLeagues.push({
          league_id: leagueID,
          is_enabled: isChecked,
        });
      }

      row[TABLE_FIELD_MAPPING["select"].index] = {
        ...row[TABLE_FIELD_MAPPING["select"].index],
        value: (
          <Checkbox
            checked={isChecked}
            onChange={(e) => handleRowSelect(e, leagueID)}
          />
        ),
      };

      row[TABLE_FIELD_MAPPING["is_enabled"].index] = {
        ...row[TABLE_FIELD_MAPPING["is_enabled"].index],
        value: (
          <Typography
            sx={{
              color: isChecked
                ? theme.palette.success.main
                : theme.palette.warning.main,
            }}
          >
            {isChecked ? "True" : "False"}
          </Typography>
        ),
      };

      return row;
    });

    setData(tempData);
    /* setFilteredData(filterLeagues(tempData)); */
    setFilteredData(tempData);
    setUpdatedLeagues(newUpdatedLeagues);
  };

  const TABLE_HEAD = [
    { key: "logo", label: t("logo") },
    { key: "name", label: t("name") },
    { key: "country", label: t("country") },
    { key: "country_status", label: t("country-status") },
    { key: "enabled", label: t("enabled"), align: "center" },
    {
      key: "select",
      label: (
        <Checkbox
          checked={data.length > 0 && numberOfSelectedRows === data.length}
          indeterminate={
            numberOfSelectedRows > 0 && numberOfSelectedRows < data.length
          }
          onChange={handleSelectAll}
        />
      ),
      align: "center",
    },
  ];

  const TABLE_FIELD_MAPPING = {
    id: { key: "id", cellComponentType: "th", index: 0, noRender: true },
    logo: { key: "logo", index: 1 },
    name: { key: "name", index: 2 },
    country: { key: "country", index: 3, paddingLeft: 4 },
    country_status: { key: "country_status", index: 4, paddingLeft: 4 },
    is_enabled: { key: "enabled", index: 5, align: "center", paddingRight: 4 },
    select: { key: "select", index: 6, align: "center" },
  };

  const fetchLeagues = () => {
    setLoadingData(true);
    let params = {
      name: searchLeague,
      country_id: selectedCountry,
      is_enabled: searchStatus,
      page: paginationData.page + 1,
      size: paginationData.rowsPerPage,
    };

    PlatformSettings.listPlatformLeagues(1, params)
      .then((response) => {
        if (response.status === 200) {
          let items = [];
          for (const idx in response.data.items.sort(
            (a, b) => b.is_enabled - a.is_enabled
          )) {
            let item = new Array(TABLE_HEAD.length - 1).fill({});
            const itemData = response.data.items[idx];

            item[TABLE_FIELD_MAPPING["select"].index] = {
              ...TABLE_FIELD_MAPPING["select"],
              value: (
                <Checkbox
                  checked={itemData.is_enabled}
                  onChange={(event) => handleRowSelect(event, itemData.id)}
                />
              ),
            };

            item[TABLE_FIELD_MAPPING["country"].index] = {
              ...TABLE_FIELD_MAPPING["country"],
              value: itemData?.country?.name,
            };
            const countryStatus = itemData?.country?.is_enabled;
            const content = (
              <Typography
                sx={{
                  color: countryStatus
                    ? theme.palette.success.main
                    : theme.palette.warning.main,
                }}
              >
                {countryStatus ? "Active" : "Inactive"}
              </Typography>
            );

            item[TABLE_FIELD_MAPPING["country_status"].index] = {
              ...TABLE_FIELD_MAPPING["country_status"],
              value: countryStatus ? (
                content
              ) : (
                <Tooltip title={t("disabled-country-info")}>
                  <Typography
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "4px",
                    }}
                  >
                    {content}
                    <InfoIcon fontSize="small" />
                  </Typography>
                </Tooltip>
              ),
            };

            item[TABLE_FIELD_MAPPING["is_enabled"].index] = {
              ...TABLE_FIELD_MAPPING["is_enabled"],
              value: (
                <Typography
                  sx={{
                    color: itemData.is_enabled
                      ? theme.palette.success.main
                      : theme.palette.warning.main,
                  }}
                >
                  {itemData.is_enabled ? "True" : "False"}
                </Typography>
              ),
            };

            item[TABLE_FIELD_MAPPING["logo"].index] = {
              ...TABLE_FIELD_MAPPING["logo"],
              value: itemData.logo ? (
                <ImageLoader
                  src={itemData.logo}
                  alt={`${itemData.name} logo`}
                  width={20}
                  height={20}
                  style={{ marginRight: 8 }}
                  fallback={
                    <HideImageIcon
                      fontSize="small"
                      sx={{ mr: 0.5, color: "#2e86c1" }}
                    />
                  }
                />
              ) : (
                <PublicTwoToneIcon
                  fontSize="small"
                  sx={{ mr: 0.5, color: "#2e86c1" }}
                />
              ),
            };

            Object.entries(itemData).forEach(([key, value]) => {
              if (
                key in TABLE_FIELD_MAPPING &&
                key !== "is_enabled" &&
                key !== "logo" &&
                key !== "country"
              ) {
                item[TABLE_FIELD_MAPPING[key].index] = {
                  ...TABLE_FIELD_MAPPING[key],
                  value: value,
                };
              }
            });
            items.push(item);
          }
          setTotalCount(response.data.total);
          setData(items);
          setFilteredData(items);
        } else {
          throw new Error("Failed to get leagues");
        }
      })
      .catch((err) => {
        setMessage(t("failed-to-get-leagues"));
        console.log(err);
      })
      .finally(() => {
        setLoadingData(false);
      });
  };

  useEffect(() => {
    fetchLeagues();
  }, [paginationData]);

  useEffect(() => {
    PlatformSettings.listPlatformCountries()
      .then((response) => {
        const options = response.data.map((country) => ({
          value: country.id,
          label: country.name,
        }));
        setCountries(options);
      })
      .catch((err) => {
        console.log("Error fetching countries:", err);
        throw err;
      });
  }, []);

  const handleSave = () => {
    setSubmitting(true);

    let successMessage = t("league-status-update-success");
    let failMessage = t("league-status-update-fail");

    const payload = {
      leagues: updatedLeagues,
    };

    PlatformSettings.bulkUpdatePlatfromLeagues(1, payload)
      .then((response) => {
        if (response.status === 200) {
          setMessage(successMessage);
          setSnackbarStatus(true);
          setUpdatedLeagues([]);
        } else {
          throw new Error("Failed to update leagues");
        }
      })
      .catch((err) => {
        console.error(err);
        setTimeout(function () {
          if (
            err.response.data.detail.includes(
              "There are partitions that use this league"
            )
          ) {
            setOpenDisableAnyway(true);
            setSubmitting(false);
          } else {
            setMessage(failMessage);
            setSnackbarStatus(true);
            setSubmitting(false);
          }
        }, 800);
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const handleRowSelect = (event, leagueID) => {
    setNumberOfSelectedRows(
      event.target.checked ? numberOfSelectedRows + 1 : numberOfSelectedRows - 1
    );
    setFilteredData((prevData) => {
      const tempData = [...prevData];

      const index = tempData.findIndex(
        (item) => item[TABLE_FIELD_MAPPING["id"].index]?.value === leagueID
      );

      const selectedItem = tempData[index];
      const isSelected =
        selectedItem[TABLE_FIELD_MAPPING["select"].index]?.value?.props
          ?.checked;
      const isEnabled =
        selectedItem[TABLE_FIELD_MAPPING["is_enabled"].index]?.value?.props
          ?.children === "True";

      selectedItem[TABLE_FIELD_MAPPING["select"].index] = {
        ...selectedItem[TABLE_FIELD_MAPPING["select"].index],
        value: (
          <Checkbox
            checked={!isSelected}
            onChange={(event) => handleRowSelect(event, leagueID)}
          />
        ),
      };

      selectedItem[TABLE_FIELD_MAPPING["is_enabled"].index] = {
        ...selectedItem[TABLE_FIELD_MAPPING["is_enabled"].index],
        value: (
          <Typography
            sx={{
              color: !isEnabled
                ? theme.palette.success.main
                : theme.palette.warning.main,
            }}
          >
            {!isEnabled ? "True" : "False"}
          </Typography>
        ),
      };

      setUpdatedLeagues((prevLeagues) => {
        const indexInUpdated = prevLeagues.findIndex(
          (item) => item.league_id === leagueID
        );
        if (indexInUpdated === -1) {
          return [
            ...prevLeagues,
            { league_id: leagueID, is_enabled: !isEnabled },
          ];
        } else {
          const newLeagues = [...prevLeagues];
          newLeagues.splice(indexInUpdated, 1);
          return newLeagues;
        }
      });

      return tempData;
    });
  };

  /*  const filterLeagues = (data) => {
    return data.filter((item) => {
      const countryName = item[2].value.toLowerCase();
      const isEnabled = item[4].value.props.children === "True";

      const nameMatch =
        !searchLeague || countryName.includes(searchLeague.toLowerCase());
      const statusMatch =
        searchStatus === "Any" ||
        (searchStatus === "Enabled" && isEnabled) ||
        (searchStatus === "Disabled" && !isEnabled);

      return nameMatch && statusMatch;
    });
  }; */

  /*   useEffect(() => {
    setFilteredData(filterLeagues(data));
  }, [searchLeague, searchStatus]); */

  const disableCallback = () => {
    setUpdatedLeagues([]);
  };

  return (
    <>
      <BaseSnackbar
        open={openSnackbar}
        message={message}
        setOpen={setSnackbarStatus}
      />
      <BaseModal
        title={t("disable-leagues")}
        open={openDisableAnyway}
        setOpen={setOpenDisableAnyway}
        children={
          <DisableAnyway
            successCallback={disableCallback}
            setModalStatus={setOpenDisableAnyway}
            setSnackbarStatus={setSnackbarStatus}
            setMessage={setMessage}
            apiService={() =>
              PlatformSettings.bulkUpdatePlatfromLeagues(
                1,
                { leagues: updatedLeagues },
                {
                  is_proceed: true,
                }
              )
            }
            name={t("leagues")}
            usedIn={t("partitions")}
          />
        }
      />

      <TableFilterContainer      
       sx={{
        marginTop: "2vh",
        display: "flex",
        flexDirection: { xs: "column", sm: "row" },
        alignItems: "center",
        justifyContent: "space-between",
        px: 2,
      }}>
        <Grid sx={{ alignItems: "center" }} container spacing={4}>
          <Grid item md={3} xs={12}>
            <FormControl fullWidth>
              <TextField
                value={searchLeague}
                label={t("league-name")}
                margin="normal"
                variant="outlined"
                color="secondary"
                onChange={(event) => setSearchLeague(event.target.value)}
              />
            </FormControl>
          </Grid>
          <Grid item md={3} xs={12}>
            <FormControl fullWidth>
              <Autocomplete
                sx={{mb: 1}}
                id="combo-box-demo"
                options={countries}
                getOptionLabel={(option) => option.label}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={selectedCountry ? t("country") : t("select-country")}
                  />
                )}
                value={
                  countries.find(
                    (option) => option.value === selectedCountry
                  ) || null
                }
                onChange={(event, newValue) => {
                  if (newValue && newValue.value) {
                    setSelectedCountry(newValue.value);
                  } else {
                    setSelectedCountry(null);
                  }
                }}
              />
            </FormControl>
          </Grid>
          <Grid item md={2} xs={12}>
            <FormControl fullWidth>
              <InputLabel id="status-select-label">Status</InputLabel>
              <Select
                labelId="status-select-label"
                label="Status"
                value={searchStatus}
                onChange={(e) => setSearchStatus(e.target.value)}
              >
                <MenuItem value={null}>Any</MenuItem>
                <MenuItem value={true}>Enabled</MenuItem>
                <MenuItem value={false}>Disabled</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item md={1} xs={12}>
            <SearchButton onClick={() => fetchLeagues()} />
          </Grid>

          <Grid item md={3}>
            <LoadingButton
              type="submit"
              loading={submitting}
              disabled={updatedLeagues.length === 0}
              sx={{
                bgcolor:
                  updatedLeagues.length > 0
                    ? theme.palette.success.dark
                    : theme.palette.secondary.main,
                "&:hover": {
                  backgroundColor: theme.palette.success.main_light,
                },
                ml:"5rem"
              }}
              variant="contained"
              size="large"
              onClick={() => handleSave()}
              startIcon={<SaveIcon sx={{ height: "1.2rem", mr: -0.3 }} />}
            >
              {t("save-all")}
            </LoadingButton>
          </Grid>
        </Grid>
      </TableFilterContainer>

      <BaseTable
        head={TABLE_HEAD}
        data={filteredData}
        pagination={{
          paginationData: { ...paginationData, totalCount: totalCount },
          setPaginationData: setPaginationData,
        }}
        loadingData={loadingData}
        smallRow={true}
      />
    </>
  );
}
