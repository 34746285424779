import api from "../Api";
import urls from "../Urls";

//PARTITION USERS

export const listPartitionUsers = (params) => {
    return api.get(urls.list_partitions_users, { params: params });
};

export const getPartitionUsers = (id) => {
    return api.get(urls.get_partition_users(id))
};

export const createPartitionUsers = (payload) => {
    return api.post(urls.create_partition_users, payload)
}

export const updatePartitionUsers = (id, payload,  params ) => {
    params = params ? params : {proceed : false};
    return api.put(urls.update_partition_users(id), payload, {params})
}

export const updatePartitionUsersPassword = (id, payload,  params ) => {
    params = params ? params : {proceed : false};
    return api.put(urls.update_partition_users_password(id), payload, {params})
}

export const deletePartitionUsers = (id) => {
    return api.delete(urls.delete_partition_users(id));
};

//DISTRIBUTOR USERS

export const listDistributorUsers = (params) => {
    return api.get(urls.list_distributor_users, { params: params });
};

export const getDistributorUsers = (id) => {
    return api.get(urls.get_distributor_users(id))
};

export const createDistributorUsers = (payload) => {
    return api.post(urls.create_distributor_users, payload)
}

export const updateDistributorUsers = (id, payload,  params ) => {
    params = params ? params : {proceed : false};
    return api.put(urls.update_distributor_users(id), payload, {params})
}

export const updateDistributorUsersPassword = (id, payload,  params ) => {
    params = params ? params : {proceed : false};
    return api.put(urls.update_distributor_users_password(id), payload, {params})
}

export const deleteDistributorUsers = (id) => {
    return api.delete(urls.delete_distributor_users(id));
};

//STORE USERS

export const listStoreUsers = (params) => {
    return api.get(urls.list_store_users, { params: params });
};

export const getStoreUsers = (id) => {
    return api.get(urls.get_store_users(id))
};

export const createStoreUsers = (payload) => {
    return api.post(urls.create_store_users, payload)
}

export const updateStoreUsers = (id, payload,  params ) => {
    params = params ? params : {proceed : false};
    return api.put(urls.update_store_users(id), payload, {params})
}

export const updateStoreUsersPassword = (id, payload,  params ) => {
    params = params ? params : {proceed : false};
    return api.put(urls.update_store_users_password(id), payload, {params})
}

export const deleteStoreUsers = (id) => {
    return api.delete(urls.delete_store_users(id));
};

//PARTITION-DISTRIBUTOR USERS

export const listPaDistributorUsers = (params) => {
    return api.get(urls.list_pa_distributor_users, { params: params });
};

export const getPaDistributorUsers = (id) => {
    return api.get(urls.get_pa_distributor_users(id))
};

export const createPaDistributorUsers = (id,payload) => {
    return api.post(urls.create_pa_distributor_users(id), payload)
}

export const updatePaDistributorUsers = (id, payload,  params ) => {
    params = params ? params : {proceed : false};
    return api.put(urls.update_pa_distributor_users(id), payload, {params})
}

export const updatePaDistributorUsersPassword = (id, payload,  params ) => {
    params = params ? params : {proceed : false};
    return api.put(urls.update_pa_distributor_users_password(id), payload, {params})
}

export const deletePaDistributorUsers = (id) => {
    return api.delete(urls.delete_pa_distributor_users(id));
};

//DISTRIBUTOR-STORE USERS

export const listDtStoreUsers = (params) => {
    return api.get(urls.list_dt_store_users, { params: params });
};

export const getDtStoreUsers = (id) => {
    return api.get(urls.get_dt_store_users(id))
};

export const createDtStoreUsers = (store_id, payload) => {
    return api.post(urls.create_dt_store_users(store_id), payload)
}

export const updateDtStoreUsers = (id, payload,  params ) => {
    params = params ? params : {proceed : false};
    return api.put(urls.update_dt_store_users(id), payload, {params})
}

export const updateDtStoreUsersPassword = (id, payload,  params ) => {
    params = params ? params : {proceed : false};
    return api.put(urls.update_dt_store_users_password(id), payload, {params})
}

export const deleteDtStoreUsers = (id) => {
    return api.delete(urls.delete_dt_store_users(id));
};

//DISTRIBUTOR ONLINE USERS

export const listOnlineUsers = (params) => {
    return api.get(urls.list_online_users, { params: params });
};

export const getOnlineUsers = (id) => {
    return api.get(urls.get_online_users(id))
};

export const createOnlineUser = (id,payload) => {
    return api.post(urls.create_online_users(id), payload)
}

export const updateOnlineUser = (id, payload,  params ) => {
    params = params ? params : {proceed : false};
    return api.put(urls.update_online_users(id), payload, {params})
}

export const updateOnlineUserPassword = (id, payload,  params ) => {
    params = params ? params : {proceed : false};
    return api.put(urls.update_online_users_password(id), payload, {params})
}

export const deleteOnlineUser = (id) => {
    return api.delete(urls.delete_online_users(id));
};
