import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Tooltip, IconButton, Typography, Box, Button } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useNavigate, useLocation } from "react-router-dom";
import { DefaultPaginationData } from "../../../constants";
import { rowArrayToObject } from "../../../utils/Util";
import BaseModal from "../../../components/BaseModal";
import BaseSnackbar from "../../../components/BaseSnackbar";
import { BaseTable, TableFilterContainer } from "../../../components/table";
import { DistributorsService } from "../../../api/services";
import SearchBar from "../../../components/SearchBar";
import AddNewButton from "../../../components/buttons/AddNewButton";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import EditDistributor from "./editDistributor/editDistributor";

export default function ListDistributors() {
  const { t } = useTranslation();
  const theme = useTheme();
  const location = useLocation();
  const { successMessage } = location.state || {};
  const [openSnackbar, setSnackbarStatus] = useState(false);
  const [message, setMessage] = useState("");
  const [data, setData] = useState([]);
  const [paginationData, setPaginationData] = useState(DefaultPaginationData);
  const [loadingData, setLoadingData] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedRow, setSelectedRow] = useState({});
  const [openEditModal, setEditModalStatus] = useState(false);
  const [openDeleteModal, setDeleteModalStatus] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    if (successMessage) {
      setSnackbarStatus(true);
      setMessage(successMessage);
    }
  }, [successMessage]);

  const TABLE_HEAD = [
    { key: "id", label: t("distributor-id") },
    { key: "name", label: t("distributor-name") },
    { key: "action", label: t("actions"), align: "right" },
  ];

  const TABLE_FIELD_MAPPING = {
    id: { key: "id", cellComponentType: "th", index: 0 },
    name: { key: "name", index: 1 },
  };

  const fetchDistributors = () => {
    setLoadingData(true);
    const params = {
      page: paginationData.page + 1,
      size: paginationData.rowsPerPage,
    };

 DistributorsService.listDistributors(params)
      .then((response) => {
        const items = response.data.items.map((itemData) => {
          const item = new Array(TABLE_HEAD.length - 1).fill({});
          item[TABLE_FIELD_MAPPING["id"].index] = {
            ...TABLE_FIELD_MAPPING["id"],
            value: itemData.id,
          };
          item[TABLE_FIELD_MAPPING["name"].index] = {
            ...TABLE_FIELD_MAPPING["name"],
            value: itemData.name,
          };
          return item;
        });
        setData(items);
        setTotalCount(response.data.total);
      })
      .catch((err) => {
        setMessage(t("failed-to-get-distributors"));
        setSnackbarStatus(true);
        console.error(err);
      })
      .finally(() => {
        setLoadingData(false);
      });
  };

  useEffect(() => {
    fetchDistributors();
  }, [paginationData]);

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const filterData = (data, query) => {
    if (!query) return data;
    return data.filter((row) => {
      return ["name"].some((key) => {
        const cellValue = row[TABLE_FIELD_MAPPING[key]?.index]?.value || "";
        return cellValue.toString().toLowerCase().includes(query.toLowerCase());
      });
    });
  };

  const filteredData = filterData(data, searchQuery);


  const modalHandler = (modalType, index = undefined) => {
    switch (modalType) {
      case "edit":
        setEditModalStatus(!openEditModal);
        break;
      case "delete":
        setDeleteModalStatus(!openDeleteModal);
        break;
      default:
        break;
    }
  
    if (index !== undefined) {
      const row = rowArrayToObject(data[index]);
      setSelectedRow(row);
    }
  };

  const handleDeleteDistributor = () => {
    if (selectedRow) {
      const distributorId = selectedRow.id || selectedRow[TABLE_FIELD_MAPPING["id"].index]?.value;
      if (distributorId) {
        DistributorsService.deleteDistributor(distributorId)
          .then(() => {
            setMessage(t("distributor-deleted-successfully"));
            setSnackbarStatus(true);
            fetchDistributors();
            setDeleteModalStatus(false);
          })
          .catch((err) => {
            if (err.response && err.response.status === 400) {
              const errorMessage = err.response.data?.detail || t("distributor-delete-failed-users-exist");
              setMessage(errorMessage);
            } else {
              setMessage(t("failed-to-delete-distributor"));
            }
            setSnackbarStatus(true);
            console.error(err);
          });
      } else {
        console.error("Distributor ID is undefined");
      }
    } else {
      console.error("Selected row is undefined");
    }
  };

  const getActionItems = (index) => (
    <Box sx={{ minWidth: "80px" }}>
      <Tooltip title={t("edit-distributor")}>
        <IconButton
          sx={{
            color: theme.palette.neutral,
          }}
          size="small"
          aria-label="edit-distributor"
          onClick={() => modalHandler("edit", index)}
        >
          <EditIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title={t("delete-distributor")}>
        <IconButton
          color="secondary"
          size="small"
          aria-label="delete-distributor"
          onClick={() => modalHandler("delete", index)}
        >
          <DeleteIcon />
        </IconButton>
      </Tooltip>
    </Box>
  );

  return (
    <>
      <BaseSnackbar
        open={openSnackbar}
        message={message}
        setOpen={setSnackbarStatus}
      />
       <BaseModal
        title={t("edit-distributor") + " - " + selectedRow.name}
        sx={{
          width: {xs:"80%", md:"30%"},
          height: "auto",
          overflow: "none",
        }}
        open={openEditModal}
        setOpen={setEditModalStatus}
        children={
          <EditDistributor
            successCallback={fetchDistributors}
            formData={selectedRow}
            setModalStatus={setEditModalStatus}
            setSnackbarStatus={setSnackbarStatus}
            setMessage={setMessage}
          />
        }
      />
      <BaseModal
        title={t("delete-distributor")}
        open={openDeleteModal}
        setOpen={setDeleteModalStatus}
        sx={{width: {xs:"80%", md:"20%"}}}
        children={
          <Box>
            <Typography>{t("delete-this-item")}</Typography>
            <Box
              sx={{ marginTop: 2, display: "flex", justifyContent: "flex-end" }}
            >
              <Button
                onClick={() => setDeleteModalStatus(false)}
                sx={{ marginRight: 2 }}
              >
                {t("cancel")}
              </Button>
              <Button color="secondary" onClick={handleDeleteDistributor}>
                {t("confirm")}
              </Button>
            </Box>
          </Box>
        }
      />
      <TableFilterContainer
        sx={{
          marginTop: "2vh",
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          alignItems: "center",
          justifyContent: "space-between",
          px: 2,
        }}
      >
        <SearchBar
          searchQuery={searchQuery}
          handleSearchChange={handleSearchChange}
        />
        <AddNewButton
          label={t("add-new-distributor")}
          onClick={() => navigate("/distributor/add-new-distributor")}
        />
      </TableFilterContainer>
      <BaseTable
        head={TABLE_HEAD}
        data={filteredData}
        actionItemPrep={getActionItems}
        pagination={{
          paginationData: { ...paginationData, totalCount: totalCount },
          setPaginationData: setPaginationData,
        }}
        loadingData={loadingData}
      />
    </>
  );
}
