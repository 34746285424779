import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Tooltip, IconButton, Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { DefaultPaginationData } from "../../../constants";
import { useNavigate } from "react-router-dom";
import BaseSnackbar from "../../../components/BaseSnackbar";
import { rowArrayToObject } from "../../../utils/Util";
import BaseModal from "../../../components/BaseModal";
import { BaseTable, TableFilterContainer } from "../../../components/table";
import { UsersService } from "../../../api/services";
import SearchBar from "../../../components/SearchBar";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import LockResetIcon from '@mui/icons-material/LockReset';
import AddNewButton from "../../../components/buttons/AddNewButton";
import AddOnlineUser from "./form/addOnlineUser";
import EditOnlineUser from "./form/editOnlineUser";
import DeleteOnlineUser from "./form/deleteOnlineUser";
import ChangeOnlineUserPassword from "./form/changeOnlineUserPassword";

export default function ListOnlineUsers() {
  const { t } = useTranslation();
  const theme = useTheme();
  const [data, setData] = useState([]);
  const [paginationData, setPaginationData] = useState(DefaultPaginationData);
  const [loadingData, setLoadingData] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [openSnackbar, setSnackbarStatus] = useState(false);
  const [message, setMessage] = useState("");
  const [totalCount, setTotalCount] = useState(0);
  const [selectedRow, setSelectedRow] = useState({});
  const [openAddModal, setAddModalStatus] = useState(false);
  const [openEditModal, setEditModalStatus] = useState(false);
  const [openDeleteModal, setDeleteModalStatus] = useState(false);
  const [openPasswordModal, setPasswordModalStatus] = useState(false);
  const navigate = useNavigate();

  const TABLE_HEAD = [
    {key:"id", label:t("id")},
    { key: "user_name", label: t("username") },
    { key: "action", label: t("actions"), align: "right" },
  ];

  const TABLE_FIELD_MAPPING = {
    id: {key:"id", index: 0},
    user_name: { key: "user_name", index: 1 },
  };

  const fetchUsers = () => {
    setLoadingData(true);
    const params = {
      page: paginationData.page + 1,
      size: paginationData.rowsPerPage,
    };

    UsersService.listOnlineUsers(params) 
      .then((response) => {
        const items = response.data.items.map((itemData) => {
          const item = new Array(TABLE_HEAD.length - 1).fill({});
          Object.keys(TABLE_FIELD_MAPPING).forEach((key) => {
            const value = itemData[key];
            item[TABLE_FIELD_MAPPING[key].index] = {
              ...TABLE_FIELD_MAPPING[key],
              value: value || "",
            };
          });
          return item;
        });
        setData(items);
        setTotalCount(response.data.total);
      })
      .catch((err) => {
        setMessage(t("Failed to fetch users"));
        setSnackbarStatus(true);
        console.error(err);
      })
      .finally(() => setLoadingData(false));
  };

  useEffect(() => {
    fetchUsers();
  }, [paginationData]);

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const filterData = (data, query) => {
    if (!query) return data;
    return data.filter((row) =>
      ["user_name"].some((key) => {
        const cellValue = row[TABLE_FIELD_MAPPING[key]?.index]?.value || "";
        return cellValue.toString().toLowerCase().includes(query.toLowerCase());
      })
    );
  };

  const filteredData = filterData(data, searchQuery);
  
  const modalHandler = (modalType, index = undefined) => {
    switch (modalType) {
      case "add":
        setAddModalStatus(!openAddModal);
        break;
      case "edit":
        setEditModalStatus(!openEditModal);
        break;
      case "delete":
        setDeleteModalStatus(!openDeleteModal);
        break;
      case "change-password":
        setPasswordModalStatus(!openPasswordModal);
        break;
      default:
        break;
    }
  
    if (index !== undefined && data[index]) {
      const row = rowArrayToObject(data[index]);
      setSelectedRow(row);
    }
  };

  const getActionItems = (index) => (
    <Box sx={{ minWidth: "80px" }}>
      <Tooltip title={t("edit-user")}>
        <IconButton
          sx={{
            color: theme.palette.neutral,
          }}
          size="small"
          aria-label="edit-user"
          onClick={() => modalHandler("edit", index)}
        >
          <EditIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title={t("change-password")}>
        <IconButton 
        sx={{
            color: theme.palette.neutral,
          }} 
          size="small" aria-label="change-password" onClick={() => modalHandler("change-password", index)}>
          <LockResetIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title={t("delete-user")}>
        <IconButton color="secondary" size="small" aria-label="delete-user" onClick={() => modalHandler("delete", index)}>
          <DeleteIcon />
        </IconButton>
      </Tooltip>
    </Box>
  );

  return (
    <>
      <BaseSnackbar
        open={openSnackbar}
        message={message}
        setOpen={setSnackbarStatus}
      />
      <BaseModal
        title={t("add-user")}
        open={openAddModal}
        setOpen={setAddModalStatus}
        sx={{width: {xs:"80%", md:"20%"}}}
        children={
          <AddOnlineUser
            formType="add"
            formData={{}}
            successCallback={fetchUsers}
            setModalStatus={setAddModalStatus}
            setSnackbarStatus={setSnackbarStatus}
            setMessage={setMessage}
          />
        }
      />
      <BaseModal
        title={t("edit-user")}
        open={openEditModal}
        setOpen={setEditModalStatus}
        sx={{width: {xs:"80%", md:"20%"}}}
        children={
          <EditOnlineUser
            formType="edit"
            formData={selectedRow}
            successCallback={fetchUsers}
            setModalStatus={setEditModalStatus}
            setSnackbarStatus={setSnackbarStatus}
            setMessage={setMessage}
          />
        }
      />
      <BaseModal
        title={t("change-password")}
        open={openPasswordModal}
        setOpen={setPasswordModalStatus}
        sx={{width: {xs:"80%", md:"20%"}}}
        children={
          <ChangeOnlineUserPassword
            formType="password"
            formData={selectedRow}
            successCallback={fetchUsers}
            setModalStatus={setPasswordModalStatus}
            setSnackbarStatus={setSnackbarStatus}
            setMessage={setMessage}
          />
        }
      />
      <BaseModal
        title={t("delete-user")}
        open={openDeleteModal}
        setOpen={setDeleteModalStatus}
        sx={{width: {xs:"80%", md:"20%"}}}
        children={
          <DeleteOnlineUser
            successCallback={fetchUsers}
            formData={selectedRow}
            setModalStatus={setDeleteModalStatus}
            setSnackbarStatus={setSnackbarStatus}
            setMessage={setMessage}
          />
        }
      />
      <TableFilterContainer
        sx={{
          marginTop: "2vh",
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          alignItems: "center",
          justifyContent: "space-between",
          px: 2,
        }}
      >
        <SearchBar
          searchQuery={searchQuery}
          handleSearchChange={handleSearchChange}
        />
        <AddNewButton
          label={t("add-new-user")}
          onClick={() => modalHandler("add")}
        />
      </TableFilterContainer>
      <BaseTable
        head={TABLE_HEAD}
        data={filteredData}
        actionItemPrep={getActionItems}
        pagination={{
          paginationData: { ...paginationData, totalCount: totalCount },
          setPaginationData: setPaginationData,
        }}
        loadingData={loadingData}
      />
    </>
  );
}
