import React, { useEffect, useState, useCallback } from "react";
import { OnlineOddsService } from "../../../api/services";
import { couponStatusMap, couponStatusColors } from "../../../constants";
import {
  Box,
  Typography,
  Grid,
  Card,
  CardContent,
  CardActions,
  Tabs,
  Tab,
  CircularProgress,
  Pagination,
  Stack,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { styled, useTheme } from "@mui/material/styles";
import { useStore } from "../../../store/Store";
import { convertUTCToLocalTime, formatDateTime } from "../../../utils/Util";

const TabPanel = ({ children, value, index, ...other }) => (
  <div
    role="tabpanel"
    hidden={value !== index}
    id={`tabpanel-${index}`}
    {...other}
  >
    {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
  </div>
);

export default function MyBets() {
  const [coupons, setCoupons] = useState([]);
  const [loading, setLoading] = useState(true);
  const [activeTab, setActiveTab] = useState(0);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [pageSize] = useState(12);
  const theme = useTheme();
  const [store] = useStore();
  const { t } = useTranslation();

  const tabConfig = {
    0: {
      label: t("pending"),
      status: 1,
    },
    1: {
      label: t("open"),
      status: 2,
    },
    2: {
      label: t("closed"),
      status: 0,
    },
  };

  const fetchCoupons = useCallback(async () => {
    try {
      setLoading(true);
      setCoupons([]);

      let status = tabConfig[activeTab].status;

      if (activeTab === 1) {
        const [response1, response2] = await Promise.all([
          OnlineOddsService.listOnlineCoupons({
            coupon_status: 2,
            page,
            size: pageSize,
          }),
          OnlineOddsService.listOnlineCoupons({
            coupon_status: 3,
            page,
            size: pageSize,
          }),
        ]);

        const combinedCoupons = [
          ...(response1.data.items || []),
          ...(response2.data.items || []),
        ];
        setCoupons(combinedCoupons);

        const totalItems =
          (response1.data.total || 0) + (response2.data.total || 0);
        setTotalPages(Math.ceil(totalItems / pageSize));
      } else if (activeTab === 2) {
        const responses = await Promise.all([
          OnlineOddsService.listOnlineCoupons({
            coupon_status: 0,
            page,
            size: pageSize,
          }),
          OnlineOddsService.listOnlineCoupons({
            coupon_status: 4,
            page,
            size: pageSize,
          }),
          OnlineOddsService.listOnlineCoupons({
            coupon_status: 5,
            page,
            size: pageSize,
          }),
          OnlineOddsService.listOnlineCoupons({
            coupon_status: 6,
            page,
            size: pageSize,
          }),
        ]);

        const combinedCoupons = responses.reduce((acc, response) => {
          return [...acc, ...(response.data.items || [])];
        }, []);
        setCoupons(combinedCoupons);

        const totalItems = responses.reduce((total, response) => {
          return total + (response.data.total || 0);
        }, 0);
        setTotalPages(Math.ceil(totalItems / pageSize));
      } else {
        const response = await OnlineOddsService.listOnlineCoupons({
          coupon_status: status,
          page,
          size: pageSize,
        });
        setCoupons(response.data.items || []);
        setTotalPages(Math.ceil((response.data.total || 0) / pageSize));
      }
    } catch (err) {
      console.error("Failed to fetch coupons:", err);
    } finally {
      setLoading(false);
    }
  }, [activeTab, page, pageSize]);

  useEffect(() => {
    fetchCoupons();
  }, [fetchCoupons]);

  useEffect(() => {
    setPage(1);
  }, [activeTab]);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  if (loading) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", p: 3 }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={activeTab}
          onChange={handleTabChange}
          variant="fullWidth"
          sx={{
            "& .MuiTab-root": {
              textTransform: "none",
              fontWeight: "bold",
            },
            "& .Mui-selected": {
              color: theme.palette.error.darkest,
            },
            "& .MuiTabs-indicator": {
              backgroundColor: theme.palette.error.darkest,
            },
          }}
        >
          {Object.values(tabConfig).map((tab, index) => (
            <Tab key={index} label={tab.label} />
          ))}
        </Tabs>
      </Box>

      {Object.keys(tabConfig).map((tabIndex) => (
        <TabPanel key={tabIndex} value={activeTab} index={Number(tabIndex)}>
          {!coupons || coupons.length === 0 ? (
            <Typography sx={{ textAlign: "center", p: 2 }}>
              {t("no-tickets-found")}
            </Typography>
          ) : (
            <>
              <Grid container spacing={2}>
                {coupons.map((coupon) => {
                  const firstBet = coupon.placed_bets[0];
                  const status = coupon.coupon_status;
                  const borderColor = couponStatusColors[status] || "#ccc";
                  const isSingleType = coupon.coupon_type === 1;

                  return (
                    <Grid key={coupon.id} item xs={12} sm={6} md={4} lg={3}>
                      <Card
                        sx={{
                          border: `2px solid ${borderColor}`,
                          borderRadius: "8px",
                          boxShadow: 2,
                          height: "450px",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <CardContent
                          sx={{
                            flexGrow: 1,
                            p: 0,
                            "&:last-child": { pb: 0 },
                          }}
                        >
                          <Box sx={{ p: 2 }}>
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                mb: 1,
                              }}
                            >
                              <Typography variant="h6" sx={{ fontWeight: 700 }}>
                                {t("coupon")} #{coupon.id}
                              </Typography>
                              <Typography
                                variant="body2"
                                sx={{
                                  bgcolor: theme.palette.grey[200],
                                  px: 1,
                                  py: 0.5,
                                  borderRadius: 1,
                                  fontWeight: 500,
                                }}
                              >
                                {coupon.coupon_type === 1
                                  ? t("single")
                                  : t("combine")}
                              </Typography>
                            </Box>
                            <Typography variant="body2" color="GrayText">
                              {t("created")}:{" "}
                              {formatDateTime(coupon.created_at, store.language)}
                            </Typography>
                          </Box>

                          <Box
                            sx={{
                              height: "300px",
                              overflowY: "auto",
                              borderTop: "1px solid #eee",
                              borderBottom: "1px solid #eee",
                              "&::-webkit-scrollbar": {
                                width: "8px",
                              },
                              "&::-webkit-scrollbar-track": {
                                background: theme.palette.error.light,
                              },
                              "&::-webkit-scrollbar-thumb": {
                                background: theme.palette.error.light,
                                borderRadius: "4px",
                              },
                              "&::-webkit-scrollbar-thumb:hover": {
                                background: theme.palette.error.light,
                              },
                            }}
                          >
                            <Box sx={{ p: 2 }}>
                              {coupon.placed_bets.map((bet, i) => (
                                <Box
                                  key={i}
                                  sx={{
                                    borderTop:
                                      i > 0 ? "1px solid #e0e0e0" : "none",
                                    paddingTop: i > 0 ? "0.5rem" : "0",
                                    marginTop: i > 0 ? "0.5rem" : "0",
                                  }}
                                >
                                  <Typography
                                    variant="body1"
                                    sx={{
                                      fontWeight: 600,
                                      marginBottom: "0.2rem",
                                    }}
                                  >
                                    {bet.fixture?.home_team_name} vs.{" "}
                                    {bet.fixture?.away_team_name}
                                  </Typography>
                                  <Box
                                    sx={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      alignItems: "center",
                                      mb: 0.5,
                                    }}
                                  >
                                    <Box>
                                      <Typography
                                        variant="body2"
                                        color="GrayText"
                                      >
                                        {bet.odd?.value} - {bet.odd?.odd}
                                      </Typography>
                                      {isSingleType && (
                                        <Typography
                                          variant="body2"
                                          sx={{
                                            color: theme.palette.success.main,
                                            fontWeight: 500,
                                            mt: 0.5,
                                          }}
                                        >
                                          {t("amount")}:{" "}
                                          {bet.bet_amount?.toFixed(2)} ₺
                                        </Typography>
                                      )}
                                    </Box>
                                    {isSingleType && (
                                      <Typography
                                        variant="body2"
                                        sx={{
                                          color: theme.palette.success.main,
                                          fontWeight: 500,
                                        }}
                                      >
                                        {t("possible-payout")}:{" "}
                                        {(
                                          parseFloat(bet.bet_amount) *
                                          parseFloat(bet.odd?.odd)
                                        ).toFixed(2)}{" "}
                                        ₺
                                      </Typography>
                                    )}
                                  </Box>
                                  <Typography
                                    variant="body2"
                                    sx={{
                                      color:
                                        couponStatusColors[
                                          coupon.coupon_status
                                        ],
                                    }}
                                  >
                                    {couponStatusMap[coupon.coupon_status]}
                                  </Typography>
                                </Box>
                              ))}
                            </Box>
                          </Box>

                          <CardActions
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "space-between",
                              p: 2,
                              bgcolor: theme.palette.grey[50],
                              borderTop: "1px solid #f0f0f0",
                              position: "sticky",
                              bottom: 0,
                              zIndex: 1,
                            }}
                          >
                            <Box>
                              <Typography
                                variant="body1"
                                sx={{ fontWeight: 500 }}
                              >
                                {t("total-amount")}:
                              </Typography>
                              <Typography
                                variant="body1"
                                color="text.secondary"
                              >
                                {isSingleType
                                  ? coupon.placed_bets
                                      .reduce(
                                        (total, bet) =>
                                          total +
                                          parseFloat(bet.bet_amount || 0),
                                        0
                                      )
                                      .toFixed(2)
                                  : firstBet?.bet_amount?.toFixed(2)}{" "}
                                ₺
                              </Typography>
                            </Box>
                            <Box sx={{ textAlign: "right" }}>
                              <Typography
                                variant="body1"
                                sx={{ fontWeight: 500 }}
                              >
                                {t("total-possible-payout")}:
                              </Typography>
                              <Typography
                                variant="body1"
                                color="text.secondary"
                              >
                                {isSingleType
                                  ? coupon.placed_bets
                                      .reduce(
                                        (total, bet) =>
                                          total +
                                          parseFloat(bet.bet_amount || 0) *
                                            parseFloat(bet.odd?.odd || 0),
                                        0
                                      )
                                      .toFixed(2)
                                  : coupon.total_payout?.toFixed(2)}{" "}
                                ₺
                              </Typography>
                            </Box>
                          </CardActions>
                        </CardContent>
                      </Card>
                    </Grid>
                  );
                })}
              </Grid>

              {totalPages > 1 && (
                <Stack spacing={2} sx={{ mt: 4, alignItems: "center" }}>
                  <Pagination
                    count={totalPages}
                    page={page}
                    onChange={handlePageChange}
                    color="primary"
                    size="large"
                    sx={{
                      "& .MuiPaginationItem-root": {
                        color: theme.palette.grey[700],
                      },
                      "& .Mui-selected": {
                        backgroundColor: theme.palette.error.darkest,
                        color: theme.palette.common.white,
                        "&:hover": {
                          backgroundColor: theme.palette.error.main,
                        },
                      },
                    }}
                  />
                </Stack>
              )}
            </>
          )}
        </TabPanel>
      ))}
    </Box>
  );
}
