import React, { useEffect, useState } from "react";
import "./App.css";
import Store from "./store/Store";
import ThemeConfig from "./theme";
import api from "./api/Api";
import PlatformRouter from "./PlatformRouter";
import { ErrorBoundary } from "react-error-boundary";
import { Service, SiteConfigService } from "./api/services";
import { useLocation } from "react-router-dom";
import DistributorRouter from "./DistributorRouter";
import StoreRouter from "./StoreRouter";
import PartitionRouter from "./PartitionRouter";
import KioskRouter from "./KioskRouter";
import OnlineStoreRouter from "./OnlineStoreRouter";
import Page404 from "./pages/Page404";
//import { setFavIcon } from "./utils/Util";

function ErrorFallback({ error }) {
  window.location.href = "/oops";
  console.error(error);
}

const getApiUrl = () => {
  var request = new XMLHttpRequest();
  request.open("GET", document.location.origin, false);
  request.send();
  if (request.readyState === 4) {
    if (
      request.getResponseHeader("X-Api-Address") === null ||
      request.getResponseHeader("X-Api-Address") === undefined
    ) {
      let isLocal =
        document.location.origin.includes("127.0.0.1") ||
        document.location.origin.includes("localhost");
      return isLocal ? "http://127.0.0.1:8000/api" : document.location.origin;
    } else {
      return request.getResponseHeader("X-Api-Address");
    }
  }
};

const baseUrl = getApiUrl();
api.defaults.baseURL = baseUrl;

export default function App() {

  const [stateData, setStateData] = useState();
  const [router, setRouter] = useState([]);
  const [show404, setShow404] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const fetchStyle = async () => {
      try {
        const path = location.pathname;
        let hash_code = null;
  
        if (path.includes("/on")) {
          const match = path.match(/^\/dt\/(\d{6})\/on/);
          hash_code = match ? match[1] : null;
        }

        const serviceMapping = {
          "/on": () => hash_code ? SiteConfigService.getOnlineStyle(hash_code) : null,
          "/dt": () => localStorage.getItem("distributor") ? SiteConfigService.getDistributorStyle() : SiteConfigService.getStyle(),
          "/st": () => localStorage.getItem("store") ? SiteConfigService.getStoreStyle() : SiteConfigService.getStyle(),
          "/ki": () => localStorage.getItem("kiosk") ? SiteConfigService.getKioskStyle() : SiteConfigService.getStyle(),
          "default": () => SiteConfigService.getStyle()
        };
        
        if (path.includes('/on') && !path.includes('/online')){
          localStorage.setItem("userType", "onlineUser")
        } else if (path.includes('/dt')){
          localStorage.setItem("userType", "distributor")
        }else if (path.includes('/st')){
          localStorage.setItem("userType", "store")
        }else if (path.includes('/ki')){
          localStorage.setItem("userType", "kiosk")
        }

        const styleApiService = Object.keys(serviceMapping).find(key => path.includes(key)) || "default";
        const styleResponse = await serviceMapping[styleApiService]();
        
        if (styleResponse.status === 200 && hash_code){
          if(hash_code !== localStorage.getItem('hashCode')){
            localStorage.setItem("hashCode", hash_code)
          }
        }else{
          /* localStorage.removeItem("hashCode") */
        }
        if (styleResponse?.status !== 200) {
          setShow404(true);
          return;
        }
  
        const { style, logo, landing_image, supported_languages, online_layout, kiosk_layout, session_type } = styleResponse.data;
        const initialStateData = {
          selectedPalette: style.selectedPalette,
          palettes: style.palettes,
          logo,
          landingImage: landing_image ?? logo,
          supported_languages,
          online_layout,
          kiosk_layout,
          styleUpdatedOnState: true,
        };
  
        const routeMapping = {
          "/on": { router: <OnlineStoreRouter hashCode={hash_code} />, userType: "onlineUser" },
          "/dt": { router: <DistributorRouter />, userType: "distributor" },
          "/st": { router: <StoreRouter />, userType: "store" },
          "/ki": { router: <KioskRouter />, userType: "kiosk" },
          "default": session_type === "platform"
            ? { router: <PlatformRouter />, userType: "platform" }
            : { router: <PartitionRouter />, userType: "partition" }
        };
  
        const selectedRoute = Object.keys(routeMapping).find(key => path.includes(key)) || "default";
  
        setRouter(routeMapping[selectedRoute].router);
        setStateData({ ...initialStateData, userType: routeMapping[selectedRoute].userType });
      } catch (err) {
        console.error(err);
        setShow404(true);
      }
    };
  
    fetchStyle();
  }, [location.pathname]);
  

  return (
    <Store initialStateData={stateData}>
      <ThemeConfig>
        <ErrorBoundary
          FallbackComponent={ErrorFallback}
          onReset={() => setStateData(null)}
        >
          {show404 ? <Page404 /> : router}
        </ErrorBoundary>
      </ThemeConfig>
    </Store>
  );
}
