import { Box, Radio, FormControlLabel, RadioGroup } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useStore } from "../../../store/Store";
import { SiteConfigService } from "../../../api/services";
import BaseSnackbar from "../../../components/BaseSnackbar";

export default function KioskLayout({ selectedTab }) {
  const [store, setStore] = useStore();
  const [selectedLayout, setSelectedLayout] = useState(
    store.services.style.kiosk_layout || "1"
  );
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [message, setMessage] = useState(null);

  const handleLayoutChange = (event) => {
    const newValue = event.target.value;
    setSelectedLayout(newValue);
    setStore((prev) => ({
      ...prev,
      services: {
        ...prev.services,
        style: {
          ...prev.services.style,
          kiosk_layout: newValue,
        },
      },
    }));
  };

  const layouts = [
    { id: "1", name: "Default", image: "default.png" },
    { id: "2", name: "Tipoo", image: "tipo.png" },
  ];

  useEffect(() => {
    let payload = {
      environment: "Kiosk",
      layout: selectedLayout,
    };
    if (selectedLayout !== store.services.style.kiosk_layout) {
      SiteConfigService.setLayout(payload)
        .then((response) => {
          if (response.status === 200) {
            setMessage("success");
            setOpenSnackbar(true);
          } else {
            throw Error("failed");
          }
        })
        .catch((err) => {
          setMessage("fail");
          setOpenSnackbar(true);
        });
    }
  }, [selectedLayout]);

  return (
    <>
      <BaseSnackbar
        open={openSnackbar}
        message={message}
        setOpen={setOpenSnackbar}
      />

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          mr: -5,
        }}
      >
        <RadioGroup
          value={selectedLayout}
          onChange={handleLayoutChange}
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            alignItems: "center",
            justifyContent: "center",
            gap: { xs: "1rem", md: "3rem" },
          }}
        >
          {layouts.map((layout) => (
            <Box
              key={layout.id}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: "1rem",
                flex: { md: 1 },
              }}
            >
              <FormControlLabel
                value={layout.id}
                control={<Radio />}
                label={layout.name}
                sx={{
                  '& .MuiTypography-root': {
                    fontSize: { xs: '1rem', md: '1.2rem' }
                  }
                }}
              />
              <LayoutOption
                imagePath={`${process.env.PUBLIC_URL}/assets/${layout.image}`}
                isChecked={selectedLayout === layout.id}
              />
            </Box>
          ))}
        </RadioGroup>
      </Box>
    </>
  );
}

const LayoutOption = ({ imagePath, isChecked }) => (
  <Box
    sx={{
      flex: 1,
      minWidth: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      backgroundColor: "#ffffff",
      borderRadius: "12px",
      boxShadow: isChecked
        ? "0 0 0 2px #1976d2, 0 2px 8px rgba(0,0,0,0.1)"
        : "0 2px 8px rgba(0,0,0,0.1)",
      padding: { xs: "0.75rem", md: "1.5rem" },
      transition: "all 0.2s ease-in-out",
      opacity: isChecked ? 1 : 0.7,
      "&:hover": {
        transform: "translateY(-4px)",
        boxShadow: isChecked
          ? "0 0 0 2px #1976d2, 0 4px 12px rgba(0,0,0,0.15)"
          : "0 4px 12px rgba(0,0,0,0.15)",
        opacity: 1,
      },
    }}
  >
    <Box
      component="img"
      src={imagePath}
      sx={{
        width: "100%",
        height: "auto",
        aspectRatio: "16/9",
        objectFit: "contain",
      }}
    />
  </Box>
);
