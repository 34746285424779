import { useRoutes, Navigate } from 'react-router-dom';
import { useStore } from './store/Store';
import MainLayout from './layouts/main';
import Auth from './Auth';
import WentWrong from './pages/WentWrong';
import { isTokenExpired } from './utils/Util';
import Page404 from './pages/Page404';
import LandingPage from './pages/distributor/LandingPage';
import ListDistributorUsers from './pages/distributor/users/ListDistributorUsers';
import AddNewDistributorUser from './pages/distributor/users/addDistributorUser/addDistributorUser';
import ListStores from './pages/distributor/store/ListStores';
import { useEffect } from 'react';
import ListOnlineUsers from './pages/distributor/onlineUsers/ListOnlineUsers';
import ListReports from './pages/distributor/reports/ListReports';
import Branding from './pages/distributor/settings/Branding';
import Layouts from './pages/distributor/settings/Layouts';
import StoreUsers from './pages/distributor/storeUsers/StoreUsers';
import OnlineStores from './pages/distributor/onlineStores/OnlineStores';
import OnlineCoupons from './pages/distributor/onlineCoupons/OnlineCoupons';
import AddNewStoreUser from './pages/distributor/storeUsers/addStoreUser/addStoreUser';
import StoreCoupons from './pages/distributor/storeCoupons/StoreCoupons';
import MyAccount from './pages/MyAccount';

export default function DistributorRouter() {




  const [store, dispatch] = useStore();
  const distributor = JSON.parse(localStorage.getItem("distributor"))

  useEffect(() => {
    if (isTokenExpired(distributor?.expires_at)) {
      (async () => {
        await dispatch({ type: "DISTRIBUTOR_LOGOUT" });
      })();
    }
  }, [distributor, dispatch]);

      const handleAppRedirect = () => {
        if (isTokenExpired(distributor?.expires_at)) {
          (async () => {
            await dispatch({ type: "DISTRIBUTOR_LOGOUT" });
          })();
        }
    
        if (distributor !== null && store?.distributor?.token !== null) {
            return <MainLayout isAdminLayout={true}/>;
        } else {
          return <Navigate to="/dt/login" />;
        }
      };


  return useRoutes([
    {
      path: "/dt",
      element:handleAppRedirect(),
      children: [
        { path: "/dt", index: true, element: <Navigate to="/dt/landing" replace /> },
        { path: "/dt/landing", element: <LandingPage /> },
        { path: "/dt/distributor-users", element: <Navigate to="/dt/distributor-users/list-users" /> },
        { path: "/dt/distributor-users/list-users", element: <ListDistributorUsers /> },
        { path: "/dt/distributor-users/add-new-user", element: <AddNewDistributorUser /> },
        { path: "/dt/stores", element: <Navigate to="/dt/stores/list" /> },
        { path: "/dt/stores/list", element: <ListStores /> },
        { path: "/dt/stores/users", element: <StoreUsers /> },
        { path: "/dt/stores/users/create", element: <AddNewStoreUser /> },
        { path: "/dt/stores/coupons", element: <StoreCoupons /> },


        { path: "/dt/stores-online", element: <Navigate to="/dt/stores-online/list" /> },
        { path: "/dt/stores-online/list", element: <OnlineStores /> },
        { path: "/dt/stores-online/users", element: <ListOnlineUsers /> },
        { path: "/dt/stores-online/coupons", element: <OnlineCoupons /> },


        { path: "/dt/reports", element: <ListReports /> },
        { path: "/dt/settings", element: <Navigate to="/dt/settings/white-labels" /> },
        { path: "/dt/settings/white-labels", element: <Branding /> },
        { path: "/dt/settings/layouts", element: <Layouts /> },

        { path: "/dt/my-account", element: <MyAccount /> },


      ]
    },
    {
      path: "/dt/login",
      element:(distributor === null || distributor?.token === null ) ? <Auth type="distributor-login" /> : <Navigate to="/dt"/>,
    },
    { path: "*", element: <Page404 /> },
    { path: "/oops", element: <WentWrong /> },
  ]);
}
