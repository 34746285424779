import React, { useState, useEffect } from "react";
import { TextField, Button, Grid, Box, Select, MenuItem, InputLabel, FormControl } from "@mui/material";
import { useTranslation } from "react-i18next";
import { UsersService, CommonService } from "../../../../api/services";

export default function EditDistributorUser({
  successCallback,
  formData,
  setModalStatus,
  setSnackbarStatus,
  setMessage,
}) {
  const { t } = useTranslation();

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [properties, setProperties] = useState({});
  const [countries, setCountries] = useState([]);

  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const response = await CommonService.listCountries();
        setCountries(response.data || []);
      } catch (error) {
        console.error("Error fetching countries:", error);
        setSnackbarStatus(true);
        setMessage(t("failed-to-get-countries"));
      }
    };

    fetchCountries();
  }, [setSnackbarStatus, setMessage, ]);

  useEffect(() => {
    if (formData) {
      setFirstName(formData.first_name || "");
      setLastName(formData.last_name || "");
      setProperties(formData.properties || {});
    }
  }, [formData]);

  const handleSubmit = (event) => {
    event.preventDefault();

    const updatedData = {
      first_name: firstName,
      last_name: lastName,
      properties: properties,
    };

    console.log("Submitting updated distributor user data:", updatedData);

    UsersService.updatePaDistributorUsers(formData.id, updatedData)
      .then((response) => {
        successCallback();
        setModalStatus(false); 
        setSnackbarStatus(true); 
        setMessage(t("distributor-user-updated-successfully"));
      })
      .catch((err) => {
        console.error("Update failed:", err);
        setSnackbarStatus(true);
        setMessage(t("failed-to-update-distributor-user"));
      });
  };

  const handlePropertyChange = (key, value) => {
    setProperties((prevProperties) => ({
      ...prevProperties,
      [key]: value,
    }));
  };

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            label={t("first-name")}
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            fullWidth
            required
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label={t("last-name")}
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            fullWidth
            required
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label={t("telephone")}
            value={properties.telephone || ""}
            onChange={(e) => handlePropertyChange("telephone", e.target.value)}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label={t("address1")}
            value={properties.address1 || ""}
            onChange={(e) => handlePropertyChange("address1", e.target.value)}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label={t("address2")}
            value={properties.address2 || ""}
            onChange={(e) => handlePropertyChange("address2", e.target.value)}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <InputLabel id="country-id-label">{t("country-id")}</InputLabel>
            <Select
              labelId="country-id-label"
              label={t("country-id")}
              value={properties.country_id || ""}
              onChange={(e) => handlePropertyChange("country_id", e.target.value)}
            >
              <MenuItem value="">
                <em>{t("select-country")}</em>
              </MenuItem>
              {countries.map((country) => (
                <MenuItem key={country.id} value={country.id}>
                  {country.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 1 }}>
            <Button onClick={() => setModalStatus(false)}>{t("cancel")}</Button>
            <Button type="submit" color="secondary">
              {t("save")}
            </Button>
          </Box>
        </Grid>
      </Grid>
    </form>
  );
}
