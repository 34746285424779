import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Tooltip, IconButton, Typography, Box, Button } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { DefaultPaginationData } from "../../../constants";
import { rowArrayToObject } from "../../../utils/Util";
import BaseModal from "../../../components/BaseModal";
import BaseSnackbar from "../../../components/BaseSnackbar";
import { BaseTable, TableFilterContainer } from "../../../components/table";
import { UsersService } from "../../../api/services";
import SearchBar from "../../../components/SearchBar";
import AddNewButton from "../../../components/buttons/AddNewButton";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import EditDistributorUser from "./editDistributorUser/editDistributorUser";
import DeleteDistributorUser from "./deleteDistributorUser/deleteDistributorUser";
import { useNavigate } from "react-router-dom";

export default function ListDistributorUsers() {
  const { t } = useTranslation();
  const theme = useTheme();
  const [openSnackbar, setSnackbarStatus] = useState(false);
  const [message, setMessage] = useState("");
  const [data, setData] = useState([]);
  const [paginationData, setPaginationData] = useState(DefaultPaginationData);
  const [loadingData, setLoadingData] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedRow, setSelectedRow] = useState({});
  const [openEditModal, setEditModalStatus] = useState(false);
  const [openDeleteModal, setDeleteModalStatus] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const navigate = useNavigate();

  const TABLE_HEAD = [
    { key: "id", label: t("user-id") },
    { key: "email", label: t("email") },
    { key: "first_name", label: t("first-name") },
    { key: "last_name", label: t("last-name") },
    { key: "address1", label: t("address1") },
    { key: "address2", label: t("address2") },
    { key: "telephone", label: t("telephone") },
    { key: "country_id", label: t("country") },
    { key: "action", label: t("actions"), align: "right" },
  ];

  const TABLE_FIELD_MAPPING = {
    id: { key: "id", index: 0 },
    email: { key: "email", index: 1 },
    first_name: { key: "first_name", index: 2 },
    last_name: { key: "last_name", index: 3 },
    address1: { key: "address1", index: 4 },
    address2: { key: "address2", index: 5 },
    telephone: { key: "telephone", index: 6 },
    country_id: { key: "country_id", index: 7 },
  };

  const fetchUsers = () => {
    setLoadingData(true);
    const params = {
      page: paginationData.page + 1,
      size: paginationData.rowsPerPage,
    };

    UsersService.listPaDistributorUsers(params)
      .then((response) => {
        const items = response.data.items.map((itemData) => {
          const item = new Array(TABLE_HEAD.length - 1).fill({});
          Object.keys(TABLE_FIELD_MAPPING).forEach((key) => {
            const value =
              key === "telephone"
                ? itemData.properties?.telephone
                : key.includes("address")
                ? itemData.properties?.[key]
                : key === "country_id"
                ? itemData.properties?.country_id
                : itemData[key];

            item[TABLE_FIELD_MAPPING[key].index] = {
              ...TABLE_FIELD_MAPPING[key],
              value: value || "",
            };
          });
          return item;
        });
        setData(items);
        setTotalCount(response.data.total);
      })
      .catch((err) => {
        setMessage(t("failed-to-get-distributor-users"));
        setSnackbarStatus(true);
        console.error(err);
      })
      .finally(() => {
        setLoadingData(false);
      });
  };

  useEffect(() => {
    fetchUsers();
  }, [paginationData]);

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const filterData = (data, query) => {
    if (!query) return data;
    return data.filter((row) => {
      return ["email", "first_name", "last_name"].some((key) => {
        const cellValue = row[TABLE_FIELD_MAPPING[key]?.index]?.value || "";
        return cellValue.toString().toLowerCase().includes(query.toLowerCase());
      });
    });
  };

  const filteredData = filterData(data, searchQuery);

  const modalHandler = (modalType, index = undefined) => {
    if (index !== undefined) {
      const row = rowArrayToObject(data[index]);
      setSelectedRow(row);
    }

    switch (modalType) {
      case "edit":
        setEditModalStatus(!openEditModal);
        break;
      case "delete":
        setDeleteModalStatus(!openDeleteModal);
        break;
      default:
        break;
    }
  };

  const getActionItems = (index) => (
    <Box sx={{ minWidth: "80px" }}>
      <Tooltip title={t("edit-user")}>
        <IconButton
          sx={{
            color: theme.palette.neutral,
          }}
          size="small"
          aria-label="edit-user"
          onClick={() => modalHandler("edit", index)}
        >
          <EditIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title={t("delete-user")}>
        <IconButton
          color="secondary"
          size="small"
          aria-label="delete-user"
          onClick={() => modalHandler("delete", index)}
        >
          <DeleteIcon />
        </IconButton>
      </Tooltip>
    </Box>
  );

  return (
    <>
      <BaseSnackbar
        open={openSnackbar}
        message={message}
        setOpen={setSnackbarStatus}
      />
      <BaseModal
        title={t("edit-user")}
        sx={{
          width: {xs:"80%", md:"40%"},
          height: "auto",
          overflow: "none",
        }}
        open={openEditModal}
        setOpen={setEditModalStatus}
        children={
          <EditDistributorUser
            successCallback={fetchUsers}
            formData={selectedRow}
            setModalStatus={setEditModalStatus}
            setSnackbarStatus={setSnackbarStatus}
            setMessage={setMessage}
          />
        }
      />
      <BaseModal
        title={t("delete-user")}
        open={openDeleteModal}
        setOpen={setDeleteModalStatus}
        sx={{width: {xs:"80%", md:"20%"}}}
        children={
          <DeleteDistributorUser
            successCallback={fetchUsers}
            formData={selectedRow}
            setModalStatus={setDeleteModalStatus}
            setSnackbarStatus={setSnackbarStatus}
            setMessage={setMessage}
          />
        }
      />
      <TableFilterContainer
        sx={{
          marginTop: "2vh",
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          alignItems: "center",
          justifyContent: "space-between",
          px: 2,
        }}
      >
        <SearchBar
          searchQuery={searchQuery}
          handleSearchChange={setSearchQuery}
        />
        <AddNewButton
          label={t("add-new-user")}
          onClick={() => navigate("/distributor-users/add-new-user")}
        />
      </TableFilterContainer>
      <BaseTable
        head={TABLE_HEAD}
        data={filteredData}
        actionItemPrep={getActionItems}
        pagination={{
          paginationData: { ...paginationData, totalCount },
          setPaginationData,
        }}
        loadingData={loadingData}
      />
    </>
  );
}
