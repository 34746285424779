import React, { useState, useEffect } from "react";
import { Tooltip, IconButton, Typography, Box, Button, Link } from "@mui/material";
import { useTranslation } from "react-i18next";
import { TableFilterContainer, BaseTable } from "../../../components/table";
import SearchBar from "../../../components/SearchBar";
import { useTheme } from "@mui/material/styles";
import { StoreService } from "../../../api/services";
import { DefaultPaginationData } from "../../../constants";
import BaseModal from "../../../components/BaseModal";
import BaseSnackbar from "../../../components/BaseSnackbar";
import { rowArrayToObject } from "../../../utils/Util";
import AddNewButton from "../../../components/buttons/AddNewButton";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

export default function OnlineStores() {
  const { t } = useTranslation();
  const theme = useTheme();
  const [loading, setLoading] = useState(false);
  const [paginationData, setPaginationData] = useState(DefaultPaginationData);
  const [searchQuery, setSearchQuery] = useState("");
  const [totalCount, setTotalCount] = useState(0);
  const [openAddModal, setAddModalStatus] = useState(false);
  const [openDeleteModal, setDeleteModalStatus] = useState(false);
  const [openEditModal, setEditModalStatus] = useState(false);
  const [openSnackbar, setSnackbarStatus] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});
  const [data, setData] = useState([]);
  const [message, setMessage] = useState("");
  
  const TABLE_HEAD = [
    { key: "id", label: t("Store ID") },
    { key: "name", label: t("Store Name") },
    { key: "created_at", label: t("Created At") },
    { key: "hash_code", label: t("store-code") },
    { key: "url", label: t("url") },
  ];

  const TABLE_FIELD_MAPPING = {
    id: { key: "id", index: 0 },
    name: { key: "name", index: 1 },
    created_at: { key: "created_at", index: 2 },
    hash_code: { key: "hash_code", index: 3 },
    url: { key: "url", index: 4 },
  };

  const fetchStores = () => {
    setLoading(true);
    const params = {
      page: paginationData.page + 1, 
      size: paginationData.rowsPerPage,
      online: true
    };

    StoreService.listStore(params)
    .then((response) => {
      const items = response.data.items.map((itemData) => {
        const item = new Array(TABLE_HEAD.length - 1).fill({});
  
        Object.keys(TABLE_FIELD_MAPPING).forEach((key) => {
          const value =
            key === "created_at"
              ? new Date(itemData[key]).toLocaleDateString()
              : key === "is_online"
              ? itemData[key] ? t("Yes") : t("No")
              : itemData[key];
  
              if (key === "url") {
                const hostname = window.location.hostname; 
                const url = `https://${hostname}/dt/${itemData.hash_code}/on/landing`;
                item[TABLE_FIELD_MAPPING[key].index] = {
                  ...TABLE_FIELD_MAPPING[key],
                  value: (
                    <Link
                      href={url}
                      target="_blank"
                      rel="noopener noreferrer"
                      sx={{ textDecoration: "none", color: "black" ,fontWeight: "bold" }}
                    >
                      {url}
                    </Link>
                  ),
                };
              } else {
                item[TABLE_FIELD_MAPPING[key].index] = {
                  ...TABLE_FIELD_MAPPING[key],
                  value: value || "",
                };
              }
            });
  
            return item;
          });
  
      setData(items);
      setTotalCount(response.data.total || items.length);
    })
    .catch((err) => {
      setMessage(t("failed-to-get-stores"));
      setSnackbarStatus(true);
      console.error(err);
    })
    .finally(() => {
      setLoading(false);
    });
  };

  useEffect(() => {
    fetchStores();
  }, [paginationData]);

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const filterData = (data, query) => {
    if (!query) return data;
    return data.filter((row) =>
      ["name"].some((key) => {
        const cellValue = row[TABLE_FIELD_MAPPING[key]?.index]?.value || "";
        return cellValue.toString().toLowerCase().includes(query.toLowerCase());
      })
    );
  };

  const filteredData = filterData(data, searchQuery);

  const modalHandler = (modalType, index = undefined) => {
  switch (modalType) {
       case "add":
         setAddModalStatus(!openAddModal);
         break;
       case "edit":
         setEditModalStatus(!openEditModal);
         break;
       case "delete":
         setDeleteModalStatus(!openDeleteModal);
         break;
       default:
         break;
     }
   
     if (index !== undefined && data[index]) {
       const row = rowArrayToObject(data[index]);
       setSelectedRow(row);
     }
   };

  const getActionItems = (index) => {
    return (
      <>
        {/* <Tooltip title={t("edit-store")}>
          <IconButton
             sx={{
              color: theme.palette.neutral,
            }}
            size="small"
            aria-label="edit-store"
            onClick={() => modalHandler("edit", index)}
          >
            <EditIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title={t("delete-store")}>
          <IconButton
            color="secondary"
            size="small"
            aria-label="delete-store"
            onClick={() => modalHandler("delete", index)}
          >
            <DeleteIcon />
          </IconButton>
        </Tooltip> */}
      </>
    );
  };

  return (
    <>
     <BaseSnackbar
        open={openSnackbar}
        message={message}
        setOpen={setSnackbarStatus}
      />

      <TableFilterContainer
        sx={{
          marginTop: "2vh",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          paddingRight: "20px",
          paddingLeft: "20px",
        }}
      >
        <SearchBar
          searchQuery={searchQuery}
          handleSearchChange={handleSearchChange}
        />
    {/*     <AddNewButton
              label={t("add-new-store")}
              onClick={() => modalHandler("add")}
            /> */}
      </TableFilterContainer>
      <Box sx={{ mt: 4 }}>
        <BaseTable
          head={TABLE_HEAD}
          data={filteredData}
          pagination={{
            paginationData: { ...paginationData, totalCount },
            setPaginationData,
          }}
          loadingData={loading}
        />
      </Box>
    </>
  );
}
