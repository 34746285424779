import api from "../Api";
import urls from "../Urls";

export const listGeneralFootballOdds = (params) => {
    return api.get(urls.list_football_odds, { params: params });
};

export const listCountries = (params) => {
    return api.get(urls.list_football_countries, { params: params });
};

export const listLeagues = (params) => {
    return api.get(urls.list_football_leagues, { params: params });
};

export const createCoupon =(payload) => {
    return api.post(urls.create_coupon, payload)
};

export const listFootballOddsDetail = (fixture_id, params) => {
    return api.get(urls.list_football_odds_detail(fixture_id), { params: params });
};

export const listCoupons = (params) => {
    return api.get(urls.list_coupons, {params: params});
};

export const updateCouponStatus = (coupon_id, body) => {
    return api.patch(urls.update_coupon_status(coupon_id), body);
};

export const listDistributorCoupons = (params) => {
    return api.get(urls.list_distributor_coupons, {params: params});
};

export const updateDistributorCouponStatus = (coupon_id, body) => {
    return api.patch(urls.update_distributor_coupon_status(coupon_id), body);
};
