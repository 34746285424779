import { useEffect, useState } from "react";
import { useTheme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { CommonService, PartitionSettings, PartitionsService } from "../../../api/services"; 
import BaseSnackbar from "../../../components/BaseSnackbar";
import SaveIcon from "@mui/icons-material/Save";
import { BaseTable, TableFilterContainer } from "../../../components/table";
import SearchBar from "../../../components/SearchBar";
import PublicTwoToneIcon from "@mui/icons-material/PublicTwoTone";
import HideImageIcon from "@mui/icons-material/HideImage";
import {
  Checkbox,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import ImageLoader from "../../../components/ImageLoader";

export default function ManagePartitionCountries() {
  const { t } = useTranslation();
  const theme = useTheme();
  const [openSnackbar, setSnackbarStatus] = useState(false);
  const [message, setMessage] = useState("");
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [searchCountry, setSearchCountry] = useState("");
  const [searchStatus, setSearchStatus] = useState("Any");
  const [updatedCountries, setUpdatedCountries] = useState([]);
  const [loadingData, setLoadingData] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [partitions, setPartitions] = useState([]);
  const [sports, setSports] = useState([]);
  const [selectedPartition, setSelectedPartition] = useState("");
  const [selectedSport, setSelectedSport] = useState("");
  const [numberOfSelectedRows, setNumberOfSelectedRows] = useState(0);


  useEffect(() => {
    let result = 0;
    for (const idx in data) if (data[idx][5].value.props.checked) result++;
    setNumberOfSelectedRows(result);
  }, [data]);

  const handleSelectAll = (event) => {
    setSearchStatus("Any");
    const isChecked = event.target.checked;
    setNumberOfSelectedRows(isChecked ? data.length : 0);

    let tempData = [...data];
    let newUpdatedCountries = [];

    tempData = tempData.map((row) => {
      const countryId = row[TABLE_FIELD_MAPPING["id"].index].value;
      const currentIsEnabled =
        row[TABLE_FIELD_MAPPING["is_enabled"].index].value.props.children ===
        "True";

      if (currentIsEnabled !== isChecked) {
        newUpdatedCountries.push({
          country_id: countryId,
          is_enabled: isChecked,
        });
      }

      row[TABLE_FIELD_MAPPING["select"].index] = {
        ...row[TABLE_FIELD_MAPPING["select"].index],
        value: (
          <Checkbox
            checked={isChecked}
            onChange={(e) => handleRowSelect(e, countryId)}
          />
        ),
      };

      row[TABLE_FIELD_MAPPING["is_enabled"].index] = {
        ...row[TABLE_FIELD_MAPPING["is_enabled"].index],
        value: (
          <Typography
            sx={{
              color: isChecked
                ? theme.palette.success.main
                : theme.palette.warning.main,
            }}
          >
            {isChecked ? "True" : "False"}
          </Typography>
        ),
      };

      return row;
    });

    setData(tempData);
    setFilteredData(filterCountries(tempData));
    setUpdatedCountries(newUpdatedCountries);
  };

  const TABLE_FIELD_MAPPING = {
    id: { key: "id", cellComponentType: "th", index: 0, noRender: true },
    flag: { key: "flag", index: 1 },
    name: { key: "name", index: 2 },
    code: { key: "code", index: 3 },
    is_enabled: { key: "enabled", index: 4, align: "center" },
    select: { key: "select", index: 5, align: "center" },
  };

  const TABLE_HEAD = [
    { key: "flag", label: t("flag") },
    { key: "name", label: t("country") },
    { key: "code", label: t("code") },
    { key: "enabled", label: t("enabled"), align: "center" },
    {
      key: "select",
      label: (
        <Checkbox
          checked={data.length > 0 && numberOfSelectedRows === data.length}
          indeterminate={
            numberOfSelectedRows > 0 && numberOfSelectedRows < data.length
          }
          onChange={handleSelectAll}
        />
      ),
      align: "center",
    },
  ];

  useEffect(() => {
    fetchPartitions();
    fetchSports();
  }, []);

  useEffect(() => {
    if (selectedPartition && selectedSport) {
      fetchPartitionCountries();
    }
  }, [selectedPartition, selectedSport]);
  
  const fetchPartitions = async () => {
    try {
      const response = await PartitionsService.listPartitions();
      if (response.status === 200 && response.data?.items) {
        setPartitions(response.data.items);
      } else {
        throw new Error("Failed to fetch partitions");
      }
    } catch (error) {
      console.error(error);
      setMessage(t("failed-to-fetch-partitions"));
      setSnackbarStatus(true);
    }
  };

  const fetchSports = async () => {
    try {
      const response = await CommonService.listSports();
      if (response.status === 200 ) {
        setSports(response.data);
      } else {
        throw new Error("Failed to fetch sports");
      }
    } catch (error) {
      console.error(error);
      setMessage(t("failed-to-fetch-sports"));
      setSnackbarStatus(true);
    }
  };
  const fetchPartitionCountries = () => {
    setLoadingData(true);
    PartitionSettings.listPartitionCountries(selectedPartition, selectedSport)
    .then((response) => {
      if (response.status === 200) {
        const items = response.data
          .sort((a, b) => b.is_enabled - a.is_enabled)
          .map((item) => {
            const row = new Array(TABLE_HEAD.length).fill({});

            row[TABLE_FIELD_MAPPING["id"].index] = {
              ...TABLE_FIELD_MAPPING["id"],
              value: item.country_id,
            };

            row[TABLE_FIELD_MAPPING["flag"].index] = {
              ...TABLE_FIELD_MAPPING["flag"],
              value: item.country.flag ? (
                <ImageLoader
                  src={item.country.flag}
                  alt={`${item.country.name} flag`}
                  width={20}
                  height={20}
                  fallback={
                    <HideImageIcon
                      fontSize="small"
                      sx={{ mr: 0.5, color: "#2e86c1" }}
                    />
                  }
                />
              ) : (
                <PublicTwoToneIcon
                  fontSize="small"
                  sx={{ mr: 0.5, color: "#2e86c1" }}
                />
              ),
            };

            row[TABLE_FIELD_MAPPING["name"].index] = {
              ...TABLE_FIELD_MAPPING["name"],
              value: item.country.name,
            };

            row[TABLE_FIELD_MAPPING["code"].index] = {
              ...TABLE_FIELD_MAPPING["code"],
              value: item.country.code,
            };

            row[TABLE_FIELD_MAPPING["is_enabled"].index] = {
              ...TABLE_FIELD_MAPPING["is_enabled"],
              value: (
                <Typography
                  sx={{
                    color: item.is_enabled
                      ? theme.palette.success.main
                      : theme.palette.warning.main,
                  }}
                >
                  {item.is_enabled ? "True" : "False"}
                </Typography>
              ),
            };

            row[TABLE_FIELD_MAPPING["select"].index] = {
              ...TABLE_FIELD_MAPPING["select"],
              value: (
                <Checkbox
                  checked={item.is_enabled}
                  onChange={(event) => handleRowSelect(event, item.country_id)}
                />
              ),
            };

            return row;
          });

        setData(items);
        setFilteredData(items);
      } else {
        throw new Error("Failed to get countries");
      }
    })
    .catch((err) => {
      setMessage(t("failed-to-get-countries"));
      console.log(err);
    })
    .finally(() => {
      setLoadingData(false);
    });
};


  const handleSave = () => {
    setSubmitting(true);
    
    const payload = {
      mappings: updatedCountries.map((country) => ({
        country_id: country.country_id,
        sport_id: selectedSport, 
        is_enabled: country.is_enabled,
      })),
    };
    PartitionSettings.bulkUpdatePartitionCountries(selectedPartition, payload)
    .then((response) => {
      if (response.status === 200) {
        setMessage(t("updated-successfully"));
        setSnackbarStatus(true);
        setUpdatedCountries([]);
      } else {
        throw new Error("Failed to update partition countries");
      }
    })
    .catch((err) => {
      console.error(err);
      setTimeout(() => {
        if (
          err.response?.data?.detail.includes("This country is not within the coverage area")
        ) {
          setSnackbarStatus(true);
          setSubmitting(false);
        } else {
          setMessage(t("failed to update"));
          setSnackbarStatus(true);
          setSubmitting(false);
        }
      }, 800);
    })
    .finally(() => {
      setSubmitting(false);
    });
  };  

  const handleRowSelect = (event, countryID) => {
    setNumberOfSelectedRows(
      event.target.checked ? numberOfSelectedRows + 1 : numberOfSelectedRows - 1
    );
    setFilteredData((prevData) => {
      const tempData = [...prevData];

      const index = tempData.findIndex(
        (item) => item[TABLE_FIELD_MAPPING["id"].index]?.value === countryID
      );

      const selectedItem = tempData[index];
      const isSelected =
        selectedItem[TABLE_FIELD_MAPPING["select"].index]?.value?.props
          ?.checked;
      const isEnabled =
        selectedItem[TABLE_FIELD_MAPPING["is_enabled"].index]?.value?.props
          ?.children === "True";

      selectedItem[TABLE_FIELD_MAPPING["select"].index] = {
        ...selectedItem[TABLE_FIELD_MAPPING["select"].index],
        value: (
          <Checkbox
            checked={!isSelected}
            onChange={(event) => handleRowSelect(event, countryID)}
          />
        ),
      };

      selectedItem[TABLE_FIELD_MAPPING["is_enabled"].index] = {
        ...selectedItem[TABLE_FIELD_MAPPING["is_enabled"].index],
        value: (
          <Typography
            sx={{
              color: !isEnabled
                ? theme.palette.success.main
                : theme.palette.warning.main,
            }}
          >
            {!isEnabled ? "True" : "False"}
          </Typography>
        ),
      };

      setUpdatedCountries((prevCountries) => {
        const indexInUpdated = prevCountries.findIndex(
          (item) => item.country_id === countryID
        );
        if (indexInUpdated === -1) {
          return [
            ...prevCountries,
            { country_id: countryID, is_enabled: !isEnabled },
          ];
        } else {
          const newCountries = [...prevCountries];
          newCountries.splice(indexInUpdated, 1);
          return newCountries;
        }
      });

      return tempData;
    });
  };

  const filterCountries = (data) => {
    return data.filter((item) => {
      const countryName = item[2].value.toLowerCase();
      const isEnabled = item[4].value.props.children === "True";

      const nameMatch =
        !searchCountry || countryName.includes(searchCountry.toLowerCase());
      const statusMatch =
        searchStatus === "Any" ||
        (searchStatus === "Enabled" && isEnabled) ||
        (searchStatus === "Disabled" && !isEnabled);

      return nameMatch && statusMatch;
    });
  };

  useEffect(() => {
    setFilteredData(filterCountries(data));
  }, [searchCountry, searchStatus]);


  return (
    <>
      <BaseSnackbar
        open={openSnackbar}
        message={message}
        setOpen={setSnackbarStatus}
      />
      <TableFilterContainer
       sx={{
        marginTop: "2vh",
        display: "flex",
        flexDirection: { xs: "column", sm: "row" },
        alignItems: "center",
        justifyContent: "space-between",
        px: 2,
        gap:2
      }}>
        <SearchBar
          searchQuery={searchCountry}
          handleSearchChange={(e) => setSearchCountry(e.target.value)}
        />
        <FormControl sx={{ width: {xs: "100%", md:"15%"} }}>
          <InputLabel>{t("Select Partition")}</InputLabel>
          <Select
            value={selectedPartition}
            onChange={(e) => setSelectedPartition(e.target.value)}
          >
            {Array.isArray(partitions) && partitions.length > 0 ? (
              partitions.map((partition) => (
                <MenuItem key={partition.id} value={partition.id}>
                  {partition.name}
                </MenuItem>
              ))
            ) : (
              <MenuItem disabled>{t("no-partitions-available")}</MenuItem>
            )}
          </Select>
        </FormControl>
        <FormControl sx={{width: {xs: "100%", md:"15%"}  }}>
          <InputLabel>{t("Select Sport")}</InputLabel>
          <Select
            value={selectedSport}
            onChange={(e) => setSelectedSport(e.target.value)}
          >
            {sports.map((sport) => (
              <MenuItem key={sport.id} value={sport.id}>
                {sport.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl sx={{ width: {xs: "100%", md:"10%"}  }}>
          <InputLabel id="status-select-label">{t("status")}</InputLabel>
          <Select
            labelId="status-select-label"
            label="Status"
            value={searchStatus}
            onChange={(e) => setSearchStatus(e.target.value)}
          >
            <MenuItem value="Any">Any</MenuItem>
            <MenuItem value="Enabled">Enabled</MenuItem>
            <MenuItem value="Disabled">Disabled</MenuItem>
          </Select>
        </FormControl>
        <LoadingButton
          type="submit"
          loading={submitting}
          disabled={updatedCountries.length === 0}
          sx={{
            bgcolor:
              updatedCountries.length > 0
                ? theme.palette.success.dark
                : theme.palette.secondary.main,
            "&:hover": {
              backgroundColor: theme.palette.success.main_light,
            },
            ml: "auto",
            mr: "4em",
          }}
          variant="contained"
          size="large"
          onClick={() => handleSave()}
          startIcon={<SaveIcon sx={{ height: "1.2rem", mr: -0.3 }} />}
        >
          {t("save-all")}
        </LoadingButton>
      </TableFilterContainer>

      {!selectedPartition || !selectedSport ? (
      <Typography
        sx={{
          mt: 6,
          textAlign: "center",
          color: theme.palette.text.secondary,
        }}
        variant="h6"
      >
        {t("please-select-partition-and-sport-to-see-countries")}
      </Typography>
    ) : (
      <BaseTable
        head={TABLE_HEAD}
        data={filteredData}
        loadingData={loadingData}
        pagination={{}}
        smallRow={true}
      />
    )}
  </>
  );
}
