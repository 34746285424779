import {
    Box,
    Card,
    Grid,
    InputLabel,
    TextField,
    FormControl,
    Select,
    MenuItem,
  } from "@mui/material";
  import * as Yup from "yup";
  import { useState, useEffect } from "react";
  import { useTranslation } from "react-i18next";
  import { Form, FormikProvider, useFormik } from "formik";
  import { UsersService } from "../../../../api/services";
  import BaseSnackbar from "../../../../components/BaseSnackbar";
  import SaveIcon from "@mui/icons-material/Save";
  import { LoadingButton } from "@mui/lab";
  import { useTheme } from "@mui/material/styles";
  import { useNavigate } from "react-router-dom";
  import { DistributorsService } from "../../../../api/services";

  export default function AddNewDistributorUser() {
    const { t } = useTranslation();
    const theme = useTheme();
    const navigate = useNavigate();
    const [message, setMessage] = useState("");
    const [openSnackbar, setSnackbarStatus] = useState(false);
    const [distributors, setDistributors] = useState([]);

    useEffect(() => {
      DistributorsService.listDistributors()
        .then((response) => {
          if (response?.data?.items) {
            setDistributors(response.data.items); 
          } else {
            setDistributors([]); 
          }
        })
        .catch((error) => {
          console.error("Failed to fetch distributors", error);
          setDistributors([]); 
        });
    }, []);

    const UserSchema = Yup.object().shape({
      first_name: Yup.string().required(t("first-name-is-required")),
      last_name: Yup.string().required(t("last-name-is-required")),
      email: Yup.string().email(t("email-is-invalid")).required(t("email-is-required")),
      password: Yup.string().required(t("password-is-required")),
      distributor_id: Yup.string().required(t("distributor-is-required")),
    });
  
    const formik = useFormik({
      validationSchema: UserSchema,
      initialValues: {
        first_name: "",
        last_name: "",
        email: "",
        password: "",
        distributor_id: "",
        distributor_admin: false, 
      },
      onSubmit: (values, actions) => {
        const distributorId = values.distributor_id;

        if (!distributorId) {
          setMessage(t("distributor-id-is-required"));
          setSnackbarStatus(true);
          return;
        }

        const payload = {
          first_name: values.first_name,
          last_name: values.last_name,
          email: values.email,
          password: values.password,
          distributor_admin: values.distributor_admin, 
          properties: {}, 
        };
  
        const successMessage = t("distributor-user-added-successfully");
        const failMessage = t("failed-to-add-distributor-user");
  
        UsersService.createPaDistributorUsers(distributorId,payload)
          .then((response) => {
            if (response.status === 201 || response.status === 200) {
              setTimeout(() => {
                navigate("/distributor-users/list-users", {
                  state: { successMessage },
                });
              }, 600);
  
              actions.setSubmitting(false);
            } else {
              throw new Error("Failed to create distributor user");
            }
          })
          .catch((err) => {
            console.error(err);
            setMessage(failMessage);
            setSnackbarStatus(true);
            setTimeout(() => {
              actions.setSubmitting(false);
            }, 1000);
          });
      },
    });
  
    const { errors, touched, isSubmitting, handleSubmit, getFieldProps, setFieldValue } = formik;
  
    return (
      <>
        <BaseSnackbar
          open={openSnackbar}
          message={message}
          setOpen={setSnackbarStatus}
        />

        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Card
              sx={{
                display: "flex",
                flexDirection: "column",
                padding: 5,
                gap: 4,
                mt: 5,
                mr: -5,
              }}
            >
              <Grid container spacing={10}>
                <Grid
                  item
                  xs={12}
                  md={6}
                  sx={{ display: "flex", flexDirection: "column" }}
                >
                  <InputLabel>
                    {t("select-distributor")}
                    <span style={{ color: "red" }}>*</span>
                  </InputLabel>
                  <FormControl fullWidth>
                    <Select
                      id="distributor_id"
                      {...getFieldProps("distributor_id")} 
                      onChange={(event) =>
                        setFieldValue(
                          "distributor_id",
                          Number(event.target.value)
                        )
                      } 
                      error={Boolean(
                        touched.distributor_id && errors.distributor_id
                      )}
                    >
                      {distributors.map((distributor) => (
                        <MenuItem key={distributor.id} value={distributor.id}>
                          {distributor.name}
                        </MenuItem>
                      ))}
                    </Select>
                    {touched.distributor_id && errors.distributor_id && (
                      <span style={{ color: "red", fontSize: "12px" }}>
                        {errors.distributor_id}
                      </span>
                    )}
                  </FormControl>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={6}
                  sx={{ display: "flex", flexDirection: "column" }}
                >
                  <InputLabel>
                    {t("first-name")}
                    <span style={{ color: "red" }}>*</span>
                  </InputLabel>
                  <TextField
                    fullWidth
                    id="first_name"
                    label={t("first-name")}
                    placeholder={t("enter-first-name")}
                    {...getFieldProps("first_name")}
                    error={Boolean(touched.first_name && errors.first_name)}
                    helperText={touched.first_name && errors.first_name}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={6}
                  sx={{ display: "flex", flexDirection: "column" }}
                >
                  <InputLabel>
                    {t("last-name")}
                    <span style={{ color: "red" }}>*</span>
                  </InputLabel>
                  <TextField
                    fullWidth
                    id="last_name"
                    label={t("last-name")}
                    placeholder={t("enter-last-name")}
                    {...getFieldProps("last_name")}
                    error={Boolean(touched.last_name && errors.last_name)}
                    helperText={touched.last_name && errors.last_name}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={6}
                  sx={{ display: "flex", flexDirection: "column" }}
                >
                  <InputLabel>
                    {t("email")}
                    <span style={{ color: "red" }}>*</span>
                  </InputLabel>
                  <TextField
                    fullWidth
                    id="email"
                    label={t("email")}
                    placeholder={t("enter-email")}
                    {...getFieldProps("email")}
                    error={Boolean(touched.email && errors.email)}
                    helperText={touched.email && errors.email}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={6}
                  sx={{ display: "flex", flexDirection: "column" }}
                >
                  <InputLabel>
                    {t("password")}
                    <span style={{ color: "red" }}>*</span>
                  </InputLabel>
                  <TextField
                    fullWidth
                    id="password"
                    type="password"
                    label={t("password")}
                    placeholder={t("enter-password")}
                    {...getFieldProps("password")}
                    error={Boolean(touched.password && errors.password)}
                    helperText={touched.password && errors.password}
                  />
                </Grid>
              </Grid>

              <Box
                className="add-user-button"
                sx={{ width: "100%", display:"flex", alignItems:"center", justifyContent:"center" }}
              >
                <LoadingButton
                  sx={{ mt: 3, backgroundColor: theme.palette.accent.main }}
                  size="large"
                  type="submit"
                  variant="contained"
                  loading={isSubmitting}
                  startIcon={<SaveIcon />}
                >
                  {t("add-user")}
                </LoadingButton>
              </Box>
            </Card>
          </Form>
        </FormikProvider>
      </>
    );
  }
  