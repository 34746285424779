import React, { useState, useEffect } from 'react';
import { 
  Box, 
  Tab, 
  Tabs,
  Typography,
  IconButton,
  Button,
  Divider,
  Paper,
  TextField,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { useStore } from '../../../store/Store';
import { useTheme } from '@mui/material/styles';

const BetCoupon = ({ bets, removeBet }) => {
  const { t } = useTranslation();
  const [tabValue, setTabValue] = useState(0);
  const [stakes, setStakes] = useState({});
  const [sharedStake, setSharedStake] = useState(0);
  const navigate = useNavigate();
  const [store] = useStore();
  const location = useLocation();
  const theme = useTheme();
  const match = location.pathname.match(/^\/dt\/(\d{6})\/on/);
  const hashCode = match ? match[1] : null;

  useEffect(() => {
    if (tabValue === 0) {
      const initialStakes = bets.reduce((acc, _, index) => {
        acc[index] = stakes[index] !== undefined ? stakes[index] : 50;
        return acc;
      }, {});
      setStakes(initialStakes);
    } else if (tabValue === 1 && bets.every(bet => bet.selectedBet)) {
      setSharedStake(50);
    }
  }, [tabValue, bets]);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleStakeChange = (betIndex, value) => {
    const numericValue = Number(value);
    if (value === "" || (!isNaN(numericValue) && numericValue >= 0)) {
      if (tabValue === 0) {
        setStakes(prev => ({
          ...prev,
          [betIndex]: value === "" ? "" : numericValue,
        }));
      } else {
        setSharedStake(value === "" ? "" : numericValue);
      }
    }
  };

  const handleIncrement = (betIndex) => {
    if (tabValue === 0) {
      setStakes(prev => ({
        ...prev,
        [betIndex]: (prev[betIndex] || 0) + 10,
      }));
    } else {
      setSharedStake(prev => prev + 10);
    }
  };

  const handleDecrement = (betIndex) => {
    if (tabValue === 0) {
      setStakes(prev => ({
        ...prev,
        [betIndex]: Math.max((prev[betIndex] || 0) - 10, 0),
      }));
    } else {
      setSharedStake(prev => Math.max(prev - 10, 0));
    }
  };

  const calculateBetReturn = (bet, stake) => {
    const odds = Number(bet.selectedBet);
    return stake * odds;
  };

  const calculateTotalReturn = () => {
    if (tabValue === 0) {
      return bets.reduce((total, bet, index) => {
        const odds = Number(bet.selectedBet);
        return total + ((stakes[index] || 0) * odds);
      }, 0);
    } else {
      if (bets.length > 0 && bets.every(bet => bet.selectedBet)) {
        const combinedOdds = bets.reduce((product, bet) => product * Number(bet.selectedBet), 1);
        return sharedStake * combinedOdds;
      }
      return 0;
    }
  };

  const calculateTotalStake = () => {
    if (tabValue === 0) {
      return Object.values(stakes).reduce((acc, stake) => acc + (stake || 0), 0);
    } else {
      return (bets.length > 0 && bets.every(bet => bet.selectedBet)) ? (sharedStake || 0) : 0;
    }
  };

  const handleCheckout = async () => {
    const couponData = {
      placed_bets: bets.map((bet, index) => ({
        fixture: bet.fixture || 0,
        odd: bet.selectedBet || 0,
        odd_id: bet.odd_id || 0,
        bet_amount: tabValue === 0 ? stakes[index] || 0 : sharedStake || 0,
    })),
    coupon_type: tabValue === 0 ? 1 : 2, // 1 for single, 2 for multiple
    total_stake: calculateTotalStake(),
    };
  
    const navigatePath = store.userType === "kiosk"
    ? "/ki/coupon-summary"
    : hashCode
      ? `/dt/${hashCode}/on/coupon-summary`
      : "/on/coupon-summary"; 

  navigate(navigatePath, { state: { couponData } });

  };
  
  const StakeInput = ({ index, isMultiple = false }) => (
    <Box sx={{ 
      display: 'flex',
      alignItems: 'center',      
      justifyContent: 'space-between',
    }}>
      <Typography sx={{ 
        fontSize: '0.9rem',
        minWidth: '40px'
      }}>
       {t("Stake")}
      </Typography>
      <Box sx={{ 
        display: 'flex',
        alignItems: 'center',
        mx: 3
      }}>
        <Button 
          onClick={() => handleDecrement(index)}
          sx={{ 
            minWidth: '25px',
            height: '20px',
            border: '1px solid #ddd',
            borderRadius: '4px',
            mt: 1
          }}
        >
          −
        </Button>
        <TextField
          value={isMultiple ? sharedStake : stakes[index]}
          onChange={(e) => handleStakeChange(index, e.target.value)}
          variant="outlined"
          size="small"
          sx={{ 
            width: '60px',
            mx: 1,
            '& .MuiOutlinedInput-input': {
              textAlign: 'center',
              py: 0.5
            }
          }}
        />
        <Button 
          onClick={() => handleIncrement(index)}
          sx={{ 
            minWidth: '25px',
            height: '20px',
            p: 0,
            border: '1px solid #ddd',
            borderRadius: '4px',
            mt: 1 
          }}
        >
          +
        </Button>
      </Box>
    </Box>
  );

  return (
    <Box position="relative">
    <Paper sx={{ 
      maxWidth: 400, 
      backgroundColor: theme.palette.common.white,
      boxShadow: 1
    }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs 
          value={tabValue} 
          onChange={handleTabChange}
          sx={{
            '& .MuiTab-root': {
              textTransform: 'none',
              fontSize: '0.9rem',
              minHeight: '48px'
            },
            '& .Mui-selected': {
              color: theme.palette.success.dark,
            },
            '& .MuiTabs-indicator': {
              backgroundColor: theme.palette.success.dark,
            }
          }}
        >
          <Tab label={t("Single")} />
          <Tab label={t("Multiple")} />
        </Tabs>
      </Box>

      <Box sx={{ p: 2 }}>
        {bets.map((bet, index) => (
          <Box key={index} sx={{ mb: 2 }}>
            <Box sx={{ 
              display: 'flex', 
              justifyContent: 'space-between',
              alignItems: 'flex-start',
              mb: 1
            }}>
              <Box>
                <Typography 
                  sx={{ 
                    fontSize: '0.9rem', 
                    fontWeight: 'bold',
                    mb: 0.5 
                  }}
                >
                  {bet.fixture.home_team} vs {bet.fixture.away_team}
                </Typography>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography 
                  sx={{ 
                    fontWeight: 'bold',
                    mr: 1
                  }}
                >
                  {bet.selectedBet}
                </Typography>
                <IconButton 
                  size="small" 
                  onClick={() => removeBet(bet)}
                  sx={{ 
                    padding: 0,
                    '&:hover': {
                      color: '#666'
                    }, 
                  }}
                >
                  <CloseIcon fontSize="small" />
                </IconButton>
              </Box>
            </Box>

            {tabValue === 0 && bet.selectedBet && (
              <>
                <StakeInput index={index} />
                <Typography sx={{ mt: 1, fontSize: '0.8rem', color: theme.palette.success.darkest, fontWeight: 'bold' }}>
                {t("Return")}: ₺{calculateBetReturn(bet, stakes[index] || 0).toFixed(2)}
                </Typography>
              </>
            )}
            <Divider sx={{ mt: 1 }} />
          </Box>
        ))}

        {tabValue === 1 && bets.length > 0 && bets.every(bet => bet.selectedBet) && (
            <StakeInput index={0} isMultiple={true} />
        )}

        <Box sx={{ mt: 2 }}>
          <Box sx={{ 
            display: 'flex', 
            justifyContent: 'space-between',
            mb: 1
          }}>
            <Typography sx={{ fontSize: '0.9rem' }}>{t("Total Stake")}</Typography>
            <Typography sx={{ fontSize: '0.9rem' }}>
            ₺{calculateTotalStake().toFixed(2)}
            </Typography>
          </Box>
          <Box sx={{ 
            display: 'flex', 
            justifyContent: 'space-between',
            color: theme.palette.success.darkest,
            fontWeight: 'bold'
          }}>
            <Typography sx={{ fontSize: '0.9rem' }}>{t("Total Payout")}</Typography>
            <Typography sx={{ fontSize: '0.9rem' }}>₺{calculateTotalReturn().toFixed(2)}</Typography>
          </Box>
        </Box>

        <Button
          variant="contained"
          fullWidth
          color='accent'
          sx={{my:2}}
          onClick={handleCheckout}
          disabled={bets.length === 0}
        >
          {t("Checkout")}
        </Button>
      </Box>
    </Paper>
    </Box>
  );
};

export default BetCoupon;
