import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Typography, Box, Tooltip, IconButton, Tab, Tabs, Dialog, DialogTitle, DialogContent, DialogActions, Button, Grid, Select, MenuItem, FormControl, InputLabel } from "@mui/material";
import { useLocation } from "react-router-dom";
import { DefaultPaginationData } from "../../../constants";
import BaseSnackbar from "../../../components/BaseSnackbar";
import { BaseTable, TableFilterContainer } from "../../../components/table";
import { DistributorsService, StoreService } from "../../../api/services";
import VisibilityIcon from "@mui/icons-material/Visibility";
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import PendingIcon from '@mui/icons-material/Pending';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { styled } from '@mui/material/styles';
import { alpha } from '@mui/material/styles';
import { couponStatusMap, couponStatusColors } from "../../../constants";
import dayjs from 'dayjs';

const StyledCard = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3),
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[1],
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
}));

const IconWrapper = styled(Box)(({ theme, color = 'primary.main' }) => ({
  backgroundColor: alpha(theme.palette[color.split('.')[0]][color.split('.')[1] || 'main'], 0.1),
  borderRadius: '50%',
  padding: theme.spacing(1.5),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const couponTypeMap = {
  1: "Single",
  2: "Multiple"
};

const couponTypeColors = {
  1: "primary.main",
  2: "secondary.main"
};

export default function ListReports() {
  const { t } = useTranslation();
  const location = useLocation();
  const { successMessage } = location.state || {};
  const [openSnackbar, setSnackbarStatus] = useState(false);
  const [message, setMessage] = useState("");
  const [data, setData] = useState([]);
  const [paginationData, setPaginationData] = useState(DefaultPaginationData);
  const [loadingData, setLoadingData] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [summaryData, setSummaryData] = useState({
    totalBetAmount: 0,
    totalPendingPayment: 0,
    totalLost: 0,
    totalPendingEarning: 0,
    totalCurrentBalance: 0,
    totalWon: 0,
  });
  const [selectedTab, setSelectedTab] = useState(0);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedBetDetails, setSelectedBetDetails] = useState(null);
  const [filters, setFilters] = useState({
    store_id: "",
    coupon_type: "",
    coupon_status: "",
    user_type: "",
  });
  const [stores, setStores] = useState([]);

  const summaryCardConfig = {
    totalBetAmount: {
      icon: <AccountBalanceWalletIcon />,
      color: 'primary.main',
      label: t("total-bet-amount")
    },
    totalPendingPayment: {
      icon: <PendingIcon />,
      color: 'warning.main',
      label: t("total-pending-payment")
    },
    totalLost: {
      icon: <TrendingDownIcon />,
      color: 'error.main',
      label: t("total-lost")
    },
    totalPendingEarning: {
      icon: <PendingIcon />,
      color: 'info.main',
      label: t("total-pending-earning")
    },
    totalCurrentBalance: {
      icon: <AccountBalanceWalletIcon />,
      color: 'success.main',
      label: t("total-current-balance")
    },
    totalWon: {
      icon: <CheckCircleIcon />,
      color: 'success.main',
      label: t("total-won")
    }
  };

  useEffect(() => {
    if (successMessage) {
      setSnackbarStatus(true);
      setMessage(successMessage);
    }
  }, [successMessage]);

  useEffect(() => {
    StoreService.listStore()
      .then((response) => {
        setStores(response.data.items || []);
      })
      .catch((error) => {
        console.error("Failed to fetch stores:", error);
        setMessage(t("failed-to-get-stores"));
        setSnackbarStatus(true);
      });
  }, []);

  const TABLE_HEAD = [
    { key: "coupon_id", label: t("coupon-id") },
    { key: "created_at", label: t("created-at") },
    { key: "coupon_type", label: t("coupon-type") },
    { key: "coupon_status", label: t("status") },
    { key: "total_payout", label: t("total-payout") },
    { key: "actions", label: t("actions") },
  ];

  const TABLE_FIELD_MAPPING = {
    coupon_id: { key: "coupon_id", cellComponentType: "th", index: 0 },
    created_at: { key: "created_at", index: 1 },
    coupon_type: { key: "coupon_type", index: 2 },
    coupon_status: { key: "coupon_status", index: 3 },
    total_payout: { key: "total_payout", index: 4 },
  };

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const getCouponData = (tabIndex, responseData) => {
    switch (tabIndex) {
      case 0:
        return responseData.pending_coupon_details || [];
      case 1:
        return responseData.total_won_details || [];
      case 2:
        return responseData.pending_earning_details || [];
      case 3:
        return responseData.current_balance_details || [];
      case 4:
        return responseData.lost_coupon_details || [];
      default:
        return [];
    }
  };

  const fetchReports = () => {
    setLoadingData(true);
    const params = {
      page: paginationData.page + 1,
      size: paginationData.rowsPerPage,
      ...filters,
    };

    Object.keys(params).forEach(key => 
      params[key] === "" && delete params[key]
    );

    DistributorsService.listCouponReport(params)
      .then((response) => {
        let items = [];
        setSummaryData({
          totalBetAmount: response.data.total_bet_amount,
          totalPendingPayment: response.data.total_pending_payment,
          totalLost: response.data.total_lost,
          totalPendingEarning: response.data.total_pending_earning,
          totalCurrentBalance: response.data.total_current_balance,
          totalWon: response.data.total_won,
        });

        const couponDetails = getCouponData(selectedTab, response.data);
        setTotalCount(couponDetails.length);

        for (const idx in couponDetails) {
          let item = new Array(TABLE_HEAD.length - 1).fill({});
          const itemData = couponDetails[idx];

          Object.entries(itemData).forEach(([key, value]) => {
            if (key in TABLE_FIELD_MAPPING) {
              if (key === "created_at") {
                item[TABLE_FIELD_MAPPING[key].index] = {
                  ...TABLE_FIELD_MAPPING[key],
                  value: dayjs(value).format('YYYY-MM-DD HH:mm:ss'),
                };
              } else if (key === "coupon_status") {
                item[TABLE_FIELD_MAPPING[key].index] = {
                  ...TABLE_FIELD_MAPPING[key],
                  value: (
                    <Typography 
                      sx={{ 
                        color: couponStatusColors[value],
                        fontWeight: 'medium'
                      }}
                    >
                      {couponStatusMap[value]}
                    </Typography>
                  ),
                };
              } else if (key === "coupon_type") {
                item[TABLE_FIELD_MAPPING[key].index] = {
                  ...TABLE_FIELD_MAPPING[key],
                  value: (
                    <Typography 
                      sx={{ 
                        color: couponTypeColors[value],
                        fontWeight: 'medium'
                      }}
                    >
                      {couponTypeMap[value]}
                    </Typography>
                  ),
                };
              } else {
                item[TABLE_FIELD_MAPPING[key].index] = {
                  ...TABLE_FIELD_MAPPING[key],
                  value: value || "-",
                };
              }
            }
          });

          item[TABLE_HEAD.length - 1] = {
            key: "actions",
            value: (
              <Box sx={{ display: 'flex', gap: 1 }}>
                {itemData.bet_details.length > 0 && (
                  <Tooltip title={t("view-bet-details")}>
                    <IconButton
                      size="small"
                      onClick={() => handleViewBetDetails(itemData.bet_details)}
                    >
                      <VisibilityIcon />
                    </IconButton>
                  </Tooltip>
                )}
              </Box>
            ),
          };

          items.push(item);
        }
        setData(items);
      })
      .catch((err) => {
        setMessage(t("failed-to-get-reports"));
        setSnackbarStatus(true);
        console.error(err);
      })
      .finally(() => {
        setLoadingData(false);
      });
  };

  useEffect(() => {
    fetchReports();
  }, [paginationData, selectedTab, filters]);

  const handleViewBetDetails = (betDetails) => {
    setSelectedBetDetails(betDetails);
    setOpenDialog(true);
  };

  const BetDetailsDialog = ({ open, onClose, betDetails }) => {
    if (!betDetails) return null;
    
    return (
      <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
        <DialogTitle>{t("bet-details")}</DialogTitle>
        <DialogContent>
          {betDetails.map((bet, index) => (
            <Box key={index} sx={{ mb: 2, p: 2, bgcolor: 'background.paper', borderRadius: 1 }}>
              <Typography variant="subtitle1" sx={{ mb: 1 }}>
                {bet.fixture.home_team_name} vs {bet.fixture.away_team_name}
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
                  <Typography variant="body2" color="text.secondary">
                    {t("bet-amount")}: {bet.bet_amount}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Typography variant="body2" color="text.secondary">
                    {t("odd-value")}: {bet.odd.value}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Typography variant="body2" color="text.secondary">
                    {t("odd")}: {bet.odd.odd}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          ))}
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>{t("close")}</Button>
        </DialogActions>
      </Dialog>
    );
  };

  const handleFilterChange = (event) => {
    const { name, value } = event.target;
    setFilters(prev => ({
      ...prev,
      [name]: value
    }));
  };

  return (
    <>
      <BaseSnackbar
        open={openSnackbar}
        message={message}
        setOpen={setSnackbarStatus}
      />

      <Box sx={{ 
        display: 'grid', 
        gridTemplateColumns: {
          xs: '1fr',
          sm: 'repeat(2, 1fr)',
          md: 'repeat(3, 1fr)',
        },
        gap: 2,
        mb: 4,
      }}>
        {Object.entries(summaryData).map(([key, value]) => (
          <StyledCard key={key}>
            <Box>
              <Typography 
                variant="subtitle2" 
                color="text.secondary"
                sx={{ mb: 1 }}
              >
                {summaryCardConfig[key].label}
              </Typography>
              <Typography 
                variant="h4" 
                color={summaryCardConfig[key].color}
                sx={{ fontWeight: 'medium' }}
              >
                {value}
              </Typography>
            </Box>
            <IconWrapper color={summaryCardConfig[key].color}>
              {summaryCardConfig[key].icon}
            </IconWrapper>
          </StyledCard>
        ))}
      </Box>

      <TableFilterContainer>
        <Box sx={{ 
        display: 'flex', 
        gap: 2, 
        padding: 2,
        flexWrap: 'wrap'
      }}>
        <FormControl sx={{ minWidth: 200 }}>
          <InputLabel>{t("store")}</InputLabel>
          <Select
            name="store_id"
            value={filters.store_id}
            onChange={handleFilterChange}
            label={t("store")}
          >
            <MenuItem value="">
              <em>{t("all")}</em>
            </MenuItem>
            {stores.map((store) => (
              <MenuItem key={store.id} value={store.id}>
                {store.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl sx={{ minWidth: 200 }}>
          <InputLabel>{t("coupon-type")}</InputLabel>
          <Select
            name="coupon_type"
            value={filters.coupon_type}
            onChange={handleFilterChange}
            label={t("coupon-type")}
          >
            <MenuItem value="">
              <em>{t("all")}</em>
            </MenuItem>
            {Object.entries(couponTypeMap).map(([value, label]) => (
              <MenuItem key={value} value={value}>
                {label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl sx={{ minWidth: 200 }}>
          <InputLabel>{t("status")}</InputLabel>
          <Select
            name="coupon_status"
            value={filters.coupon_status}
            onChange={handleFilterChange}
            label={t("status")}
          >
            <MenuItem value="">
              <em>{t("all")}</em>
            </MenuItem>
            {Object.entries(couponStatusMap).map(([value, label]) => (
              <MenuItem key={value} value={value}>
                {label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl sx={{ minWidth: 200 }}>
          <InputLabel>{t("user-type")}</InputLabel>
          <Select
            name="user_type"
            value={filters.user_type}
            onChange={handleFilterChange}
            label={t("user-type")}
          >
            <MenuItem value="">
              <em>{t("all")}</em>
            </MenuItem>
            <MenuItem value="kiosk">{t("kiosk")}</MenuItem>
            <MenuItem value="online">{t("online")}</MenuItem>
          </Select>
        </FormControl>
      </Box>
      </TableFilterContainer>

      <Tabs 
        value={selectedTab} 
        onChange={handleTabChange} 
        aria-label="report tabs"
        sx={{ my: 3 }}
        variant="scrollable"
        scrollButtons="auto"
      >
        <Tab label={t("pending-coupons")} />
        <Tab label={t("won-coupons")} />
        <Tab label={t("pending-earnings")} />
        <Tab label={t("current-balance")} />
        <Tab label={t("lost-coupons")} />
      </Tabs>
      
      <BaseTable
        head={TABLE_HEAD}
        data={data}
        pagination={{
          paginationData: { ...paginationData, totalCount: totalCount },
          setPaginationData: setPaginationData,
        }}
        loadingData={loadingData}
      />

      <BetDetailsDialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        betDetails={selectedBetDetails}
      />
    </>
  );
}
