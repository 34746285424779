import {
    Box,
    Card,
    Grid,
    InputLabel,
    TextField,
    Select,
    MenuItem,
    FormControl,
  } from "@mui/material";
  import * as Yup from "yup";
  import { useState, useEffect } from "react";
  import { useTranslation } from "react-i18next";
  import { Form, FormikProvider, useFormik } from "formik";
  import { UsersService, StoreService } from "../../../../api/services";
  import BaseSnackbar from "../../../../components/BaseSnackbar";
  import SaveIcon from "@mui/icons-material/Save";
  import { LoadingButton } from "@mui/lab";
  import { useTheme } from "@mui/material/styles";
  import { useNavigate } from "react-router-dom";
  
  export default function AddOnlineUser({setModalStatus, setSnackbarStatus, setMessage, successCallback}) {
    const { t } = useTranslation();
    const theme = useTheme();
    const navigate = useNavigate();
    const [stores, setStores] = useState([]);

    useEffect(() => {

      StoreService.listStore({online:true})
        .then((response) => {
          if (response?.data?.items) {
            setStores(response.data.items);
          } else {
            setStores([]); 
          }
        })
        .catch((error) => {
          console.error("Failed to fetch stores:", error);
          setStores([]);
        })
    }, []);
  
    const UserSchema = Yup.object().shape({
      /* store_id: Yup.string().required(t("store-is-required")), */
      user_name: Yup.string().required(t("username-is-required")),
      password: Yup.string().required(t("password-is-required")),
    });
  
    const formik = useFormik({
      validationSchema: UserSchema,
      initialValues: {
        /* store_id: "", */
        user_name: "",
        password: "",
        properties: {}, 
      },
      onSubmit: (values, actions) => {
        const storeId = stores[0].id;

        if (!storeId) {
          setMessage(t("store-is-required"));
          setSnackbarStatus(true);
          return;
        }

        const payload = {
          user_name: values.user_name,
          password: values.password,
          properties: {}, 
        };
  
        const successMessage = t("new-online-user-added-successfully");
        const failMessage = t("failed-to-add-online-user");
  
        UsersService.createOnlineUser(storeId,payload)
          .then((response) => {
            if (response.status === 201 || response.status === 200) {
              setMessage(successMessage);
              setSnackbarStatus(true);
              successCallback();
              setModalStatus(false);
              actions.setSubmitting(false);
            } else {
              throw new Error("Failed to create online user");
            }
          })
          .catch((err) => {
            console.error(err);
            setMessage(failMessage);
            setSnackbarStatus(true);
            setTimeout(() => {
              actions.setSubmitting(false);
            }, 1000);
          });
      },
    });
  
    const { errors, touched, isSubmitting, handleSubmit, getFieldProps, setFieldValue } = formik;
  
    return (
      <>
  
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Card
            sx={{
              display: "flex",
              flexDirection: "column",
              padding: 4,
              gap: 3,
              mt: 3,
              maxWidth: 450,
              mx: "auto", 
              borderRadius: 3,
              boxShadow: 5,
            }}
          >
            <Grid container spacing={3} justifyContent="center">
            {/* <Grid item xs={12}>
                  <InputLabel>
                    {t("select-store")}
                    <span style={{ color: "red" }}>*</span>
                  </InputLabel>
                  <FormControl fullWidth>
                    <Select
                      id="store_id"
                      {...getFieldProps("store_id")} 
                      onChange={(event) =>
                        setFieldValue(
                          "store_id",
                          Number(event.target.value)
                        )
                      } 
                      error={Boolean(
                        touched.store_id && errors.store_id
                      )}
                    >
                      {stores.map((store) => (
                        <MenuItem key={store.id} value={store.id}>
                          {store.name}
                        </MenuItem>
                      ))}
                    </Select>
                    {touched.store_id && errors.store_id && (
                      <span style={{ color: "red", fontSize: "12px" }}>
                        {errors.store_id}
                      </span>
                    )}
                  </FormControl>
                </Grid> */}

              <Grid item xs={12}>
                <TextField
                  fullWidth
                  id="user_name"
                  label={t("username")}
                  placeholder={t("enter-username")}
                  {...getFieldProps("user_name")}
                  error={Boolean(touched.user_name && errors.user_name)}
                  helperText={touched.user_name && errors.user_name}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  fullWidth
                  id="password"
                  type="password"
                  label={t("password")}
                  placeholder={t("enter-password")}
                  {...getFieldProps("password")}
                  error={Boolean(touched.password && errors.password)}
                  helperText={touched.password && errors.password}
                />
              </Grid>

              <Grid item xs={12} textAlign="center">
                <LoadingButton
                  size="large"
                  type="submit"
                  variant="contained"
                  color="accent"
                  loading={isSubmitting}
                  startIcon={<SaveIcon />}
                >
                  {t("add-user")}
                </LoadingButton>
              </Grid>
            </Grid>
          </Card>
        </Form>
      </FormikProvider>
    </>
    );
  }
  