import React from 'react';
import { Box, Button } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import SportsSoccerIcon from '@mui/icons-material/SportsSoccer';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import TimerIcon from '@mui/icons-material/Timer';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { useStore } from '../../../store/Store';

const SubHeaderWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: '100%',
  backgroundColor: theme.palette.common.white,
  borderBottom: '1px solid #e0e0e0',
}));

const NavButton = styled(Button)(({ theme }) => ({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '8px',
  color: '#666',
  textTransform: 'none',
  gap: '4px',
  borderRadius: 0,
  '&:hover': {
    backgroundColor: theme.palette.grey[200],
  },
  '& .MuiSvgIcon-root': {
    fontSize: '24px',
    color: theme.palette.error.darkest,
  },
  '& .label': {
    fontSize: '12px',
    whiteSpace: 'nowrap',
  }
}));

export default function SubHeader() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [store] = useStore();
  const theme = useTheme();

  const menuItems = [
    { icon: <SportsSoccerIcon />, label: t("sport-betting"), path: store.userType === 'kiosk' ? '/ki/landing' : `/dt/${store.hashCode}/on/landing` },
    { icon: <CalendarTodayIcon />, label: t("today"), path: store.userType === 'kiosk' ? '/ki/today' : `/dt/${store.hashCode}/on/today` },
    { icon: <TimerIcon />, label: t("last-minute"), path: store.userType === 'kiosk' ? '/ki/last-minute' : `/dt/${store.hashCode}/on/last-minute` },
  ];

  return (
    <SubHeaderWrapper>
      {menuItems.map((item, index) => (
        <NavButton
          key={index}
          onClick={() => navigate(item.path)}
        >
          {item.icon}
          <span className="label">{item.label}</span>
        </NavButton>
      ))}
    </SubHeaderWrapper>
  );
}