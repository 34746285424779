import React, { useState, useEffect } from 'react';
import { Tab, Tabs, Box, Grid, TextField, Autocomplete } from '@mui/material';

import { useTranslation } from "react-i18next";
import KioskLayout from './KioskLayout';
import OnlineLayout from './OnlineLayout';

export default function Layouts() {
  const [selectedTab, setSelectedTab] = useState(0);
  const { t } = useTranslation();


  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <>
      <Tabs value={selectedTab} onChange={handleTabChange} aria-label="tabs" sx={{pt: 3}}>
        <Tab label="Kiosk Layout" />
        <Tab label="Online Layout" />
      </Tabs>
   <Box sx={{ p: 3 }}>
  {selectedTab === 0 && (
    <KioskLayout
      selectedTab={selectedTab}
    />
  )}
  {selectedTab === 1 && (
    <OnlineLayout
      selectedTab={selectedTab}
    />
  )}
</Box>

    </>
  );
};

