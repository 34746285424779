import React, { useState } from 'react';
import { AppBar, Box, Toolbar, IconButton, Typography, Menu, MenuItem, Divider } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import AccountCircle from '@mui/icons-material/AccountCircle';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { useStore } from "../../../store/Store";
import LanguageSelector from "../../../components/LanguageSelector";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Logo from "../../../components/Logo";


const StyledAppBar = styled(AppBar)(({ theme }) => ({
  backgroundColor: theme.palette.error.darkest,
  maxHeight:"50px",
  justifyContent:"center"
}));

export default function Header() {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const [store, dispatch] = useStore();
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  
  const match = location.pathname.match(/^\/dt\/(\d{6})\/on/);
  const hashCode = match ? match[1] : null;
  
  const isLandingPage = location.pathname === '/ki/landing' || 
                       location.pathname === `/dt/${hashCode}/on/landing`;
  
  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    handleClose();

    if (store.userType === "kiosk") {
      dispatch({ type: "KIOSK_LOGOUT" });
      navigate("/ki/login", { replace: true });
    } else if (store.userType === 'onlineUser') {
      dispatch({ type: "ONLINE_LOGOUT" });
      navigate(`/dt/${hashCode}/on/login`, { replace: true });
    }
    
  };


  return (
    <StyledAppBar position="static">
      <Toolbar sx={{ justifyContent: 'space-between'}}>
        <Box sx={{width:"48px"}}>
          {!isLandingPage && (
            <IconButton 
              onClick={() => navigate(-1)} 
              sx={{ color: theme.palette.common.white}}
            >
              <ArrowBackIcon />
            </IconButton>
          )}
        </Box>

    <Logo
            sx={{
              width: { xs: 80, md: 110 },
              height: { xs: 30, md: 50 },
              position: 'absolute',
              left: '49%',
              transform: 'translateX(-50%)'
            }}
          />

        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <IconButton
            size="large"
            onClick={handleMenu}
            sx={{ color: theme.palette.common.white }}
          >
            <AccountCircle />
          </IconButton>
          <Menu
            sx={{
              '& .MuiPaper-root': {
                width: '200px',
                '& .MuiMenuItem-root': {
                  padding: '12px 24px',
                  fontSize: '16px'
                }
              }
            }}
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            <MenuItem onClick={handleLogout}>{t("logout")}</MenuItem>
          </Menu>

          <LanguageSelector />
        </Box>
      </Toolbar>
    </StyledAppBar>
  );
}
