import React, { useState, useEffect, useRef } from "react";
import { Box, Chip } from "@mui/material";
import SportsSoccerIcon from "@mui/icons-material/SportsSoccer"; 
import { CommonService } from "../../../api/services";
import { getSportIcons } from "../../../constants";
import { useTheme } from '@mui/material/styles';

const SportsFilter = ({setFilters}) => {
  const [sports, setSports] = useState([]);
  const [selectedSports, setSelectedSports] = useState([]);
  const sliderRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);
  const theme = useTheme();

  useEffect(() => {
    // Fetch sports data
    CommonService.listSports()
      .then((response) => {
        if (response.status === 200) {
          setSports(response.data);
        } else {
          throw new Error("Failed to fetch sports");
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);


  useEffect(() => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      sports: selectedSports,
    }));
  }, [selectedSports, setFilters]);

  const handleChipClick = (sport) => {
    setSelectedSports((prev) =>
      prev.includes(sport)
        ? prev.filter((s) => s !== sport) 
        : [...prev, sport]
    );
  };

  const handleMouseDown = (e) => {
    setIsDragging(true);
    setStartX(e.pageX - sliderRef.current.offsetLeft);
    setScrollLeft(sliderRef.current.scrollLeft);
  };

  const handleMouseMove = (e) => {
    if (!isDragging) return;
    e.preventDefault();
    const x = e.pageX - sliderRef.current.offsetLeft;
    const walk = (x - startX) * 2; 
    sliderRef.current.scrollLeft = scrollLeft - walk;
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  return (
      <Box
        ref={sliderRef}
        sx={{
          display: "flex",
          flexDirection: "row",
          cursor: "grab", 
          paddingY: "12px",
          gap: "15px",
          height: "60px",
          overflow: "hidden", 
        }}
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
        onMouseLeave={handleMouseUp} 
      >
        {sports.map((sport) => (
          <Chip
            key={sport.id}
            icon={getSportIcons[sport.id] || <SportsSoccerIcon />}
            label={sport.name}
            onClick={() => handleChipClick(sport.name)}
            sx={{
              backgroundColor: selectedSports.includes(sport.name) ? theme.palette.accent.dark : theme.palette.neutral.lighter,
              color: selectedSports.includes(sport.name) ? theme.palette.common.white : theme.palette.common.black,
              padding: {xs:"3px", md:"8px"},
              fontSize: {xs:"10px", md:"14px"},
              "& .MuiChip-icon": {
                color: selectedSports.includes(sport.name) ? theme.palette.common.white : theme.palette.accent.dark,
              },
            }}
          />
        ))}
      </Box>
  );
};

export default SportsFilter;
