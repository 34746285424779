import {
    Box,
    Card,
    Grid,
    InputLabel,
    TextField,
    FormControl,
    Select,
    MenuItem,
  } from "@mui/material";
  import * as Yup from "yup";
  import { useState, useEffect } from "react";
  import { useTranslation } from "react-i18next";
  import { Form, FormikProvider, useFormik } from "formik";
  import { UsersService, CommonService} from "../../../../api/services";
  import BaseSnackbar from "../../../../components/BaseSnackbar";
  import SaveIcon from "@mui/icons-material/Save";
  import { LoadingButton } from "@mui/lab";
  import { useTheme } from "@mui/material/styles";
  import { useNavigate } from "react-router-dom";
  
  export default function AddNewPartitionUser() {
    const { t } = useTranslation();
    const theme = useTheme();
    const navigate = useNavigate();
    const [message, setMessage] = useState("");
    const [openSnackbar, setSnackbarStatus] = useState(false);
    const [countries, setCountries] = useState([]);

        useEffect(() => {    
          const fetchCountries = async () => {
            try {
              const response = await CommonService.listCountries();
              setCountries(response.data || []);
            } catch (error) {
              console.error('Error fetching countries:', error);
              setMessage(t("failed-to-get-countries"));
              setSnackbarStatus(true);
            }
          };
    
          fetchCountries();
        }, []);
  
    const UserSchema = Yup.object().shape({
      first_name: Yup.string().required(t("first-name-is-required")),
      last_name: Yup.string().required(t("last-name-is-required")),
      email: Yup.string().email(t("email-is-invalid")).required(t("email-is-required")),
      telephone: Yup.string().required(t("telephone-is-required")),
      address1: Yup.string().required(t("address1-is-required")),
      country_id: Yup.string().required(t("country-id-is-required")),
    });
    const formik = useFormik({
      validationSchema: UserSchema,
      initialValues: {
        first_name: "",
        last_name: "",
        email: "",
        password: "",
        telephone: "",
        address1: "",
        address2: "",
        country_id: "",
      },
      onSubmit: (values, actions) => {
        const payload = {
          first_name: values.first_name,
          last_name: values.last_name,
          email: values.email,
          password : values.password,
          properties: {
            telephone: values.telephone,
            address1: values.address1,
            address2: values.address2,
            country_id: values.country_id,
          },
        };
    
        let successMessage = t("new-user-added-successfully");
        let failMessage = t("user-add-fail");
    
        UsersService.createPartitionUsers(payload)
          .then((response) => {
            if (response.status === 201 || response.status === 200) {
              setTimeout(() => {
                navigate("/partition-users/list-users", {
                  state: {
                    successMessage: successMessage,
                  },
                });
              }, 600);
    
              actions.setSubmitting(false);
            } else {
              throw new Error("failed to create user");
            }
          })
          .catch((err) => {
            console.error(err);
            setMessage(failMessage);
            setSnackbarStatus(true);
            setTimeout(() => {
              actions.setSubmitting(false);
            }, 1000);
          });
      },
    });
    
  
    const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;
  
    return (
      <>
        <BaseSnackbar
          open={openSnackbar}
          message={message}
          setOpen={setSnackbarStatus}
        />
  
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Card
              sx={{
                display: "flex",
                flexDirection: "column",
                padding: 5,
                gap: 4,
                mt: 5,
                mr:-5,
              }}
            >
              <Grid container spacing={10}>
                <Grid item xs={12} md={6} sx={{ display: "flex", flexDirection: "column" }}>
                  <InputLabel>{t("first-name")}<span style={{color:"red"}}>*</span></InputLabel>
                  <TextField
                    fullWidth
                    id="first_name"
                    label={t("first-name")}
                    placeholder={t("enter-first-name")}
                    {...getFieldProps("first_name")}
                    error={Boolean(touched.first_name && errors.first_name)}
                    helperText={touched.first_name && errors.first_name}
                  />
                </Grid>
                <Grid item xs={12} md={6} sx={{ display: "flex", flexDirection: "column" }}>
                  <InputLabel>{t("last-name")}<span style={{color:"red"}}>*</span></InputLabel>
                  <TextField
                    fullWidth
                    id="last_name"
                    label={t("last-name")}
                    placeholder={t("enter-last-name")}
                    {...getFieldProps("last_name")}
                    error={Boolean(touched.last_name && errors.last_name)}
                    helperText={touched.last_name && errors.last_name}
                  />
                </Grid>
                <Grid item xs={12} md={6} sx={{ display: "flex", flexDirection: "column" }}>
                  <InputLabel>{t("email")}<span style={{color:"red"}}>*</span></InputLabel>
                  <TextField
                    fullWidth
                    id="email"
                    label={t("email")}
                    placeholder={t("enter-email")}
                    {...getFieldProps("email")}
                    error={Boolean(touched.email && errors.email)}
                    helperText={touched.email && errors.email}
                  />
                </Grid>
                <Grid item xs={12} md={6} sx={{ display: "flex", flexDirection: "column" }}>
                  <InputLabel>{t("password")}<span style={{color:"red"}}>*</span></InputLabel>
                  <TextField
                    fullWidth
                    id="password"
                    type="password"
                    label={t("password")}
                    placeholder={t("enter-password")}
                    {...getFieldProps("password")}
                    error={Boolean(touched.password && errors.password)}
                    helperText={touched.password && errors.password}
                  />
                </Grid>
                <Grid item xs={12} md={6} sx={{ display: "flex", flexDirection: "column" }}>
                  <InputLabel>{t("telephone")}<span style={{color:"red"}}>*</span></InputLabel>
                  <TextField
                    fullWidth
                    id="telephone"
                    label={t("telephone")}
                    placeholder={t("enter-telephone")}
                    {...getFieldProps("telephone")}
                    error={Boolean(touched.telephone && errors.telephone)}
                    helperText={touched.telephone && errors.telephone}
                  />
                </Grid>
                <Grid item xs={12} md={6} sx={{ display: "flex", flexDirection: "column" }}>
                  <InputLabel>{t("address1")}<span style={{color:"red"}}>*</span></InputLabel>
                  <TextField
                    fullWidth
                    id="address1"
                    label={t("address1")}
                    placeholder={t("enter-address1")}
                    {...getFieldProps("address1")}
                    error={Boolean(touched.address1 && errors.address1)}
                    helperText={touched.address1 && errors.address1}
                  />
                </Grid>
                <Grid item xs={12} md={6} sx={{ display: "flex", flexDirection: "column" }}>
                  <InputLabel>{t("address2")}</InputLabel>
                  <TextField
                    fullWidth
                    id="address2"
                    label={t("address2")}
                    placeholder={t("enter-address2")}
                    {...getFieldProps("address2")}
                  />
                </Grid>
                <Grid item xs={12} md={6} sx={{ display: "flex", flexDirection: "column" }}>
                  <InputLabel sx={{mb:1}}>{t("country")}<span style={{color:"red"}}>*</span></InputLabel>
                  <FormControl fullWidth error={Boolean(touched.country_id && errors.country_id)}>
                    <Select
                      id="country_id"
                      {...getFieldProps("country_id")}
                      displayEmpty
                      placeholder={t("select-country")}
                    >
                      <MenuItem value="">
                        {t("select-country")}
                      </MenuItem>
                      {countries.map((country) => (
                        <MenuItem key={country.id} value={country.id}>
                          {country.name}
                        </MenuItem>
                      ))}
                    </Select>
                    {touched.country_id && errors.country_id && (
                      <Box component="span" sx={{ color: 'error.main', fontSize: '0.75rem', mt: 1 }}>
                        {errors.country_id}
                      </Box>
                    )}
                  </FormControl>
                </Grid>
              </Grid>
  
              <Box className="add-user-button" sx={{ width: "100%", display:"flex", alignItems:"center", justifyContent:"center" }}>
                <LoadingButton
                  sx={{ mt: 3, backgroundColor: theme.palette.accent.main }}
                  size="large"
                  type="submit"
                  variant="contained"
                  loading={isSubmitting}
                  startIcon={<SaveIcon />}
                >
                  {t("add-user")}
                </LoadingButton>
              </Box>
            </Card>
          </Form>
        </FormikProvider>
      </>
    );
  }
  