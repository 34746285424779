import { Box } from "@mui/material";
import PropTypes from "prop-types";
import { useStore } from "../store/Store";
// material

// ----------------------------------------------------------------------

Logo.propTypes = {
  sx: PropTypes.object,
};

export default function Logo({ sx }) {

  const [store] = useStore();

  const src =  store.services?.style?.logo ? `data:;base64,${store.services?.style?.logo}` : `${process.env.PUBLIC_URL}/assets/logo.png`;

  return (
    <>
      <Box component="img" src={src} sx={{ width: 40, height: 40, ...sx }}/>

    </>
  );
}
