import api from "../Api";
import urls from "../Urls";

export const listStore = (params) => {
    return api.get(urls.list_store, {params: params});
};

export const getStore = (id) => {
    return api.get(urls.get_store(id));
};

export const createStore = (payload) => {
    return api.post(urls.create_store, payload);
};

export const updateStore = (id, payload, params) => {
    return api.put(urls.update_store(id), payload, {params: params});
};

export const deleteStore = (id) => {
    return api.delete(urls.delete_store(id));
};
