import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Tooltip, IconButton, Typography, Box, Button } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useNavigate, useLocation } from "react-router-dom";
import { DefaultPaginationData } from "../../../constants";
import { rowArrayToObject } from "../../../utils/Util";
import BaseModal from "../../../components/BaseModal";
import BaseSnackbar from "../../../components/BaseSnackbar";
import { BaseTable, TableFilterContainer } from "../../../components/table";
import { KioskService } from "../../../api/services";
import SearchBar from "../../../components/SearchBar";
import AddNewButton from "../../../components/buttons/AddNewButton";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import EditKiosk from "./editKiosk/editKiosk";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

export default function ListKiosk() {
  const { t } = useTranslation();
  const theme = useTheme();
  const location = useLocation();
  const { successMessage } = location.state || {};
  const [openSnackbar, setSnackbarStatus] = useState(false);
  const [message, setMessage] = useState("");
  const [data, setData] = useState([]);
  const [paginationData, setPaginationData] = useState(DefaultPaginationData);
  const [loadingData, setLoadingData] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedRow, setSelectedRow] = useState({});
  const [openEditModal, setEditModalStatus] = useState(false);
  const [openDeleteModal, setDeleteModalStatus] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [keyVisibility, setKeyVisibility] = useState({});
  const [visibilityTimers, setVisibilityTimers] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    if (successMessage) {
      setSnackbarStatus(true);
      setMessage(successMessage);
    }
  }, [successMessage]);

  const toggleKeyVisibility = (id) => {
    if (visibilityTimers[id]) {
      clearTimeout(visibilityTimers[id]);
    }

    setKeyVisibility((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));

    if (!keyVisibility[id]) {
      const timer = setTimeout(() => {
        setKeyVisibility((prev) => ({
          ...prev,
          [id]: false,
        }));
      }, 1200);

      setVisibilityTimers((prev) => ({
        ...prev,
        [id]: timer,
      }));
    }
  };

  const TABLE_HEAD = [
    { key: "id", label: t("kiosk-id") },
    { key: "name", label: t("kiosk-name") },
    { key: "access_token", label: t("key") },
    { key: "action", label: t("actions"), align: "right" },
  ];

  const TABLE_FIELD_MAPPING = {
    id: { key: "id", cellComponentType: "th", index: 0 },
    name: { key: "name", index: 1 },
    access_token: { key: "access_token", index: 2 },
  };

  const fetchKiosks = () => {
    setLoadingData(true);
    const params = {
      page: paginationData.page + 1,
      size: paginationData.rowsPerPage,
    };

    KioskService.listKiosk(params)
      .then((response) => {
        const items = response.data.items.map((itemData) => {
          const item = new Array(TABLE_HEAD.length - 1).fill({});
          item[TABLE_FIELD_MAPPING["id"].index] = {
            ...TABLE_FIELD_MAPPING["id"],
            value: itemData.id,
          };
          item[TABLE_FIELD_MAPPING["name"].index] = {
            ...TABLE_FIELD_MAPPING["name"],
            value: itemData.name,
          };
          item[TABLE_FIELD_MAPPING["access_token"].index] = {
            ...TABLE_FIELD_MAPPING["access_token"],
            value: itemData.access_token 
          };
          return item;
        });
        setData(items);
        setTotalCount(response.data.total);
      })
      .catch((err) => {
        setMessage(t("failed-to-get-kiosks"));
        setSnackbarStatus(true);
        console.error(err);
      })
      .finally(() => {
        setLoadingData(false);
      });
  };

  useEffect(() => {
    fetchKiosks();
  }, [paginationData]);

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const filterData = (data, query) => {
    if (!query) return data;
    return data.filter((row) => {
      return ["name"].some((key) => {
        const cellValue = row[TABLE_FIELD_MAPPING[key]?.index]?.value || "";
        return cellValue.toString().toLowerCase().includes(query.toLowerCase());
      });
    });
  };

  const filteredData = filterData(data, searchQuery);

  const modalHandler = (modalType, index = undefined) => {
    switch (modalType) {
      case "edit":
        setEditModalStatus(!openEditModal);
        break;
      case "delete":
        setDeleteModalStatus(!openDeleteModal);
        break;
      default:
        break;
    }

    if (index !== undefined) {
      const row = rowArrayToObject(data[index]);
      setSelectedRow(row);
    }
  };

  const handleDeleteKiosk = () => {
    const kioskId =
      selectedRow.id || selectedRow[TABLE_FIELD_MAPPING["id"].index]?.value;
      console.log("Kiosk ID being deleted:", kioskId);
    if (kioskId) {
      KioskService.deleteKiosk(kioskId)
        .then(() => {
          setMessage(t("kiosk-deleted-successfully"));
          setSnackbarStatus(true);
          fetchKiosks();
          setDeleteModalStatus(false);
        })
        .catch((err) => {
          setMessage(t("failed-to-delete-kiosk"));
          setSnackbarStatus(true);
          console.error(err);
        });
    }
  };

  const renderAccessToken = (row) => {
    const accessToken = row[TABLE_FIELD_MAPPING["access_token"].index]?.value;
    const rowId = row[TABLE_FIELD_MAPPING["id"].index]?.value;
    const isVisible = keyVisibility[rowId];

    const handleCopy = () => {
      if (isVisible) {
        navigator.clipboard.writeText(accessToken).then(() => {
          setSnackbarStatus(true);
          setMessage(t("Key copied to clipboard"));
        });
      }
    };
  
    return (
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Typography
          sx={{
            marginRight: 1,
            cursor: isVisible ? "pointer" : "default",
            color: isVisible ? theme.palette.primary.main : "inherit",
          }}
          onClick={handleCopy}
        >
          {isVisible ? accessToken : "••••••••"}
        </Typography>
        <IconButton onClick={() => toggleKeyVisibility(rowId)}>
          {isVisible ? <VisibilityOffIcon /> : <VisibilityIcon />}
        </IconButton>
      </Box>
    );
  };

  useEffect(() => {
    return () => {
      Object.values(visibilityTimers).forEach(clearTimeout);
    };
  }, [visibilityTimers]);

  const getActionItems = (index) => (
    <Box sx={{ minWidth: "80px" }}>
      <Tooltip title={t("edit-kiosk")}>
        <IconButton
          sx={{ color: theme.palette.neutral }}
          size="small"
          onClick={() => modalHandler("edit", index)}
        >
          <EditIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title={t("delete-kiosk")}>
        <IconButton
          color="secondary"
          size="small"
          onClick={() => modalHandler("delete", index)}
        >
          <DeleteIcon />
        </IconButton>
      </Tooltip>
    </Box>
  );

  return (
    <>
      <BaseSnackbar
        open={openSnackbar}
        message={message}
        setOpen={setSnackbarStatus}
      />
       <BaseModal
        title={t("edit-kiosk") + " - " + selectedRow.name}
        sx={{width: {xs:"80%", md:"20%"}}}
        open={openEditModal}
        setOpen={setEditModalStatus}
        children={
          <EditKiosk
            successCallback={fetchKiosks}
            formData={selectedRow}
            setModalStatus={setEditModalStatus}
            setSnackbarStatus={setSnackbarStatus}
            setMessage={setMessage}
          />
        }
      />
      <BaseModal
        title={t("delete-kiosk")}
        open={openDeleteModal}
        setOpen={setDeleteModalStatus}
        sx={{width: {xs:"80%", md:"20%"}}}
      >
        <Box>
          <Typography>{t("delete-this-item")}</Typography>
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              onClick={() => setDeleteModalStatus(false)}
              sx={{ marginRight: 2 }}
            >
              {t("cancel")}
            </Button>
            <Button color="secondary" onClick={handleDeleteKiosk}>
              {t("confirm")}
            </Button>
          </Box>
        </Box>
      </BaseModal>
      <TableFilterContainer
        sx={{
          marginTop: "2vh",
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          alignItems: "center",
          justifyContent: "space-between",
          px: 2,
        }}
       >
        <SearchBar
          searchQuery={searchQuery}
          handleSearchChange={handleSearchChange}
        />
        <AddNewButton
          label={t("add-new-kiosk")}
          onClick={() => navigate("/st/add-new-kiosk")}
        />
      </TableFilterContainer>
      <BaseTable
        head={TABLE_HEAD}
        data={filteredData.map((row) => {
          const newRow = [...row];
          newRow[TABLE_FIELD_MAPPING["access_token"].index] = {
            ...newRow[TABLE_FIELD_MAPPING["access_token"].index],
            value: renderAccessToken(row),
          };
          return newRow;
        })}
        actionItemPrep={getActionItems}
        pagination={{
          paginationData: { ...paginationData, totalCount: totalCount },
          setPaginationData: setPaginationData,
        }}
        loadingData={loadingData}
      />
    </>
  );
}
