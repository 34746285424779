import React, { useState, useEffect } from 'react'
import SubHeader from '../../layouts/betLayouts/tipoLayout/SubHeader'
import RecommendedMatches from '../../layouts/betLayouts/tipoLayout/RecommendedMatches'
import BottomNavigationBar from '../../layouts/betLayouts/tipoLayout/BottomNavigationBar'
import BestMatches from '../../layouts/betLayouts/tipoLayout/BestMatches'
import TicketModal from '../../layouts/betLayouts/tipoLayout/TicketModal'
import { useStore } from '../../store/Store'

export default function TipoLandingPage() {
  const [store, dispatch] = useStore(); 
  const [isTicketModalOpen, setIsTicketModalOpen] = useState(false);
  const [selectedBets, setSelectedBets] = useState([]);


  const handleChangeSelectedBets = (bet) => {
    const isAlreadySelected = store.selected_bets.some((item) => item.odd_id === bet.odd_id);
  
    let notSelectable = store.selected_bets.some((item) => item.betType === bet.betType && item.fixture_id === bet.fixture_id && item.odd_id !== bet.odd_id);

    if (!notSelectable){
  
      dispatch({
        type: "UPDATE_SELECTED_BETS",
        payload: {
          selected_bets: isAlreadySelected
            ? store.selected_bets.filter((item) => item.odd_id !== bet.odd_id)
            : [...store.selected_bets, bet],
        },
      });
    }
  };


  useEffect(() => {
    setSelectedBets(store.selected_bets);
  }, [store.selected_bets]);


  const handleRemoveBet = () => {
    
  };

  return (
    <>
      <SubHeader/>
      <RecommendedMatches/>
      <BestMatches 
        handleChangeSelectedBets={handleChangeSelectedBets} 
      />
      <TicketModal 
        open={isTicketModalOpen}
        onClose={() => setIsTicketModalOpen(false)}
        handleChangeSelectedBets={handleChangeSelectedBets} 

      />
      <BottomNavigationBar 
        onTicketClick={() => setIsTicketModalOpen(true)}
        ticketCount={store.selected_bets.length}
      />
    </>
  )
}
