import React, { useState } from "react";
import {
  Box,
  Card,
  Grid,
  InputLabel,
  TextField,
} from "@mui/material";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { Form, FormikProvider, useFormik } from "formik";
import { KioskService } from "../../../../api/services";
import BaseSnackbar from "../../../../components/BaseSnackbar";
import SaveIcon from "@mui/icons-material/Save";
import { LoadingButton } from "@mui/lab";
import { useTheme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";

export default function AddKiosk() {
  const { t } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();
  const [message, setMessage] = useState("");
  const [openSnackbar, setSnackbarStatus] = useState(false);

  const KioskSchema = Yup.object().shape({
    kioskName: Yup.string()
      .required(t("kiosk-name-is-required"))
      .matches(/^[^?"'!@#%<>;]*$/, t("name-invalid-characters"))
      .test(
        "has-more-letters-than-commas-and-periods",
        t("name-must-have-more-letters-than-commas-and-periods"),
        (value) => {
          if (!value) {
            return true;
          }
          const lettersCount = (value.match(/[a-zA-Z]/g) || []).length;
          const specialCharsCount = (value.match(/[,./]/g) || []).length;
          return lettersCount > specialCharsCount;
        }
      ),
  });

  const formik = useFormik({
    validationSchema: KioskSchema,
    initialValues: {
      kioskName: "",
    },
    onSubmit: (values, actions) => {
      const payload = {
        name: values.kioskName,
      };

      let successMessage = t("kiosk-added-successfully");
      let failMessage = t("kiosk-add-fail");

      KioskService.createKiosk(payload)
        .then((response) => {
          if (response.status === 201 || response.status === 200) {
            setTimeout(() => {
              navigate("/st/manage-kiosk", {
                state: {
                  successMessage: successMessage,
                },
              });
            }, 200);

            actions.setSubmitting(false);
          } else {
            throw new Error("Failed to create kiosk");
          }
        })
        .catch((err) => {
          console.error(err);
          setMessage(failMessage);
          setSnackbarStatus(true);
          setTimeout(() => {
            actions.setSubmitting(false);
          }, 1000);
        });
    },
  });

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

  return (
    <>
      <BaseSnackbar
        open={openSnackbar}
        message={message}
        setOpen={setSnackbarStatus}
      />

      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Card
            sx={{
              display: "flex",
              flexDirection: "column",
              padding: 5,
              gap: 4,
              mt: 5,
              mr:-5,
            }}
          >
            <Grid container spacing={10}>
              <Grid
                item
                xs={12}
                md={12}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <InputLabel>
                  {t("kiosk-name")}<span style={{ color: "red" }}>*</span>
                </InputLabel>
                <TextField
                  fullWidth
                  id="kioskName"
                  label={t("kiosk-name")}
                  placeholder={t("enter-kiosk-name")}
                  {...getFieldProps("kioskName")}
                  error={Boolean(touched.kioskName && errors.kioskName)}
                  helperText={touched.kioskName && errors.kioskName}
                />
              </Grid>
            </Grid>

            <Box
              className="add-kiosk-button"
              sx={{width: "100%", display:"flex", alignItems:"center", justifyContent:"center"}}>
              <LoadingButton
                sx={{ mt: 3, backgroundColor: theme.palette.accent.main }}
                size="large"
                type="submit"
                variant="contained"
                loading={isSubmitting}
                startIcon={<SaveIcon />}
              >
                {t("add-kiosk")}
              </LoadingButton>
            </Box>
          </Card>
        </Form>
      </FormikProvider>
    </>
  );
}
