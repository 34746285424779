import React, { useState, useEffect } from 'react';
import { 
  Box, 
  Typography, 
  Tabs, 
  Tab, 
  IconButton,
  CircularProgress,
  Pagination,
  Stack 
} from '@mui/material';
import { OddsService, OnlineOddsService } from '../../../api/services';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useNavigate } from 'react-router-dom';
import { useStore } from '../../../store/Store';
import { useTranslation } from 'react-i18next';
import { styled, useTheme } from '@mui/material/styles';
import { convertUTCToLocalTime } from '../../../utils/Util';

const sports = [
  'Football', 'Basketball', 'Ice Hockey', 'Tennis', 'Handball',
  'Boxing', 'American Football', 'Volleyball'
];

const MatchContainer = styled(Box)({
  borderBottom: '1px solid #eee',
  padding: '8px 16px',
});

const OddBox = styled(Box)(({ theme, selected }) => ({
  flex: 1,
  backgroundColor: selected ? theme.palette.accent.darker : theme.palette.grey[200],
  color: selected ? theme.palette.common.white : theme.palette.common.black,
  padding: '8px 0',
  textAlign: 'center',
  cursor: 'pointer',
  borderRadius: '4px',
}));

export default function BestPrematches({ handleChangeSelectedBets }) {
  const [selectedSport, setSelectedSport] = useState(0);
  const [matches, setMatches] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [pageSize] = useState(10);
  const navigate = useNavigate();
  const [store, dispatch] = useStore();
  const theme = useTheme();
  const { t } = useTranslation();
  
  useEffect(() => {
    const fetchMatches = async () => {
      setLoading(true);
      let currentDate = new Date();
      let start_date = currentDate.toISOString();
      let end_date = new Date(
        currentDate.getTime() + 24 * 60 * 60 * 1000
      ).toISOString();
      try {
        const params = {
          start_date,
          end_date,
          page,
          size: pageSize
        }

        let apiService = store.userType === 'kiosk' 
          ? OddsService.listGeneralFootballOdds(params) 
          : OnlineOddsService.listOnlineFootballOdds(params);
      
        const response = await apiService;
        setMatches(response.data.items || []);
        setTotalPages(Math.ceil((response.data.total || 0) / pageSize));
      } catch (error) {
        console.error('Error fetching matches:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchMatches();
  }, [store.userType, page, pageSize]);

  const handleMatchClick = (fixture_id) => {
    store.userType === 'kiosk' ? navigate(`/ki/odds-detail/${fixture_id}`) : navigate(`/dt/${store.hashCode}/on/odds-detail/${fixture_id}`);
  };

  const handleOddClick = (match, value) => {

    const ticketItem = {
      fixture_id: match.fixture.fixture_id,
      odd_id: value.odd_id,
      homeTeam: match.fixture.home_team,
      awayTeam: match.fixture.away_team,
      date: match.fixture.date,
      time: match.fixture.time,
      odd: value.odd,
      value: value.value,
      betType: "Match Result" + ' - ' + match.bets.name
    };
    
    handleChangeSelectedBets(ticketItem);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  return (
    <Box sx={{ width: '100%', bgcolor: theme.palette.common.white, mb: 15, mt: 3 }}>
      <Typography variant="h6" sx={{ p: 2, fontWeight: 'bold' }}>
        {t("best-prematches")}
      </Typography>
      
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs 
          value={selectedSport}
          onChange={(e, newValue) => setSelectedSport(newValue)}
          variant="scrollable"
          scrollButtons="auto"
          sx={{
            '& .MuiTab-root': {
              textTransform: 'none',
              minWidth: 'auto',
              px: 2,
              color: theme.palette.grey[500]
            },
            '& .Mui-selected': {
              color: theme.palette.grey[800]
            },
            '& .MuiTabs-indicator': {
              backgroundColor: theme.palette.error.darkest
            }
          }}
        >
          {sports.map((sport, index) => (
            <Tab key={sport} label={sport} />
          ))}
        </Tabs>
      </Box>

      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          {matches.map((match) => (
            <MatchContainer 
              key={match.fixture.fixture_id}
              onClick={() => handleMatchClick(match.fixture.fixture_id)}
              sx={{ cursor: 'pointer'}}
            >
              <Box sx={{ 
                display: 'flex', 
                flexDirection: { xs: 'column', md: 'row' }, 
                gap: 2 
              }}>
                <Box sx={{ 
                  display: 'flex',
                  width: { xs: '100%', md: '30%' },
                  gap: 2
                }}>
                  <Box sx={{ width: '40%' }}>
                    <Typography variant="subtitle2" color="text.secondary">
                      {convertUTCToLocalTime(match.fixture.date, match.fixture.time, store.language).toLocaleString(store.language, { 
                        month: 'numeric', 
                        day: 'numeric', 
                        year: 'numeric', 
                        hour: 'numeric', 
                        minute: 'numeric', 
                        hour12: true 
                      })}
                    </Typography>
                  </Box>

                  <Box sx={{ width: '60%' }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 0.5 }}>
                      <img 
                        src={match.fixture.home_team_logo} 
                        alt="" 
                        style={{ width: 22, height: 22 }}
                      />
                      <Typography variant="body2">
                        {match.fixture.home_team}
                      </Typography>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      <img 
                        src={match.fixture.away_team_logo} 
                        alt="" 
                        style={{ width: 22, height: 22 }}
                      />
                      <Typography variant="body2">
                        {match.fixture.away_team}
                      </Typography>
                    </Box>
                  </Box>
                </Box>

                <Box sx={{ 
                  width: { xs: '100%', md: '70%' }, 
                  display: 'flex', 
                  gap: 1, 
                  alignItems: 'center' 
                }}>
                  {match.bets?.values.map((value, index) => (
                    <OddBox 
                      key={value.odd_id}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleOddClick(match, value);
                      }}
                      selected={store.selected_bets.some((item) => item.odd_id === value.odd_id)}
                    >
                      <Typography variant="body2">
                        {value.value}
                      </Typography>
                      <Typography variant="body2" fontWeight="bold">
                        {value.odd}
                      </Typography>
                    </OddBox>
                  ))}
                  <IconButton 
                    edge="end"
                    onClick={(e) => {
                      e.stopPropagation(); 
                      handleMatchClick(match.fixture.fixture_id);
                    }}
                  >
                    <ChevronRightIcon />
                  </IconButton>
                </Box>
              </Box>
            </MatchContainer>
          ))}

          {totalPages > 1 && (
            <Stack spacing={2} sx={{ mt: 4, mb: 4, alignItems: 'center' }}>
              <Pagination
                count={totalPages}
                page={page}
                onChange={handlePageChange}
                color="primary"
                size="large"
                sx={{
                  '& .MuiPaginationItem-root': {
                    color: theme.palette.grey[700]
                  },
                  '& .Mui-selected': {
                    backgroundColor: theme.palette.error.darkest,
                    color: theme.palette.common.white,
                    '&:hover': {
                      backgroundColor: theme.palette.error.main
                    }
                  }
                }}
              />
            </Stack>
          )}
        </>
      )}
    </Box>
  );
}