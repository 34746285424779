import React, { useState, useEffect } from "react";
import { Box, Typography, IconButton } from "@mui/material";
import { OddsService, OnlineOddsService } from "../../../api/services";
import { useNavigate } from "react-router-dom";
import { useStore } from "../../../store/Store";
import { useTranslation } from "react-i18next";
import { styled, useTheme } from "@mui/material/styles";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import BottomNavigationBar from "./BottomNavigationBar";
import TicketModal from "./TicketModal";
import { convertUTCToLocalTime } from "../../../utils/Util";

const MatchContainer = styled(Box)({
  borderBottom: "1px solid #eee",
  padding: "8px 16px",
  cursor: "pointer",
});

const OddBox = styled(Box)(({ theme, selected }) => ({
  flex: 1,
  backgroundColor: selected
    ? theme.palette.accent.darker
    : theme.palette.grey[200],
  color: selected ? theme.palette.common.white : theme.palette.common.black,
  padding: "8px 0",
  textAlign: "center",
  cursor: "pointer",
  borderRadius: "4px",
}));

export default function TodayOdds() {
  const [matches, setMatches] = useState([]);
  const navigate = useNavigate();
  const [store, dispatch] = useStore();
  const theme = useTheme();
  const { t } = useTranslation();
  const [isTicketModalOpen, setIsTicketModalOpen] = useState(false);

  useEffect(() => {
    const fetchMatches = async () => {
      let currentDate = new Date();
      let start_date = currentDate.toISOString();
      let end_date = currentDate.toISOString().split("T")[0] + "T23:59:59Z";
      try {
        const params = {
          start_date,
          end_date,
        };

        let apiService =
          store.userType === "kiosk"
            ? OddsService.listGeneralFootballOdds(params)
            : OnlineOddsService.listOnlineFootballOdds(params);

        const response = await apiService;
        setMatches(response.data.items || []);
      } catch (error) {
        console.error("Error fetching matches:", error);
      }
    };

    fetchMatches();
  }, [store.userType]);

  const handleMatchClick = (fixture_id) => {
    store.userType === "kiosk"
      ? navigate(`/ki/odds-detail/${fixture_id}`)
      : navigate(`/dt/${store.hashCode}/on/odds-detail/${fixture_id}`);
  };

  const handleChangeSelectedBets = (bet) => {
    const isAlreadySelected = store.selected_bets.some(
      (item) => item.odd_id === bet.odd_id
    );

    let notSelectable = store.selected_bets.some(
      (item) =>
        item.betType === bet.betType &&
        item.fixture_id === bet.fixture_id &&
        item.odd_id !== bet.odd_id
    );

    if (!notSelectable) {
      dispatch({
        type: "UPDATE_SELECTED_BETS",
        payload: {
          selected_bets: isAlreadySelected
            ? store.selected_bets.filter((item) => item.odd_id !== bet.odd_id)
            : [...store.selected_bets, bet],
        },
      });
    }
  };

  const handleOddClick = (match, value) => {
    const ticketItem = {
      fixture_id: match.fixture.fixture_id,
      odd_id: value.odd_id,
      homeTeam: match.fixture.home_team,
      awayTeam: match.fixture.away_team,
      date: match.fixture.date,
      time: match.fixture.time,
      odd: value.odd,
      value: value.value,
      betType: "Match Result" + " - " + match.bets.name,
    };

    handleChangeSelectedBets(ticketItem);
  };

  return (
    <>
      <Box
        sx={{
          width: "100%",
          bgcolor: theme.palette.common.white,
          mb: 15,
          mt: 3,
        }}
      >
        <Typography variant="h6" sx={{ p: 2, fontWeight: "bold" }}>
          {t("todays-matches")}
        </Typography>
        {matches.map(
          (match) =>
            match.bets?.values &&
            match.bets.values.length > 0 && (
              <MatchContainer key={match.fixture.fixture_id}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: { xs: "column", md: "row" },
                    gap: 2,
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      width: { xs: "100%", md: "30%" },
                      gap: 2,
                    }}
                  >
                    <Box sx={{ width: "40%" }}>
                      <Typography variant="subtitle2" color="text.secondary">
                        {convertUTCToLocalTime(
                          match.fixture.date,
                          match.fixture.time,
                          store.language
                        ).toLocaleString(store.language, {
                          month: "numeric",
                          day: "numeric",
                          year: "numeric",
                          hour: "numeric",
                          minute: "numeric",
                          hour12: true,
                        })}
                      </Typography>
                    </Box>

                    <Box sx={{ width: "60%" }}>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: 1,
                          mb: 0.5,
                        }}
                      >
                        <img
                          src={match.fixture.home_team_logo}
                          alt=""
                          style={{ width: 22, height: 22 }}
                          onError={(e) => {
                            e.target.src = "/fallback-team-logo.png";
                          }}
                        />
                        <Typography variant="body2">
                          {match.fixture.home_team}
                        </Typography>
                      </Box>
                      <Box
                        sx={{ display: "flex", alignItems: "center", gap: 1 }}
                      >
                        <img
                          src={match.fixture.away_team_logo}
                          alt=""
                          style={{ width: 22, height: 22 }}
                          onError={(e) => {
                            e.target.src = "/fallback-team-logo.png";
                          }}
                        />
                        <Typography variant="body2">
                          {match.fixture.away_team}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>

                  <Box
                    sx={{
                      width: { xs: "100%", md: "70%" },
                      display: "flex",
                      gap: 1,
                      alignItems: "center",
                    }}
                  >
                    {match.bets?.values.map((value) => (
                      <OddBox
                        key={value.odd_id}
                        onClick={(e) => {
                          e.stopPropagation();
                          handleOddClick(match, value);
                        }}
                        selected={store.selected_bets.some(
                          (item) => item.odd_id === value.odd_id
                        )}
                      >
                        <Typography variant="body2">{value.value}</Typography>
                        <Typography variant="body2" fontWeight="bold">
                          {value.odd}
                        </Typography>
                      </OddBox>
                    ))}
                    <IconButton
                      edge="end"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleMatchClick(match.fixture.fixture_id);
                      }}
                    >
                      <ChevronRightIcon />
                    </IconButton>
                  </Box>
                </Box>
              </MatchContainer>
            )
        )}
      </Box>

      <TicketModal
        open={isTicketModalOpen}
        onClose={() => setIsTicketModalOpen(false)}
        handleChangeSelectedBets={handleChangeSelectedBets}
      />
      <BottomNavigationBar
        onTicketClick={() => setIsTicketModalOpen(true)}
        ticketCount={store.selected_bets.length}
      />
    </>
  );
}
