import { useState, useEffect } from "react";
import { styled } from "@mui/system";
import {
  Grid,
  FormControl,
  TextField,
  Stack,
  Tooltip,
  IconButton,
  Paper,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Box,
  Button,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useStore } from "../../../store/Store";
import { TabGroup, TabPanel } from "../../../components/navTabs";
import { BaseTable } from "../../../components/table";
import BaseSnackbar from "../../../components/BaseSnackbar";
import BaseModal from "../../../components/BaseModal";
import FileUpload from "../../../components/FileUpload";
import { DefaultPaginationData } from "../../../constants";
import { SiteConfigService } from "../../../api/services";
import tinycolor from "tinycolor2";
import { setFavIcon, camelize } from "../../../utils/Util";
import { useTranslation } from "react-i18next";
import DeleteThemeForm from "./forms/DeleteThemeForm";
import palette from "../../../theme/palette";

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  backgroundColor: theme.palette.common.white,
  [theme.breakpoints.down("sm")]: {
    padding: theme.spacing(2),
  },
}));

const GridItem = styled(Grid)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
}));

export default function Branding() {
  const { t } = useTranslation();
  const SAMPLE_TABLE_HEAD = [
    { key: "id", label: "ID", align: "center" },
    { key: "h2", label: t("header-1"), align: "center" },
    { key: "h3", label: t("header-2"), align: "center" },
    { key: "h4", label: t("header-3"), align: "center" },
    { key: "action", label: t("actions") },
  ];

  const SAMPLE_TABLE_DATA = [
    [
      { key: "id", index: 0, value: "1", align: "center", componentType: "th" },
      { key: "h2", index: 1, value: t("cell-1-2"), align: "center" },
      { key: "h3", index: 2, value: t("cell-1-3"), align: "center" },
      { key: "h4", index: 3, value: t("cell-1-4"), align: "center" },
    ],
    [
      { key: "id", index: 0, value: "2", align: "center", componentType: "th" },
      { key: "h2", index: 1, value: t("cell-2-2"), align: "center" },
      { key: "h3", index: 2, value: t("cell-2-3"), align: "center" },
      { key: "h4", index: 3, value: t("cell-2-4"), align: "center" },
    ],
    [
      { key: "id", index: 0, value: "3", align: "center", componentType: "th" },
      { key: "h2", index: 1, value: t("cell-3-2"), align: "center" },
      { key: "h3", index: 2, value: t("cell-3-3"), align: "center" },
      { key: "h4", index: 3, value: t("cell-3-4"), align: "center" },
    ],
    [
      { key: "id", index: 0, value: "4", align: "center", componentType: "th" },
      { key: "h2", index: 1, value: t("cell-4-2"), align: "center" },
      { key: "h3", index: 2, value: t("cell-4-3"), align: "center" },
      { key: "h4", index: 3, value: t("cell-4-4"), align: "center" },
    ],
    [
      { key: "id", index: 0, value: "5", align: "center", componentType: "th" },
      { key: "h2", index: 1, value: t("cell-5-2"), align: "center" },
      { key: "h3", index: 2, value: t("cell-5-3"), align: "center" },
      { key: "h4", index: 3, value: t("cell-5-4"), align: "center" },
    ],
    [
      { key: "id", index: 0, value: "6", align: "center", componentType: "th" },
      { key: "h2", index: 1, value: t("cell-6-2"), align: "center" },
      { key: "h3", index: 2, value: t("cell-6-3"), align: "center" },
      { key: "h4", index: 3, value: t("cell-6-4"), align: "center" },
    ],
  ];

  const COLOR_FIELDS = [
    { key: "lightmost", label: t("light-most"), shade: 50, type: "light" },
    { key: "lighter", label: t("lighter"), shade: 30, type: "light" },
    { key: "light", label: t("light"), shade: 10, type: "light" },
    { key: "main", label: t("main") },
    { key: "dark", label: t("dark"), shade: 10, type: "dark" },
    { key: "darker", label: t("darker"), shade: 30, type: "dark" },
    { key: "darkmost", label: t("dark-most"), shade: 40, type: "dark" },
    { key: "contrastText", label: t("text-on") },
  ];
  const defaultPalette = { ...palette };

  const [store, dispatch] = useStore();
  const [message, setMessage] = useState("");
  const [openSnackbar, setSnackbarStatus] = useState(false);
  const [loadingFakeData, setLoadingFakeData] = useState(false);
  const [loadingSave, setLoadingSave] = useState(false);
  const [loadingSaveAs, setLoadingSaveAs] = useState(false);
  const [loadingLogo, setLoadingLogo] = useState(false);
  const [loadingFavicon, setLoadingFavicon] = useState(false);
  const [loadingLandingImage, setLoadingLandingImage] = useState(false);

  const [openModal, setOpenModal] = useState(null);
  const [usedPalette, setUsedPalette] = useState({...store.palette});
  const [palettes, setPalettes] = useState({
    ...store.services.style.palettes,
  });
  const [selectedPalette, setSelectedPalette] = useState(
    store.services.style.selectedPalette
  );
  const [newThemeName, setNewThemeName] = useState("");
  const [themeNames, setThemeNames] = useState([]);
  const [openDeleteModalStatus, setDeleteModalStatus] = useState(false);

  const [tabValue, setTabValue] = useState(0);

  const handleTabChange = (event, newTabValue) => {
    setTabValue(newTabValue);
  };


  useEffect(() => {
    setSelectedPalette(store.services.style.selectedPalette);
  }, [store.services.style.selectedPalette]);

  useEffect(() => {
    setPalettes({...store.services.style.palettes})
    setUsedPalette({...store.palette})
  }, [store.services.style.palettes])
  
  const getActionItems = (index) => {
    return (
      <Stack direction="row" spacing={2}>
        <Tooltip title={t("edit")}>
          <IconButton color="secondary" size="small" aria-label="edit">
            <EditIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title={t("delete")}>
          <IconButton color="secondary" size="small" aria-label="delete">
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      </Stack>
    );
  };

  const fakeFetchData = () => {
    setLoadingFakeData(true);
    setTimeout(() => {
      // wait for skeleton
      setLoadingFakeData(false);
    }, 2000);
  };

  const tableComponent = (
    <>
      <BaseTable
        head={SAMPLE_TABLE_HEAD}
        data={[...SAMPLE_TABLE_DATA]}
        actionItemPrep={getActionItems}
        pagination={{
          paginationData: { ...DefaultPaginationData, totalCount: 100 },
          setPaginationData: () => DefaultPaginationData,
        }}
        loadingData={loadingFakeData}
      />
      <Box sx={{ textAlign: "right" }}>
        <Button variant="contained" color="primary" onClick={fakeFetchData}>
          {t("fetch-data")}
        </Button>
      </Box>
    </>
  );

  const inputComponents = (
    <>
      <FormControl fullWidth>
        <TextField fullWidth label={t("text-field")} />
        <FormControl fullWidth sx={{ mt: 1 }}></FormControl>
        <TextField fullWidth type="number" label={t("number-field")} />
      </FormControl>
      <FormControl fullWidth sx={{ mt: 2 }}>
        <InputLabel id="select-field">{t("select-field")}</InputLabel>
        <Select
          label={t("select-field-0")}
          labelId="select-field"
          color="secondary"
        >
          <MenuItem value={0}>{t("option-1")}</MenuItem>
          <MenuItem value={1}>{t("option-2")}</MenuItem>
          <MenuItem value={2}>{t("option-3")}</MenuItem>
          <MenuItem value={3}>{t("option-4")}</MenuItem>
        </Select>
      </FormControl>
      <Box sx={{ ml: 1, mt: 1 }}>
        <InputLabel>{t("checkboxes")}</InputLabel>
        <Checkbox defaultChecked />
        <Checkbox />
        <Checkbox disabled />
        <Checkbox disabled checked />
      </Box>
      <FormControl sx={{ ml: 1, mt: 1 }}>
        <FormLabel>{t("radio-buttons")}</FormLabel>
        <RadioGroup defaultValue="0" row sx={{ ml: 1.5 }}>
          <FormControlLabel
            color="secondary"
            value="0"
            control={<Radio />}
            label={t("option-1")}
          />
          <FormControlLabel
            color="secondary"
            value="1"
            control={<Radio />}
            label={t("option-2")}
          />
          <FormControlLabel
            color="secondary"
            value="2"
            control={<Radio />}
            label={t("option-3")}
          />
          <FormControlLabel
            color="secondary"
            value="3"
            disabled
            control={<Radio />}
            label={t("option-4")}
          />
        </RadioGroup>
      </FormControl>
      <Stack
        direction="row"
        spacing={2}
        alignItems="center"
        sx={{ ml: 1, mt: 1 }}
      >
        <Button variant="contained" color="neutral">
          {t("neutral")}
        </Button>
        <Button variant="contained" color="primary">
          {t("primary")}
        </Button>
        <Button variant="contained" color="secondary">
          {t("secondary")}
        </Button>
        <Button variant="contained" color="accent">
          {t("accent")}
        </Button>
        <Button variant="contained" color="success">
          {t("success")}
        </Button>
        <Button variant="contained" color="warning">
          {t("warning")}
        </Button>
        <Button variant="contained" color="error">
          {t("error")}
        </Button>

      </Stack>
      <Stack
        direction="row"
        spacing={2}
        alignItems="center"
        sx={{ ml: 1, mt: 1 }}
      >
        <Button color="neutral">{t("neutral")}</Button>
        <Button color="primary">{t("primary")}</Button>
        <Button color="secondary">{t("secondary")}</Button>
        <Button color="accent">{t("accent")}</Button>
        <Button color="success">
          {t("success")}
        </Button>
        <Button color="warning">{t("warning")}</Button>
        <Button color="error">{t("error")}</Button>
      </Stack>
      <Stack
        direction="row"
        spacing={2}
        alignItems="center"
        sx={{ ml: 1, mt: 1 }}
      >
         <Button variant="outlined" color="neutral">
          {t("neutral")}
        </Button>
        <Button variant="outlined" color="primary">
          {t("primary")}
        </Button>
        <Button variant="outlined" color="secondary">
          {t("secondary")}
        </Button>
        <Button variant="outlined" color="accent">
          {t("accent")}
        </Button>
        <Button variant="outlined" color="success">
          {t("success")}
        </Button>
        <Button variant="outlined" color="warning">
          {t("warning")}
        </Button>
        <Button variant="outlined" color="error">
          {t("error")}
        </Button>
      </Stack>
    </>
  );

  const sampleTabs = [
    { id: 0, label: t("table"), component: tableComponent },
    { id: 1, label: t("inputs"), component: inputComponents },
  ];

  const colorFields = [
    { name: "neutral", label: t("neutral-colors"), colors: usedPalette.neutral },
    { name: "primary", label: t("primary-colors"), colors: usedPalette.primary },
    {
      name: "secondary",
      label: t("secondary-colors"),
      colors: usedPalette.secondary,
    },
    { name: "accent", label: t("accent-colors"), colors: usedPalette.accent },
    { name: "success", label: t("success-colors"), colors: usedPalette.success },
    { name: "error", label: t("error-colors"), colors: usedPalette.error },
    { name: "warning", label: t("warning-colors"), colors: usedPalette.warning },
    { name: "background", label: t("background"), colors: usedPalette.background },
  ];

  /*   useEffect(() => {
    updateColorPalette();
  }, [palette]);
 */

  useEffect(() => {
    // setPalettes(store.palettes);
    const result = [];
    for (let prop in palettes) {
      result.push(prop);
    }
    setThemeNames(result);
  }, [palettes]);

  const handleColorChange = (e, colorType, isReset) => {
    let copyPalette;
    if (colorType === "default") {
      copyPalette = defaultPalette;
      setUsedPalette(copyPalette);
      /* updateColorPalette(); */
      if(!isReset){
        onSaveColors(copyPalette);

      }
    } else {
      copyPalette = JSON.parse(JSON.stringify(usedPalette)); // for cloning
      copyPalette[colorType][e.target.name] = e.target.value;
      
      setUsedPalette(copyPalette);
    }
  };

  const handleAutoShades = (colorType) => {
    let copyPalette = { ...usedPalette };
    const mainColor = copyPalette[colorType].main;
    COLOR_FIELDS.forEach((field) => {
      if (field.shade) {
        let color = tinycolor(mainColor);
        copyPalette[colorType][field.key] =
          field.type === "light"
            ? color.lighten(field.shade).toHexString()
            : color.darken(field.shade).toHexString();
      }
    });
    setUsedPalette(copyPalette);
  };

  const getColorModalContent = (colorType) => {
    return (
      <>
        {COLOR_FIELDS.map((field, idx) => {
          if (
            colorType !== "primary" &&
            colorType !== "secondary" &&
            (field.key === "lightmost" || field.key === "darkmost")
          ) {
          } else {
            return (
              <FormControl key={idx} fullWidth>
                <TextField
                  type="color"
                  value={usedPalette[colorType][field.key]}
                  label={field.label}
                  name={field.key}
                  margin="normal"
                  variant="outlined"
                  color="secondary"
                  onChange={(e) => handleColorChange(e, colorType)}
                />
              </FormControl>
            );
          }
        })}
        <Box sx={{ textAlign: "center" }}>
          <Tooltip
            title={t(
              "set-light-and-dark-shades-automatically-you-can-still-fine-tune-them-afterwards"
            )}
          >
            <Button
              variant="contained"
              color="primary"
              onClick={(e) => handleAutoShades(colorType)}
            >
              {t("auto-shades")}
            </Button>
          </Tooltip>
        </Box>
      </>
    );
  };

  const handleDeleteTheme = () => {
    const updatedPalettes = { ...palettes };
    delete updatedPalettes[selectedPalette];

    const payload = {
      selectedPalette: "UberBet",
      palettes: updatedPalettes,
    };

    setPalettes(payload.palettes);
    setSelectedPalette("UberBet");
    setUsedPalette(palettes["UberBet"]);
    SiteConfigService.setDistributorStyle(payload)
      .then((response) => {
        if (response.status === 200) {
          setMessage(t("color-scheme-has-been-successfully-set"));
        } else {
          throw new Error("set style failed");
        }
      })
      .catch((err) => {
        setMessage(t("color-scheme-could-not-be-set"));
      })
      .finally(() => {
        setSnackbarStatus(true);
        setLoadingSave(false);
      });
  };

  const onSaveColors = (copyPalette = null) => {
    setLoadingSave(true);
    const newPalette = copyPalette ? copyPalette : usedPalette;
    palettes[selectedPalette] = { ...palettes[selectedPalette], ...newPalette };
    const payload = {
      selectedPalette: selectedPalette,
      palettes: { ...palettes },
    };
    setPalettes(payload.palettes);
    SiteConfigService.setDistributorStyle(payload)
      .then((response) => {
        if (response.status === 200) {
          setMessage(t("color-scheme-has-been-successfully-set"));
        } else {
          throw "set style failed";
        }
      })
      .catch((err) => {
        setMessage(t("color-scheme-could-not-be-set"));
      })
      .finally(() => {
        setSnackbarStatus(true);
        setLoadingSave(false);
      });
  };

  const uploadLogo = (file) => {
    setLoadingLogo(true);
    setSnackbarStatus(false);

    const formData = new FormData();
    formData.append("file", file);

    SiteConfigService.setDistributorLogo(formData)
      .then((response) => {
        if (response.status === 200) {
          dispatch({
            type: "UPDATE_LOGO",
            payload: {
              logo: response.data.logo,
            },
          });
          setMessage(t("new-logo-is-uploaded"));
        } else {
          throw "set logo failed";
        }
      })
      .catch((err) => {
        setMessage(t("new-logo-could-not-be-uploaded"));
      })
      .finally(() => {
        setSnackbarStatus(true);
        setLoadingLogo(false);
      });
  };

  const uploadFavicon = (file) => {
    setLoadingFavicon(true);
    setSnackbarStatus(false);

    const formData = new FormData();
    formData.append("file", file);

    SiteConfigService.setDistributorFavicon(formData)
      .then((response) => {
        if (response.status === 200) {
          dispatch({
            type: "UPDATE_FAV_ICON",
            payload: {
              logo: response.data.favIcon,
            },
          });
          setFavIcon(response.data.favicon);
          setMessage(t("new-favicon-is-uploaded"));
        } else {
          throw "set favicon failed";
        }
      })
      .catch((err) => {
        setMessage(t("new-favicon-could-not-be-uploaded"));
      })
      .finally(() => {
        setSnackbarStatus(true);
        setLoadingFavicon(false);
      });
  };

  const uploadLandingImage = (file) => {
    setLoadingLandingImage(true);
    setSnackbarStatus(false);

    const formData = new FormData();
    formData.append("file", file);

    SiteConfigService.setDistributorLandingImage(formData)
      .then((response) => {
        if (response.status === 200) {
          dispatch({
            type: "UPDATE_LANDING_IMAGE",
            payload: {
              landing_image: response.data.landing_image,
            },
          });
          setMessage(t("new-landing-image-is-uploaded"));
        } else {
          throw "set landing image failed";
        }
      })
      .catch((err) => {
        setMessage(t("new-landing-image-could-not-be-uploaded"));
      })
      .finally(() => {
        setSnackbarStatus(true);
        setLoadingLandingImage(false);
      });
  };
  const selectedPaletteChange = (event) => {
    setSelectedPalette(event.target.value);
    setUsedPalette({ ...palettes[event.target.value] });
    /*  updateColorPalette(); */
    const payload = {
      selectedPalette: event.target.value,
      palettes: palettes,
    };

    SiteConfigService.setDistributorStyle(payload)
      .then((response) => {
        if (response.status === 200) {
          setMessage(t("theme-has-been-successfully-set"));
        } else {
          throw "set style failed";
        }
      })
      .catch((err) => {
        setMessage(t("new-theme-could-not-be-set"));
      })
      .finally(() => {
        setSnackbarStatus(true);
        setLoadingSaveAs(false);
        setOpenModal(false);
      });
  };

  const onSaveThemeAs = () => {
    setLoadingSaveAs(true);

    const newPalette = { ...usedPalette, label: newThemeName };
    const updatedPalettes = {
      ...palettes,
      [newThemeName]: newPalette,
    };

    const payload = {
      selectedPalette: newThemeName,
      palettes: updatedPalettes,
    };

    SiteConfigService.setDistributorStyle(payload)
      .then((response) => {
        if (response.status === 200) {
          setMessage(t("theme-add-success"));
        } else {
          throw "set style failed";
        }
      })
      .catch((err) => {
        setMessage(t("theme-add-fail"));
      })
      .finally(() => {
        setSnackbarStatus(true);
        setLoadingSaveAs(false);
        setOpenModal(false);
        setPalettes(updatedPalettes);
      });
  };

  return (
    <>
      <BaseSnackbar
        open={openSnackbar}
        message={message}
        setOpen={setSnackbarStatus}
      />
      <BaseModal
        title={t("neutral-colors")}
        open={openModal === "neutral"}
        setOpen={setOpenModal}
        children={getColorModalContent("neutral")}
      />
      <BaseModal
        title={t("primary-colors")}
        open={openModal === "primary"}
        setOpen={setOpenModal}
        children={getColorModalContent("primary")}
      />
      <BaseModal
        title={t("secondary-colors")}
        open={openModal === "secondary"}
        setOpen={setOpenModal}
        children={getColorModalContent("secondary")}
      />
      <BaseModal
        title={t("accent-colors")}
        open={openModal === "accent"}
        setOpen={setOpenModal}
        children={getColorModalContent("accent")}
      />
      <BaseModal
        title={t("error-colors")}
        open={openModal === "error"}
        setOpen={setOpenModal}
        children={getColorModalContent("error")}
      />
      <BaseModal
        title={t("warning-colors")}
        open={openModal === "warning"}
        setOpen={setOpenModal}
        children={getColorModalContent("warning")}
      />
      <BaseModal
        title={t("success-colors")}
        open={openModal === "success"}
        setOpen={setOpenModal}
        children={getColorModalContent("success")}
      />
      <BaseModal
        title={t("background-colors")}
        open={openModal === "background"}
        setOpen={setOpenModal}
        children={getColorModalContent("background")}
      />
      <BaseModal
        title={t("save-theme-as")}
        open={openModal === "saveAs"}
        setOpen={setOpenModal}
        children={
          <>
            <FormControl fullWidth>
              <TextField
                fullWidth
                value={newThemeName}
                onChange={(event) => {
                  setNewThemeName(event.target.value);
                }}
                label={t("name")}
              />
            </FormControl>
            <LoadingButton
              sx={{ mt: 1 }}
              loading={loadingSaveAs}
              variant="contained"
              onClick={() => onSaveThemeAs()}
            >
              {t("save-as")}
            </LoadingButton>
          </>
        }
      ></BaseModal>
      <BaseModal
        title={t("delete-color-theme")}
        open={openDeleteModalStatus}
        setOpen={setDeleteModalStatus}
        children={
          <DeleteThemeForm
            setModalStatus={setDeleteModalStatus}
            selectedTheme={palettes[selectedPalette].label}
            successCallback={handleDeleteTheme}
          />
        }
      />
      <br />
      <Grid
        container
        direction="column"
        spacing={2}
        justifyContent="space-evenly"
        alignItems="stretch"
      >
        <GridItem item xs={12} md={12}>
          <Grid
            container
            direction="row"
            spacing={2}
            justifyContent="space-evenly"
            alignItems="stretch"
          >
            <GridItem item xs={12} md={4}>
              <Stack direction="column" spacing={2}>
                <StyledPaper>
                  <FormControl fullWidth>
                    <InputLabel>{t("theme-presets")}</InputLabel>
                    <Select
                      /* disabled={!canUserWriteIt("branding")} */
                      value={selectedPalette}
                      label={t("theme-presets")}
                      onChange={selectedPaletteChange}
                    >
                      {themeNames.map((value) => {
                        return (
                          <MenuItem
                            selected={selectedPalette === value}
                            value={value}
                          >
                            {palettes[value]
                              ? palettes[value].label
                              : "UberBet"}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                  <br />
                  <br />
                  {colorFields.map((color, idx) => {
                    return (
                      <FormControl key={idx} fullWidth>
                        <TextField
                          type="color"
                          value={color.colors.main}
                          label={color.label}
                          name={color.name}
                          margin="normal"
                          variant="outlined"
                          color="secondary"
                          onClick={(e) => setOpenModal(e.target.name)}
                        />
                      </FormControl>
                    );
                  })}
                  {/* canUserWriteIt("branding") */  true && (
                    <Box sx={{ textAlign: "right" }}>
                      {!(selectedPalette === 'UberBet' || selectedPalette === 'Tipo') ? (
                        <Button
                          color="error"
                          sx={{ mt: 1, mr: 1 }}
                          onClick={() =>
                            setDeleteModalStatus(!openDeleteModalStatus)
                          }
                        >
                          <DeleteIcon />
                        </Button>
                      ) : (
                        ""
                      )}
                      <Button
                        sx={{ mt: 1, mr: 1 }}
                        variant="contained"
                        color="secondary"
                        onClick={(e) => handleColorChange(e, "default", true)}
                      >
                        {t("reset-colors")}
                      </Button>
                      {!(selectedPalette === 'UberBet' || selectedPalette === 'Tipo') && <LoadingButton
                        sx={{ mt: 1, mr: 1 }}
                        loading={loadingSave}
                        variant="contained"
                        onClick={() => onSaveColors()}
                      >
                        {t("save")}
                      </LoadingButton>}
                      
                      <Button
                        sx={{ mt: 1, mr: 1 }}
                        variant="contained"
                        onClick={() => setOpenModal("saveAs")}
                      >
                        {t("save-as")}
                      </Button>
                      {/* <LoadingButton sx={{ mt: 1 }} loading={loadingSetSelectedTheme} variant="contained" onClick={() => onSetSelectedTheme()}>{t('set-selected-theme')}</LoadingButton> */}
                    </Box>
                  )}
                </StyledPaper>
              </Stack>
            </GridItem>
            <GridItem item xs={12} md={8}>
              <StyledPaper sx={{ overflowX: "visible" }}>
                <TabGroup
                  items={sampleTabs}
                  handleTabChange={handleTabChange}
                  tabValue={tabValue}
                />
                <TabPanel value={tabValue}>
                  {sampleTabs[tabValue].component}
                </TabPanel>
              </StyledPaper>
            </GridItem>
          </Grid>
        </GridItem>
       {/*  {canUserWriteIt("branding") true && (
          <GridItem item xs={12} md={8}>
            <StyledPaper>
              <FileUpload
                label={t("logo")}
                uploadHandler={uploadLogo}
                uploading={loadingLogo}
              />
              <br />
              <FileUpload
                label={t("favicon")}
                uploadHandler={uploadFavicon}
                uploading={loadingFavicon}
              />
              <br />
              <FileUpload
                label={t("landing-image")}
                uploadHandler={uploadLandingImage}
                uploading={loadingLandingImage}
              />
            </StyledPaper>
          </GridItem>
        )} */}
      </Grid>
    </>
  );
}
