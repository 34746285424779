import {
  Box,
  Card,
  Grid,
  InputLabel,
  TextField,
  Checkbox,
  FormControlLabel,
  Typography,
} from "@mui/material";
import * as Yup from "yup";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Form, FormikProvider, useFormik } from "formik";
import { DistributorsService } from "../../../../api/services";
import BaseSnackbar from "../../../../components/BaseSnackbar";
import SaveIcon from "@mui/icons-material/Save";
import { LoadingButton } from "@mui/lab";
import { useTheme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";

export default function AddDistributor() {
  const { t } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();
  const [message, setMessage] = useState("");
  const [openSnackbar, setSnackbarStatus] = useState(false);
  const [createUser, setCreateUser] = useState(false);

  const DistributorSchema = Yup.object().shape({
    distributorName: Yup.string()
      .min(2, t("too-short-2"))
      .max(50, t("too-long-2"))
      .matches(/^[^?"'!@#%<>;]*$/, t("name-invalid-characters"))
      .required(t("distributor-name-is-required")),
  
    firstName: Yup.string()
      .min(2, t("too-short-0"))
      .max(50, t("too-long-0"))
      .matches(/^[^?"'!@#%<>;]*$/, t("name-invalid-characters"))
      .when("createUser", {
        is: true,
        then: (schema) => schema.required(t("first-name-is-required")),
      }),
  
    lastName: Yup.string()
      .min(2, t("too-short-1"))
      .max(50, t("too-long-1"))
      .matches(/^[^?"'!@#%<>;]*$/, t("name-invalid-characters"))
      .when("createUser", {
        is: true,
        then: (schema) => schema.required(t("last-name-is-required")),
      }),
  
    email: Yup.string()
      .email(t("invalid-email"))
      .when("createUser", {
        is: true,
        then: (schema) => schema.required(t("email-is-required")),
      }),
  
    password: Yup.string()
      .min(3, t("too-short-3"))
      .max(32, t("password-long-error"))
      .matches(/^[\S]*$/, t("password-validation"))
      .when("createUser", {
        is: true,
        then: (schema) => schema.required(t("password-is-required")),
      }),
  });

  const formik = useFormik({
    validationSchema: DistributorSchema,
    initialValues: {
      distributorName: "",
      distributorID: "",
      email: "",
      password: "",
      firstName: "",
      lastName: "",
    },
    onSubmit: (values, actions) => {
      let payload = {
        id: values.distributorID,
        name: values.distributorName,
        is_enabled: true,
      };

      if (createUser) {
        payload.user = {
          email: values.email,
          password: values.password,
          first_name: values.firstName,
          last_name: values.lastName,
          distributor_admin: false,
          properties: {},
        };
      }

      DistributorsService.createDistributor(payload)
        .then((response) => {
          if (response.status === 201 || response.status === 200) {
            setTimeout(() => {
              navigate("/distributor/manage", {
                state: { successMessage: t("new-distributor-added-successfully") },
              });
            }, 600);
            actions.setSubmitting(false);
          } else {
            throw new Error("failed to create distributor");
          }
        })
        .catch((err) => {
          console.error(err);
          setMessage(t("distributor-add-fail"));
          setSnackbarStatus(true);
          setTimeout(() => {
            actions.setSubmitting(false);
          }, 1000);
        });
    },
  });

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

  return (
    <>
      <BaseSnackbar
        open={openSnackbar}
        message={message}
        setOpen={setSnackbarStatus}
      />

      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Card
            sx={{
              display: "flex",
              flexDirection: "column",
              padding: 5,
              gap: 4,
              mt: 10,
              ml:{xs:2, md:30},
              width: {xs:"100%", md:"60%"},
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <InputLabel sx={{ fontWeight: "bold" }}>
                  {t("distributor-name")}{" "}
                  <span style={{ color: "red" }}>*</span>
                </InputLabel>
                <TextField
                  fullWidth
                  id="distributorName"
                  label={t("distributor-name")}
                  placeholder={t("enter-distributor-name")}
                  {...getFieldProps("distributorName")}
                  error={Boolean(
                    touched.distributorName && errors.distributorName
                  )}
                  helperText={touched.distributorName && errors.distributorName}
                />
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={createUser}
                    onChange={(e) => setCreateUser(e.target.checked)}
                  />
                }
                label={
                  <Typography sx={{ fontWeight: "bold" }}>
                    {t("create-with-distributor-user")}
                  </Typography>
                }
              />
            </Grid>

            {createUser && (
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <InputLabel sx={{ fontWeight: "bold" }}>
                    {t("first-name")}
                  </InputLabel>
                  <TextField
                    fullWidth
                    {...getFieldProps("firstName")}
                    error={Boolean(touched.firstName && errors.firstName)}
                    helperText={touched.firstName && errors.firstName}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <InputLabel sx={{ fontWeight: "bold" }}>
                    {t("last-name")}
                  </InputLabel>
                  <TextField
                    fullWidth
                    {...getFieldProps("lastName")}
                    error={Boolean(touched.lastName && errors.lastName)}
                    helperText={touched.lastName && errors.lastName}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <InputLabel sx={{ fontWeight: "bold" }}>
                    {t("email")}
                  </InputLabel>
                  <TextField
                    fullWidth
                    {...getFieldProps("email")}
                    error={Boolean(touched.email && errors.email)}
                    helperText={touched.email && errors.email}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <InputLabel sx={{ fontWeight: "bold" }}>
                    {t("password")}
                  </InputLabel>
                  <TextField
                     fullWidth
                     type="password"
                     {...getFieldProps("password")}
                     error={Boolean(touched.password && errors.password)}
                     helperText={touched.password && errors.password}
                  />
                </Grid>
              </Grid>
            )}

            <Box sx={{ display: "flex", justifyContent: "center", mt: 3 }}>
              <LoadingButton
                sx={{
                  backgroundColor: theme.palette.warning.main,
                  color: "#fff",
                }}
                size="large"
                type="submit"
                variant="contained"
                loading={isSubmitting}
                startIcon={<SaveIcon />}
              >
                {createUser
                  ? t("Add Distributor With User")
                  : t("Add Distributor")}
              </LoadingButton>
            </Box>
          </Card>
        </Form>
      </FormikProvider>
    </>
  );
}
