import React, { useState, useEffect } from "react";
import { TextField, Button, Grid, Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { StoreService } from "../../../../api/services";

export default function EditStore({
  successCallback,
  formData,
  setModalStatus,
  setSnackbarStatus,
  setMessage,
}) {
  const { t } = useTranslation();
  
  const [storeName, setStoreName] = useState("");

  useEffect(() => {
    if (formData) {
      setStoreName(formData.name || "");
    }
  }, [formData]);

  const handleSubmit = (event) => {
    event.preventDefault();

    const updatedData = {
      name: storeName,
    };

    console.log("Submitting updated store data:", updatedData);

    StoreService.updateStore(formData.id, updatedData)
      .then((response) => {
        successCallback(); 
        setModalStatus(false); 
        setSnackbarStatus(true); 
        setMessage(t("store-updated-successfully"));
      })
      .catch((err) => {
        console.error("Update failed:", err);
        setSnackbarStatus(true);
        setMessage(t("failed-to-update-store"));
      });
  };

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            label={t("store-name")}
            value={storeName}
            onChange={(e) => setStoreName(e.target.value)}
            fullWidth
            required
          />
        </Grid>
        <Grid item xs={12}>
          <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 1 }}>
            <Button onClick={() => setModalStatus(false)}>{t("cancel")}</Button>
            <Button type="submit" color="secondary">
              {t("save")}
            </Button>
          </Box>
        </Grid>
      </Grid>
    </form>
  );
}
