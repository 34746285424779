import React, { useState, useEffect, useRef } from 'react';
import { 
  Box, 
  Typography, 
  Tabs, 
  Tab, 
  Card, 
  CardContent, 
  Grid, 
  Chip, 
  IconButton,
  useTheme
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import PublicTwoToneIcon from '@mui/icons-material/PublicTwoTone';
import { OddsService, OnlineOddsService } from '../../../api/services';
import { useStore } from '../../../store/Store';
import { convertUTCToLocalTime } from '../../../utils/Util';

export default function OddsDetail({ fixture_id, onBack, addToCoupon, removeFromCoupon, couponBets}) {
  const theme = useTheme();
  const [oddsData, setOddsData] = useState(null);
  const [selectedTab, setSelectedTab] = useState(0);
  const [selectedOdd, setSelectedOdd] = useState(null);
  const mainCategories = oddsData?.bets ? Object.keys(oddsData.bets) : [];
  const [store] = useStore();

  const fetchFootballOddDetail = () => {
    let apiService = store.userType === "kiosk" ?  OddsService.listFootballOddsDetail(fixture_id) : OnlineOddsService.listOnlineFootballOddsDetail(fixture_id)
    apiService
      .then((response) => {
        if (response.status === 200) {
          setOddsData(response.data);
        } else {
          throw new Error("Failed to fetch odds");
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    fetchFootballOddDetail();
  }, [fixture_id]);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const existingBet = couponBets.find(bet => bet.fixture.fixture_id === fixture_id);

  useEffect(() => {
    if (existingBet) {
      setSelectedOdd(existingBet.odd_id);
    }
  }, []);

  
  const handleAddToCoupon = (selectedBet, odd_id) => {
    if (!oddsData || !oddsData.fixture) {
      console.error("Fixture data is not yet loaded");
      return;
    }

    const existingBet = getExistingBet();

    if (isOddSelected(odd_id)) {
      removeFromCoupon(existingBet);
      setSelectedOdd(null);
    } else {
      if (existingBet) {
        removeFromCoupon(existingBet);
      }
      setSelectedOdd(odd_id);
      addToCoupon({ 
        fixture: oddsData.fixture, 
        selectedBet, 
        odd_id 
      });
    }
  };

  const isOddSelected = (odd_id) => selectedOdd === odd_id;
  
  const getExistingBet = () => {
    if (!oddsData) return null;
    return couponBets.find(b => b.fixture.fixture_id === oddsData.fixture.fixture_id);
  };

  useEffect(() => {
    if (!getExistingBet()) {
      setSelectedOdd(null);
    }
  }, [couponBets, oddsData]);

  if (!oddsData || !oddsData.bets) return <Typography>Loading...</Typography>;

  const { fixture, league, bets } = oddsData;
  const fixtureDateTime = convertUTCToLocalTime(fixture.date, fixture.time, store.language)
  

  return (
    <Box sx={{ p: 3, backgroundColor: theme.palette.neutral.lighter, minHeight: '100vh' }}>
      <IconButton 
        onClick={onBack} 
        sx={{ mb: 2, color: theme.palette.primary.main }}
      >
        <ArrowBackIcon />
      </IconButton>

      <Card sx={{ mb: 3, boxShadow: 2 }}>
        <CardContent sx={{ p: '0 !important' }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1, backgroundColor: theme.palette.primary.light, padding: '8px 16px' }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              {league.flag ? (
                <img src={league.flag} alt={`${league.country} flag`} style={{ width: 20, height: 20, marginRight: 8 }} />
              ) : (
                <PublicTwoToneIcon fontSize="small" sx={{ mr: 0.5, color: theme.palette.info.main }} />
              )}
              <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", gap: 1 }}>
                <Typography variant="caption" color="text.primary">{league.country}</Typography>
                <Typography variant="caption" color="text.secondary">{league.name}</Typography>
              </Box>
            </Box>
            <Typography variant="caption" color="text.secondary">
              {fixtureDateTime.toLocaleString(store.language, { month: 'numeric', day: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true })}
            </Typography>
          </Box>

          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', p: 2 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <img src={fixture.home_team_logo} alt={`${fixture.home_team} logo`} style={{ width: 25, height: 25, marginRight: 8 }} />
                <Typography variant="body1" fontWeight="medium">{fixture.home_team}</Typography>
              </Box>
              <Typography variant="caption" color="text.secondary">vs</Typography>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant="body1" fontWeight="medium">{fixture.away_team}</Typography>
                <img src={fixture.away_team_logo} alt={`${fixture.away_team} logo`} style={{ width: 25, height: 25, marginLeft: 8 }} />
              </Box>
            </Box>
          </Box>
        </CardContent>
      </Card>

      <Tabs
        value={selectedTab}
        onChange={handleTabChange}
        variant="scrollable"
        scrollButtons="auto"
        aria-label="odds categories"
      >
        {mainCategories.map((category, index) => (
          <Tab key={index} label={category} />
        ))}
      </Tabs>

      <Box sx={{ mt: 2 }}>
        {mainCategories.map((category, index) => (
          index === selectedTab && (
            <Box key={category} sx={{ p: 2 }}>
              {Object.keys(oddsData.bets[category]).map((subCategory) => (
                <Box key={subCategory} sx={{ mb: 3 }}>
                  <Typography variant="h6">{subCategory}</Typography>
                  <Grid container spacing={2}>
                    {Array.isArray(oddsData.bets[category][subCategory]) ? (
                      oddsData.bets[category][subCategory].map((bet) => (
                        <Grid item xs={6} sm={4} md={3} key={bet.odd_id} sx={{ marginTop: '10px' }}>
                          <Chip
                            label={`${bet.value} - Odds: ${bet.odd}`}
                            variant="outlined"
                            sx={{
                              minWidth:{xs:20, md:70},
                              backgroundColor: selectedOdd === bet.odd_id ? theme.palette.success.darkest : theme.palette.accent.lighter,  
                            }}
                            onClick={() => handleAddToCoupon(bet.odd, bet.odd_id)} 
                          />
                        </Grid>
                      ))
                    ) : (
                      <Typography>No data available</Typography>
                    )}
                  </Grid>
                </Box>
              ))}
            </Box>
          )
        ))}
      </Box>
    </Box>
  );
}
